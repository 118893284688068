import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: { main: 'rgb(0 0 0 / 0%)' },
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginBottom: "20px"
      }
    },
    MuiInput: {
      underline: {
        "&&&&:before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.07)"
        }
      },
      root: {
        "&&&&": {
          marginTop: "4px"
        }
      }
    },
    MuiSelect: {
      icon: {
        "&&&&": {
          color: "rgba(0, 0, 0, 0.24)"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&&&&": {
          color: "rgba(0, 0, 0, 0.40)"
        }
      }
    },
    MuiTypography: {
      subheading: {
        "&&&&": {
          'font-size': "1rem",
          'font-weight': "500",
          'color': 'rgba(66, 66, 66, 0.87);'
        }
      }
    },
   
  }
});

export default theme;
