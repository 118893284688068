import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";

class TitleDescriptionComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      title,
      description,
      textAlign,
      color,
      fontSize,
      colorSubtitle,
      textAlignSubtitle,
      fontSizeSubtitle,
      textTransform,
      heightDescription,
      typeTitle = "main",
    } = this.props;
    return (
      <Grid
        item
        xs={typeTitle == "main" ? 10 : 12}
        container
        direction="column"
        alignItems="center"
        className={
          typeTitle == "secondary" || typeTitle == "titleBody"
            ? "backgroundColorContent"
            : ""
        }
      >
        {typeTitle == "main" && (
          <Grid style={{ marginTop: "2vh", marginBottom: "2vh" }}>
            {title && (
              <Typography
                variant="h5"
                className="fontSourceSansPro"
                style={{
                  color: "#808080",
                  textAlign: textAlign || "center",
                  height: heightDescription || "auto",
                }}
              >
                {title}
              </Typography>
            )}
            {description && (
              <Typography
                variant="subtitle1"
                className="fontLatoRegular"
                style={{
                  color: "#808080",
                  textAlign: "center",
                  marginTop: "2vh",
                  fontSize: "0.8rem",
                  marginBottom: "2vh",
                  height: heightDescription || "auto",
                }}
              >
                {description}
              </Typography>
            )}
          </Grid>
        )}

        {typeTitle == "secondary" && (
          <Grid item xs={10} style={{ marginTop: "2vh", marginBottom: "2vh" }}>
            {title && (
              <Typography
                className="fontSourceSansProSemiBold"
                style={{
                  color: color || "#4D4D4D",
                  fontSize: fontSize || "1.5rem",
                  textAlign: textAlign || "center",
                }}
              >
                {title}
              </Typography>
            )}
            {description && (
              <Typography
                className="fontLatoLight"
                style={{
                  color: colorSubtitle || "#808080",
                  marginTop: "1vh",
                  textAlign: textAlignSubtitle || "center",
                  fontSize: fontSizeSubtitle || "1.2rem",
                  height: heightDescription || "auto",
                }}
              >
                {description}
              </Typography>
            )}
            {this.props.children}
          </Grid>
        )}

        {typeTitle == "titleBody" && (
          <Grid
            container
            style={{
              padding: "2vh 4vh 4vh",
              backgroundColor: "#f7f4f2",
            }}
          >
            <Grid item xs={12} style={{ marginTop: "15px" }}>
              <Typography
                className="fontSourceSansProSemiBold"
                style={{
                  color: "#4D4D4D",
                  fontSize: "26px",
                  whiteSpace: "pre-line",
                  lineHeight: "28px",
                  textAlign: textAlign || "center",
                  textTransform: textTransform || "none",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="fontLatoLight"
                style={{
                  marginTop: "8px",
                  width: "100%",
                  fontSize: "18px",
                  paddingRight: "9px",
                  overflow: "scroll",
                  whiteSpace: "pre-line",
                  color: "#808080",
                  textAlign: textAlignSubtitle || "center",
                  height: heightDescription || "auto",
                }}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default TitleDescriptionComponent;
