import React from "react";
import HeaderBarComponent from "../../../shared/header-bar/header-bar";
import OurPrayersResources from "../../../../resources/our-prayers";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import ButtonComponent from "../../../shared/components/button";
import InputComponent from "../../../shared/components/input";
import TextField from "@mui/material/TextField";
import TitleDescriptionComponent from "../../../shared/components/title-description";
import MenuItem from "@material-ui/core/MenuItem";
import validator from "validator";

class GroupInvitationsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      invitation: {
        email: null,
        message: null,
        group_id: this.props.location?.state?.group_id
          ? this.props.location.state.group_id
          : null,
      },
      groups: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.create = this.create.bind(this);
  }

  componentDidMount() {
    this.loadGroups();
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    this.setState((prevState) => ({
      invitation: { ...prevState.invitation, [target]: value },
    }));
  }

  create() {
    const { invitation } = this.state;
    if (!invitation.email) {
      const snackbarMessage = "Enter a email";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    } else {
      invitation.email = invitation.email.replaceAll(" ", "");
      if (!validator.isEmail(invitation.email)) {
        const snackbarMessage = "Enter a valid email";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!invitation.group_id) {
          const snackbarMessage = "Enter a group";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          this.setState({ isLoading: true });
          OurPrayersResources.createInvitationGroup(invitation)
            .then(() => {
              this.props.history.push(
                `/our-prayers/${invitation.group_id}/invitations-group`,
                {
                  message: "The invitation has been sent",
                }
              );
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      }
    }
  }

  loadGroups() {
    this.setState({
      isLoading: true,
    });
    OurPrayersResources.getAllGroups().then((data) => {
      this.setState({
        groups: data,
        isLoading: false,
      });
    });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { snackbarMessage, snackbarOpen, isLoading, invitation, groups } =
      this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        <div>
          <HeaderBarComponent
            showLogo
            showBack
            showBackAction={() =>
              this.props.history.push(
                this.props.location?.state?.group_id
                  ? `/our-prayers/${this.props.location?.state?.group_id}/invitations-group`
                  : `/our-prayers/`
              )
            }
            showMenu
            showNotifications
            title={"Prayr Apps"}
          />

          <Grid container direction="column" alignItems="center">
            <TitleDescriptionComponent title={"Invite People To Join"} />
          </Grid>

          <Grid container direction="column" alignItems="center">
            <InputComponent
              inputValue={invitation.email}
              inputChange={this.handleChange}
              name={"email"}
              label={"Add Email"}
            />
            {/* <InputComponent
              inputValue={invitation.message}
              inputChange={this.handleChange}
              name={"message"}
              textArea
              label={"Add a Message (optional)"}
            /> */}
            <TextField
              fullWidth
              select
              label="Assign to Group"
              style={{ margin: "2vh auto" }}
              name="group_id"
              value={invitation.group_id}
              onChange={this.handleChange}
              sx={{
                "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled": {
                  display: "none",
                  color: "#808080 !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  display: "none",
                },
                "& .MuiInputLabel-root": {
                  color: "#808080",
                  position: "absolute",
                  left: "5vh",
                  top: "4px",
                  fontSize: "12px",
                },
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: "#f0f0f0 !important",
                },
              }}
              InputProps={{
                style: {
                  color: "#808080",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "4vh",
                  width: "80%",
                  margin: "auto",
                },
              }}
            >
              {groups
                .filter((f) => f.owner)
                .map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <ButtonComponent
              name={"INVITE"}
              action={this.create}
              backgroundColor={"#F07522"}
              color={"#ffffff"}
              borderRadius={"3vh"}
              fontSize={"0.9rem"}
              paddingTop={"1.1vh"}
            />
          </Grid>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default GroupInvitationsComponent;
