import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import "./App.css";
import Routes from "./components/shared/routes/routes";
import Theme from "./components/shared/theme/theme";

var installableAppOptions;

const App = function App() {
  const [installableApp, setInstallableApp] = useState(false);
  const [isIosInstalled, setIsIosInstalled] = useState(false);
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    detectDeviceType();
  }, []);

  const detectDeviceType = () => {
    var iOS =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    if (iOS) {
      setDeviceType("ios");
      var isIosInstalled = navigator.standalone === true;
      setIsIosInstalled(isIosInstalled);
    } else {
      setDeviceType("android");
      verifyInstallableApp();
    }
  };

  const verifyInstallableApp = () => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installableAppOptions = e;
      setInstallableApp(true);
    });
    window.addEventListener("appinstalled", () => {
      setInstallableApp(false);
    });
  };

  const handleInstallApp = () => {
    installableAppOptions.prompt();
    installableAppOptions.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        setInstallableApp(false);
      } else {
        setInstallableApp(false);
      }
    });
  };

  return (
    <div>
      <div
        className="background"
        style={{
          backgroundColor: "#f27405",
          height: "10vh",
          position: "absolute",
          width: "100%",
        }}
      ></div>
      <div
        className="App"
        style={{
          maxWidth: "500px",
          height: "100%",
          margin: "auto",
          position: "absolute",
          right: 0,
          left: 0,
        }}
      >
        <MuiThemeProvider theme={Theme}>
          <Routes
            {...{
              installableApp,
              isIosInstalled,
              handleInstallApp,
              deviceType,
            }}
          />
        </MuiThemeProvider>
      </div>
    </div>
  );
};
export default App;
