import React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Paper } from "@mui/material";

class BottomNavigationComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  renderOptions(icon, action, width, height) {
    return (
      <BottomNavigationAction
        onClick={action}
        icon={
          <Paper
            style={{
              width: width || "34px",
              height: height ||"29px",
              backgroundImage: icon,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              marginBottom: "16px",
              boxShadow:
                "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
            }}
          />
        }
      />
    );
  }

  render() {
    const { iconOne, actionOne, widthOne, heightOne, iconTwo, actionTwo, widthTwo, heightTwo, iconThree, actionThree, widthThree, heightThree } =
      this.props;

    return (
      <BottomNavigation
        style={{
          position: "fixed",
          maxWidth: "500px",
          bottom: 0,
          width: "100%",
          zIndex: 3,
          height: "74px",
          boxShadow:
            "rgb(0 0 0 / 0%) 0px 2px 4px -1px, rgb(0 0 0 / 10%) 3px -6px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px",
        }}
      >
        {iconOne && actionOne && this.renderOptions(iconOne, actionOne, widthOne, heightOne)}
        {iconTwo && actionTwo && this.renderOptions(iconTwo, actionTwo, widthTwo, heightTwo)}
        {iconThree && actionThree && this.renderOptions(iconThree, actionThree, widthThree, heightThree)}
      </BottomNavigation>
    );
  }
}

export default BottomNavigationComponent;
