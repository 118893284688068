import Grid from "@material-ui/core/Grid";
import { Paper } from "@mui/material";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import Typography from "@mui/material/Typography";
import MyPrayersResources from "../../resources/my-prayers";
import FilledInput from "@mui/material/FilledInput";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import LocalStorageResources from "../../resources/local-storage";
import TitleDescriptionComponent from "../shared/components/title-description";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import BottomNavigationComponent from "../shared/components/bottom-navigation";

class AnsweredPrayersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: null,
      loadPrayers: [],
      filterLoadPrayers: [],
      open: false,
      isLoading: false,
      enableSearch: false,
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      userData: [],
    };
    this.initPage = React.createRef();
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  componentDidMount() {
    this.loadPrayers();
  }

  accentFold(s) {
    var accent_map = {
      á: "a",
      é: "e",
      è: "e",
      í: "i",
      ó: "o",
      ú: "u",
      Á: "a",
      É: "e",
      Í: "i",
      Ó: "o",
      Ú: "u",
    };
    if (!s) {
      return "";
    }
    var ret = "";
    for (var i = 0; i < s.length; i++) {
      ret += accent_map[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  }

  filter(searchFilter = null) {
    const { loadPrayers } = this.state;
    var filterLoadPrayers = loadPrayers.filter((f) => {
      if (searchFilter) {
        var filter = [];
        const searchByUpper = this.accentFold(searchFilter).toUpperCase();
        filter =
          this.accentFold(f.name).toUpperCase().indexOf(searchByUpper) >= 0;
        return filter;
      } else {
        return loadPrayers;
      }
    });
    filterLoadPrayers = filterLoadPrayers.filter(
      (f) => f.id.toString().indexOf("p-") === -1
    );
    filterLoadPrayers = filterLoadPrayers.sort((a, b) =>
      a.id < b.id ? 1 : -1
    );
    this.setState({ filterLoadPrayers });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  scrollToTop = () => {
    if (this.initPage) {
      this.initPage.scrollIntoView({ behavior: "auto" });
    }
  };

  loadPrayers(loading = true) {
    const { message } = this.state;
    this.setState({
      isLoading: loading,
    });
    MyPrayersResources.getAllAnswered().then((data) => {
      const userData = LocalStorageResources.getObject("authData").data;
      const currentDate = moment().format("DD/MM/yyyy");
      data.map((d) => {
        const lastPrayDate = moment
          .utc(d.last_prayer_date)
          .local()
          .format("DD/MM/yyyy");
        d.prayToday =
          d.last_prayer_date == null || currentDate != lastPrayDate
            ? true
            : false;
      });
      const filterPrayToday = data
        .filter((f) => f.prayToday)
        .sort((a, b) => (a.id < b.id ? 1 : -1));
      const filterDontPrayToday = data
        .filter((f) => !f.prayToday)
        .sort(
          (a, b) =>
            moment.utc(a.last_prayer_date).local() -
            moment.utc(b.last_prayer_date).local()
        );
      const dataFilter = filterPrayToday.concat(filterDontPrayToday);
      this.setState({
        loadPrayers: dataFilter,
        isLoading: false,
        filterLoadPrayers: dataFilter,
        userData,
      });
      if (message) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: message,
          message: null,
        });
      }
    });
  }

  onClickSearch() {
    this.handleClose;
    this.handleSearch();
    this.scrollToTop();
  }

  renderBottomNavigation() {
    return (
      <BottomNavigationComponent
        iconTwo={`url("/footer-bar/Icon_SearchOrange.png")`}
        actionTwo={() => this.onClickSearch()}
        widthTwo={"29px"}
      />
    );
  }

  handleSearch = () => {
    const { enableSearch, loadPrayers } = this.state;
    this.setState({
      enableSearch: !enableSearch ? true : false,
      filterLoadPrayers: loadPrayers,
    });
  };

  render() {
    const {
      loadPrayers,
      filterLoadPrayers,
      isLoading,
      enableSearch,
      snackbarOpen,
      snackbarMessage,
    } = this.state;

    return (
      <div style={{ marginBottom: "15vh" }}>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.initPage = el;
              }}
            />
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />
            <Grid container alignItems="center" direction="column">
              <TitleDescriptionComponent title={"Answered Prayers"} />
            </Grid>
            <Grid container direction="column">
              {enableSearch && (
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ marginBottom: "2vh" }}
                >
                  <FilledInput
                    id="standard-basic"
                    label="Search..."
                    sx={{
                      backgroundColor: "#ffffff00 !important",
                      "&.MuiFilledInput-root:after": {
                        borderBottom: "2px solid #F07522 !important",
                      },
                    }}
                    variant="standard"
                    endAdornment={
                      <InputAdornment position="end" sx={{ marginTop: "2vh" }}>
                        <IconButton
                          onClick={() => this.handleSearch()}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    style={{ width: "80%" }}
                    onChange={(event) => this.filter(event.target.value)}
                  />
                </Grid>
              )}
            </Grid>

            {filterLoadPrayers.length > 0 && (
              <div style={{ marginBottom: "10vh" }}>
                <Grid item xs={12} style={{ padding: "2vh", paddingTop: "0" }}>
                  {filterLoadPrayers.map((item) => (
                    <Grid style={{ width: "100%" }} key={item.id}>
                      <div
                        key={item.id}
                        style={{
                          width: "100%",
                          marginBottom: "2vh",
                          display: "flex",
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <div
                              className={"prayer-div" + item.id}
                              style={{
                                position: "relative",
                                paddingRight: "2vh",
                              }}
                            >
                              <Paper
                                sx={{
                                  ".prayer-options:hover": {
                                    display: "none",
                                    color: "#ffffff !important",
                                  },
                                }}
                                onClick={() =>
                                  this.props.history.push(
                                    `/my-prayers/${item.id}/view-details/`,
                                    {
                                      backUrl: `/answered-prayers`,
                                    }
                                  )
                                }
                                style={{
                                  width: "100%",
                                  height: "12vh",
                                  borderRadius: "2vh",
                                  backgroundImage: item.avatar_url
                                    ? `url("` + item.avatar_url + `")`
                                    : null,
                                  backgroundColor: !item.avatar_url
                                    ? item.background_color
                                      ? item.background_color
                                      : "#c3c3c3"
                                    : null,
                                  position: "relative",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                {!item.avatar_url && (
                                  <Typography
                                    onClick={() =>
                                      this.props.history.push(
                                        `/my-prayers/${item.id}/view-details/`,
                                        {
                                          backUrl: `/answered-prayers`,
                                        }
                                      )
                                    }
                                    variant="h6"
                                    style={{
                                      position: "absolute",
                                      bottom: item.avatar_url ? "2vh" : null,
                                      left: !item.avatar_url ? 0 : "2vh",
                                      right: !item.avatar_url ? 0 : null,
                                      zIndex: "1",
                                      top: !item.avatar_url ? "4vh" : null,
                                      fontSize: !item.avatar_url
                                        ? "2.5vh"
                                        : "3vh",
                                      textAlign: !item.avatar_url
                                        ? "center"
                                        : null,
                                      color: "#ffffff",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name.split(" ").shift().charAt(0)}
                                    {item.name.indexOf(" ") >= 0
                                      ? item.name.split(" ").pop().charAt(0)
                                      : null}
                                  </Typography>
                                )}
                              </Paper>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            onClick={() =>
                              this.props.history.push(
                                `/my-prayers/${item.id}/view-details/`, {
                                  backUrl: `/answered-prayers`,
                                }
                              )
                            }
                            style={{
                              position: "relative",
                              paddingRight: "0.5vh",
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontWeight: "bold",
                                fontSize: "2.5vh",
                                color: "#4D4D4D",
                              }}
                            >
                              {item.name?.length > 20
                                ? `${item.name.slice(0, 20)}...`
                                : item.name}
                            </Typography>
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: "1.9vh",
                                maxWidth: "100%",
                                boxOrient: "vertical",
                                overflow: "hidden",
                                color: "#808080",
                              }}
                            >
                              {item.description.length > 80
                                ? `${item.description?.slice(0, 80)}...`
                                : item.description}
                            </Typography>

                            <Typography
                              variant="h6"
                              className="fontLatoRegular"
                              style={{
                                fontSize: "1.6vh",
                                maxWidth: "100%",
                                boxOrient: "vertical",
                                overflow: "hidden",
                                color: "#f6914a",
                                fontWeight: "bolder"
                              }}
                            >
                              {item.last_prayer_date ? moment.utc(item.last_prayer_date).local().format("MMM D, yyyy") : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
            {filterLoadPrayers.length == 0 && loadPrayers.length == 0 && (
              <Grid container direction="column" alignItems="center">
                <Grid item xs={9} style={{ marginTop: "4vh" }}>
                  <Typography
                    variant="h5"
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    No Answered Prayers Yet!
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "1vh",
                      fontSize: "2vh",
                    }}
                  >
                    Once you answered your first prayer, you will see it here
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {this.renderBottomNavigation()}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default AnsweredPrayersComponent;
