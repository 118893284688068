/* eslint-disable react/no-unescaped-entities */
import Grid from "@material-ui/core/Grid";
import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import TitleDescriptionComponent from "../../shared/components/title-description";
import TextField from "@mui/material/TextField";
import ButtonComponent from "../../shared/components/button";
import FaithEntriesResources from "../../../resources/faith-entries";
import Snackbar from "@material-ui/core/Snackbar";
import InputComponent from "../../shared/components/input";
import UploadImageComponent from "../../shared/components/upload-image";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import DateRangeIcon from "@mui/icons-material/DateRange";

class AddFaithComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      event_date: moment(moment(), "MM/DD/yyyy"),
      faithEntry: {
        event_date: moment(moment(), "DD/MM/yyyy").format("DD/MM/yyyy"),
        content: "",
        image: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangePicker = this.handleChangePicker.bind(this);
    this.create = this.create.bind(this);
    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.calendarRef = React.createRef();
    this.openCalendar = this.openCalendar.bind(this);
  }

  openCalendar() {
    this.calendarRef.current.click();
  }

  deleteAvatar() {
    this.setState((prevState) => ({
      faithEntry: { ...prevState.faithEntry, image: null },
    }));
  }

  create() {
    const { faithEntry } = this.state;
    if (!faithEntry.event_date) {
      const snackbarMessage = "Enter a Event date";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    } else {
      if (!faithEntry.content) {
        const snackbarMessage = "Enter a Entry content";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        var faithData = {};
        faithData.faith_entry = faithEntry;
        this.setState({ isLoading: true });

        FaithEntriesResources.create(faithData)
          .then(() => {
            this.props.history.push(`/faith-builder`);
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      }
    }
  }

  back() {
    this.props.history.push(`/faith-builder`);
  }

  handleChangePicker(evt) {
    this.setState((prevState) => ({
      faithEntry: {
        ...prevState.faithEntry,
        event_date: evt.format("DD/MM/yyyy"),
      },
      event_date: evt,
    }));
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = target != "image" ? evt.target.value : evt.target.files[0];
    this.setState((prevState) => ({
      faithEntry: { ...prevState.faithEntry, [target]: value },
    }));
    if (target == "image") {
      var file = evt.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          this.setState((prevState) => ({
            faithEntry: { ...prevState.faithEntry, image: reader.result },
          }));
        };
      }
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  button(props) {
    const computedClassName = props.active ? "active" : "muted";
    return <button className={computedClassName}>Click Me</button>;
  }

  render() {
    const { faithEntry, snackbarMessage, snackbarOpen, isLoading, event_date } =
      this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        <div>
          <HeaderBarComponent
            showLogo
            showBack
            showBackAction={() => this.props.history.push(`/faith-builder`)}
            showMenu
            title={"Prayr Apps"}
          />
          <Grid container direction="column" alignItems="center">
            <TitleDescriptionComponent
              title={"Add Entry"}
              description={`It is easy to forget God's faithfulness and all HE has done for
                      us over the years. Add entries to Faith Builder as a permanent
                      reminder. Perfect for times you are struggling or when you want
                      to worship HIM.`}
            />

            <Grid container direction="column" alignItems="center">
              <LocalizationProvider
                dateAdapter={DateAdapter}
                style={{ color: "#fff" }}
              >
                <MobileDatePicker
                  label="Event Date"
                  inputFormat="MM/DD/yyyy"
                  value={event_date}
                  onChange={this.handleChangePicker}
                  color="#fff"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={"event_date"}
                      style={{ marginTop: "2vh", width: "80%" }}
                      inputRef={this.calendarRef}
                      sx={{
                        "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                          {
                            display: "none",
                            color: "#808080 !important",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          display: "none",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#808080",
                          position: "absolute",
                        },
                        "& .MuiOutlinedInput-root fieldset": {
                          borderColor: "#f0f0f0 !important",
                        },
                      }}
                      InputProps={{
                        style: {
                          color: "#808080",
                          backgroundColor: "#f0f0f0",
                          borderRadius: "4vh",
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <DateRangeIcon
                              style={{ color: "#808080" }}
                              onClick={this.openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <InputComponent
                inputValue={faithEntry.content}
                inputChange={this.handleChange}
                name={"content"}
                label={"Add Entry Content"}
                textArea
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <UploadImageComponent
                targetName={"image"}
                avatarChange={this.handleChange}
                avatar={faithEntry.image}
                paddingTopLabel={"9vh"}
                borderColor={"#828282bd"}
                deleteAvatar={this.deleteAvatar}
                backgroundColor={"#ffffff00"}
                width={"60vw"}
                height={"21vh"}
                borderSize={"0.25vh"}
                color={"#808080"}
                label={"Select a photo from camera roll"}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <ButtonComponent
                name={"CREATE ENTRY"}
                action={this.create}
                marginTop={"3vh"}
                marginBottom={"3vh"}
                backgroundColor={"#F07522"}
                color={"#ffffff"}
                borderRadius={"3vh"}
                fontSize={"0.9rem"}
                paddingTop={"1.1vh"}
              />
            </Grid>
          </Grid>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default AddFaithComponent;
