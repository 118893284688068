import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import Grid from "@material-ui/core/Grid";
import InputComponent from "../../shared/components/input";
import TextField from "@mui/material/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AuthResources from "../../../resources/auth";
import UploadImageComponent from "../../shared/components/upload-image";
import MyProfileResources from "../../../resources/my-profile";
import Snackbar from "@material-ui/core/Snackbar";
import TitleDescriptionComponent from "../../shared/components/title-description";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import momentTimeZone from "moment-timezone";
import ButtonComponent from "../../shared/components/button";

const timeZones = momentTimeZone.tz.names();

class UserInfoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      user: {
        first_name: null,
        last_name: null,
        phone_number: null,
        timezone: null,
        avatar: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      isLoading: true,
    });

    MyProfileResources.getUserInfo()
      .then((data) => {
        this.setState({
          user: data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  deleteAvatar() {
    this.setState((prevState) => ({
      user: { ...prevState.user, avatar: "null", avatar_url: null },
    }));
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = target != "avatar" ? evt.target.value : evt.target.files[0];
    this.setState((prevState) => ({
      user: { ...prevState.user, [target]: value },
    }));
    if (target == "avatar") {
      var file = evt.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = async () => {
          this.setState((prevState) => ({
            user: { ...prevState.user, avatar: reader.result },
          }));
        };
      }
    }
  }

  updateUserInfo() {
    const { user } = this.state;
    if (!user.first_name) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your first name",
      });
    } else {
      if (!user.last_name) {
        const snackbarMessage = "Enter your last name";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        var userData = {};
        userData.user = user;
        this.setState({ isLoading: true });
        MyProfileResources.updateUserInfo(userData).then((response) => {
          response.json().then((parsedJson) => {
            if (parsedJson.errors && parsedJson.errors.length > 0) {
              this.setState({
                isLoading: false,
                snackbarOpen: true,
                snackbarMessage: parsedJson.errors[0],
              });
            } else {
              AuthResources.saveAttributes(parsedJson);
              const snackbarMessage = "User info was updated";
              this.setState({
                snackbarOpen: true,
                snackbarMessage,
                isLoading: false,
              });
            }
          });
        });
      }
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { user, snackbarOpen, snackbarMessage, isLoading } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/my-profile`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <Grid container alignItems="center" direction="column">
              <TitleDescriptionComponent
                title={"Personal Information"}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <UploadImageComponent
                avatarChange={this.handleChange}
                avatar={user.avatar ? user.avatar : null}
                avatarUrl={user.avatar_url ? user.avatar_url : null}
                paddingTopLabel={"7.5vh"}
                borderColor={"#828282bd"}
                deleteAvatar={this.deleteAvatar}
                backgroundColor={"#ffffff00"}
                width={"63vw"}
                borderSize={"0.25vh"}
                color={"#000000"}
                label={"SELECT A PHOTO FROM CAMERA ROLL"}
                height={"18vh"}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <InputComponent
                label="First Name"
                inputValue={user.first_name}
                inputChange={this.handleChange}
                name={"first_name"}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <InputComponent
                label="Last Name"
                inputValue={user.last_name}
                inputChange={this.handleChange}
                name={"last_name"}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <InputComponent
                label="Phone"
                inputValue={user.phone_number}
                inputChange={this.handleChange}
                name={"phone_number"}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <TextField
                fullWidth
                select
                label="Timezone"
                style={{ marginTop: "2vh", width: "80%", color: "#fff" }}
                name="timezone"
                value={user.timezone}
                onChange={this.handleChange}
                sx={{
                  "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled": {
                    display: "none",
                    color: "#808080 !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    display: "none",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#808080",
                    position: "absolute",
                  },
                  "& .MuiOutlinedInput-root fieldset": {
                    borderColor: "#f0f0f0 !important",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#808080",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "4vh",
                  },
                }}
              >
                <MenuItem key="" value={null}></MenuItem>
                {timeZones.map((g) => (
                  <MenuItem key={g} value={g}>
                    {`(GMT${momentTimeZone.tz(g).format("Z")}) ${g}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid container direction="column" alignItems="center">
              <ButtonComponent
                name={"UPDATE"}
                action={this.updateUserInfo}
                marginTop={"2vh"}
                backgroundColor={"#F07522"}
                color={"#ffffff"}
                borderRadius={"3vh"}
                fontSize={"0.9rem"}
                paddingTop={"1.1vh"}
              />
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default UserInfoComponent;
