import Typography from "@mui/material/Typography";
import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import OurPrayersResources from "../../../resources/our-prayers";
import MessagesGroupResources from "../../../resources/messages-group";
import Snackbar from "@material-ui/core/Snackbar";
import ConfirmationComponent from "../../shared/confirmation/confirmation";
import LocalStorageResources from "../../../resources/local-storage";
import BlockIcon from "@mui/icons-material/Block";
import TrashIcon from "@mui/icons-material/Delete";
import moment from "moment";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import PusherChannelSubscriber from "../../../resources/pusher-channel-subscriber";
import Paper from "@material-ui/core/Paper";
import Badge from "@mui/material/Badge";
import TitleBackgroundComponent from "../../shared/components/title-background";

const emojis = [
  {
    name: "praying",
    code: ":praying:",
    emoji: "🙏",
    //imageUrl: "/favicon-16x16.png",
  },
  {
    name: "like",
    code: ":like:",
    emoji: "👍",
  },
  {
    name: "red_heart",
    code: ":red_heart:",
    emoji: "❤️",
  },
  {
    name: "caring_hug",
    code: ":caring_hug:",
    emoji: "🤗",
  },
  {
    name: "raising_handing",
    code: ":raising_handing:",
    emoji: "🙌",
  },
  {
    name: "laughing",
    code: ":laughing:",
    emoji: "😂",
  },
  {
    name: "smile_face",
    code: ":smile_face:",
    emoji: "😄",
  },
];

const emojisReactions = [
  {
    name: "praying",
    code: ":praying:",
    emoji: "🙏",
    //imageUrl: "/favicon-16x16.png",
  },
  {
    name: "like",
    code: ":like:",
    emoji: "👍",
  },
  {
    name: "red_heart",
    code: ":red_heart:",
    emoji: "❤️",
  },
  {
    name: "caring_hug",
    code: ":caring_hug:",
    imageUrl: "/caring_hug.png",
  },
  {
    name: "raising_handing",
    code: ":raising_handing:",
    emoji: "🙌",
  },
  {
    name: "laughing",
    code: ":laughing:",
    emoji: "😂",
  },
  {
    name: "smile_face",
    code: ":smile_face:",
    emoji: "😄",
  },
];

class MessagesGroupComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      snackbarOpen: false,
      snackbarMessage: null,
      isLoading: false,
      id,
      messageId: null,
      group: {},
      messagesGroup: null,
      messageToSend: "",
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      isLoadUnread: false,
      unreadMessages: 0,
      anchorEl: null,
      showChatEmojis: false,
      controlShowReactions: {},
      messageStringStart: 0,
      messageStringEnd: 0,
      isSendingMessage: false,
    };
    this.inputMessage = React.createRef();
    this.messagesEnd = React.createRef();
    this.messagesUnread = React.createRef();
    this.handleChangeInputMessage = this.handleChangeInputMessage.bind(this);
    this.handleNewMessage = this.handleNewMessage.bind(this);
    this.handleNewReactionMessage = this.handleNewReactionMessage.bind(this);
    this.handleClickInputMessage = this.handleClickInputMessage.bind(this);
    this.handleKeyDownInputMessage = this.handleKeyDownInputMessage.bind(this);
    this.handleReceivedDeleteMessage =
      this.handleReceivedDeleteMessage.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  onSelectChatEmoji = (emojiObject) => {
    this.setState({
      messageToSend:
        this.state.messageToSend.substring(0, this.state.messageStringStart) +
        emojiObject.emoji +
        this.state.messageToSend.substring(this.state.messageStringEnd),
    });
    this.showChatEmojis();
  };

  onReactionClick = (message) => {
    const { controlShowReactions } = this.state;
    this.setState((prevState) => ({
      controlShowReactions: {
        ...prevState.controlShowReactions,
        [`showReactions${message.id}`]: controlShowReactions[
          `showReactions${message.id}`
        ]
          ? false
          : true,
      },
    }));
  };

  showChatEmojis = () => {
    const { showChatEmojis } = this.state;
    this.setState({
      showChatEmojis: !showChatEmojis ? true : false,
    });
  };

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "auto" });
    }
  };

  showUnread = () => {
    if (this.messagesUnread) {
      this.messagesUnread.scrollIntoView({ behavior: "auto", block: "center" });
      this.setState({
        isLoadUnread: true,
      });
    }
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClickInputMessage(event) {
    this.setState({
      messageStringStart: event.target.selectionStart,
      messageStringEnd: event.target.selectionEnd,
    });
  }

  handleKeyDownInputMessage(event) {
    this.setState({
      messageStringStart: event.target.selectionStart + 1,
      messageStringEnd: event.target.selectionEnd + 1,
    });
  }

  onSelectReaction(emoji, messageId, action) {
    const { group } = this.state;
    const data = {
      emoji: emoji.code,
      act: action,
    };
    MessagesGroupResources.messageReaction(group.id, messageId, data).then(
      () => {
        this.setState((prevState) => ({
          controlShowReactions: {
            ...prevState.controlShowReactions,
            [`showReactions${messageId}`]: false,
          },
        }));
      }
    );
  }

  addOrDeleteReaction(emoji, message) {
    const { group } = this.state;
    const userData = LocalStorageResources.getObject("authData").data;
    let findUserReaction = emoji.users_reactions.filter(
      (reaction) => reaction.user_id == userData.id
    )[0];
    let action = "";
    if (!findUserReaction) {
      action = "add";
    } else {
      action = "remove";
    }
    const data = {
      emoji: emoji.code,
      act: action,
    };
    MessagesGroupResources.messageReaction(group.id, message.id, data).then(
      () => {
        this.setState((prevState) => ({
          controlShowReactions: {
            ...prevState.controlShowReactions,
            [`showReactions${message.id}`]: false,
          },
        }));
      }
    );
  }

  sendMessage() {
    const { group, messageToSend } = this.state;
    if (messageToSend) {
      this.setState({
        isSendingMessage: true,
      });
      var data = {
        message: {
          content: messageToSend,
        },
      };
      OurPrayersResources.sendMessageGroup(group.id, data)
        .then(() => {
          this.setState({
            messageToSend: "",
            isSendingMessage: false,
          });
          this.scrollToBottom();
        })
        .catch(() => {
          this.setState({
            isSendingMessage: false,
          });
        });
    } else {
      this.setState({
        isSendingMessage: false,
      });
    }
  }

  loadGroup() {
    const { id, message } = this.state;
    this.setState({
      isLoading: true,
    });
    OurPrayersResources.getGroup(id).then((data) => {
      this.setState({
        group: data,
        unreadMessages: data.unread_messages,
      });
      this.loadMessages(data);
      if (!(data.invitation_status && data.invitation_status == "pending")) {
        if (message) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: message,
            message: null,
          });
        }
      } else {
        this.props.history.push(`/`);
      }
    });
  }

  loadMessages(group) {
    OurPrayersResources.messagesGroup(group.id).then((data) => {
      this.setState({
        messagesGroup: data,
      });
      let controlShowReactions = {};
      data.map((message) => {
        controlShowReactions[`showReactions${message.id}`] = false;
      });
      this.setState({
        isLoading: false,
        controlShowReactions,
      });
      this.loadPusherSubscriberChatMessages(group.pusher_channel);
      if (group.unread_messages > 0) {
        OurPrayersResources.markAsReadMessagesGroup(group.id);
      } else {
        this.scrollToBottom();
      }
    });
  }

  loadPusherSubscriberChatMessages(pusher_channel) {
    PusherChannelSubscriber.subscribeChannelMessages(
      this.handleNewMessage,
      this.handleReceivedDeleteMessage,
      this.handleNewReactionMessage,
      pusher_channel
    );
  }

  handleChangeInputMessage(e) {
    this.setState({ messageToSend: e.target.value });
  }

  handleNewMessage(data) {
    const { group } = this.state;
    this.setState((prevState) => ({
      messagesGroup: [...prevState.messagesGroup, data],
      unreadMessages: 0,
    }));
    this.scrollToBottom();
    OurPrayersResources.markAsReadMessagesGroup(group.id);
  }

  handleNewReactionMessage(data) {
    const { messagesGroup } = this.state;
    let messagePosition = messagesGroup.findIndex((mg) => mg.id == data.id);
    messagesGroup[messagePosition] = data;
    this.setState({
      messagesGroup,
    });
  }

  handleReceivedDeleteMessage(data) {
    const { messagesGroup } = this.state;
    if (messagesGroup) {
      let newMessagesGroup = messagesGroup;
      let positionIndex = newMessagesGroup
        .map(function (x) {
          return x.id;
        })
        .indexOf(data.id);
      newMessagesGroup[positionIndex] = data;
      this.setState({
        messagesGroup: newMessagesGroup,
      });
    }
  }

  handleDeleteMessage(id) {
    const { group } = this.state;
    OurPrayersResources.deleteMessagesGroup(group.id, id);
  }

  handleClickDeleteMessage(id) {
    this.setState({
      messageId: id,
      openDeleteModalConfirmation: true,
    });
  }

  goBack() {
    const { group } = this.state;
    location.href = `/our-prayers/${group.id}/view-group`;
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  renderEmojis(toReactions = false, item = null) {
    let emojisToRender = null;
    if (toReactions) {
      emojisToRender = !toReactions
        ? emojisReactions.filter((emoji) => !emoji.imageUrl)
        : emojisReactions;
    } else {
      emojisToRender = !toReactions
        ? emojis.filter((emoji) => !emoji.imageUrl)
        : emojis;
    }

    return (
      <div
        style={{
          display: "grid",
          height: "30px",
          position: "absolute",
          gridTemplateColumns: "auto auto auto auto auto auto auto",
          top: "-29px",
          left: !toReactions ? "10px" : null,
          right: toReactions ? "0" : null,
          zIndex: 2,
          border: "1px solid #d9d9d9",
          borderRadius: "5px",
          background: "#f1f1f1",
        }}
      >
        {emojisToRender.map((emoji, i) => (
          <Paper
            key={i}
            onClick={() => {
              if (!toReactions) {
                this.onSelectChatEmoji(emoji);
              } else {
                this.onSelectReaction(emoji, item, "add");
              }
            }}
            style={{
              borderRadius: 0,
              marginTop: "5px",
              boxShadow:
                "0px 2px 1px -1px rgb(255 255 255 / 0%), 0px 1px 1px 0px rgb(255 255 255 / 0%), 0px 1px 3px 0px rgb(255 255 255 / 0%)",
              display: "inline-grid",
              height: "3vh",
              width: "5vh",
              border: 0,
              backgroundColor: "#ffffff00",
              textAlign: "center",
            }}
          >
            {emoji.emoji ? (
              emoji.emoji
            ) : (
              <img
                src={emoji.imageUrl}
                alt={emoji.code}
                style={{
                  width: "18px",
                  height: "18px",
                  display: "block",
                  margin: "auto",
                }}
              />
            )}
          </Paper>
        ))}
      </div>
    );
  }

  renderInputMessage() {
    const { showChatEmojis, messageToSend, isSendingMessage } = this.state;
    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          margin: "auto",
          paddingBottom: "15px",
          left: 0,
          maxWidth: "500px",
          backgroundColor: "#ffffff",
          zIndex: 2,
          height: "8vh",
        }}
      >
        {showChatEmojis && this.renderEmojis()}
        <Paper
          style={{
            top: "12px",
            left: "10px",
            position: "absolute",
            width: "34px",
            height: "29px",
            backgroundImage: `url("/chat-icons/Emoji_IconOrange.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
          }}
          onClick={() => this.showChatEmojis()}
        />
        <TextareaAutosize
          placeholder="Type Something to send..."
          value={messageToSend}
          name={"message"}
          ref={(el) => {
            this.inputMessage = el;
          }}
          style={{
            width: "67%",
            marginLeft: "12%",
            marginTop: "6px",
            border: "none",
            overflow: "auto",
            outline: "none",
            webkitBoxShadow: "none",
            mozBoxShadow: "none",
            boxShadow: "none",
            resize: "none",
            fontSize: "14px",
            height: "18px",
            padding: "12px 12px 18px",
            borderRadius: "25px",
            backgroundColor: "#f0f0f0",
          }}
          onChange={this.handleChangeInputMessage}
          onClick={this.handleClickInputMessage}
          onKeyDown={this.handleKeyDownInputMessage}
          multiline={true}
          maxRows={4}
          type={"input"}
        />
        <Paper
          style={{
            top: "12px",
            right: "10px",
            position: "absolute",
            width: "34px",
            height: "29px",
            backgroundImage: `url("/chat-icons/SendChat_Icon.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
          }}
          onClick={() => (!isSendingMessage ? this.sendMessage() : null)}
        />
      </div>
    );
  }

  renderUserReactions(message, myMessage) {
    return (
      <>
        {!message.user_deleted && (
          <div
            style={{
              display: "inline-flex",
              position: "absolute",
              top: "-15px",
              zIndex: "2",
              height: "22px",
              backgroundColor: "#f1f1f1",
              left: myMessage ? "10px" : null,
              right: !myMessage ? `10px` : null,
              marginLeft: "auto",
              marginRight: "0",
              borderRadius: "8px",
            }}
          >
            {message.reactions.map((emoji, i) => (
              <Badge
                key={i}
                onClick={() =>
                  !myMessage && this.addOrDeleteReaction(emoji, message)
                }
                style={{ color: "#ffffff" }}
                edge="start"
                size="small"
                sx={{
                  "& .MuiBadge-badge": {
                    top: emoji.users_reactions.length > 1 && "3px",
                    left: emoji.users_reactions.length > 1 && "3px",
                    height: emoji.users_reactions.length > 1 && "12px",
                    width: emoji.users_reactions.length > 1 && "0.1px",
                    backgroundColor:
                      emoji.users_reactions.length > 1 && "#F07522",
                  },
                }}
                color="primary"
                showZero={false}
                badgeContent={
                  emoji.users_reactions.length > 1 &&
                  emoji.users_reactions.length
                }
              >
                <Paper
                  key={i}
                  style={{
                    height: "18px",
                    width: "18px",
                    fontSize: "16px",
                    marginRight: "4px",
                    marginLeft: "4px",
                    position: "relative",
                    color: "#f07522",
                    backgroundColor: "#ffffff00",
                    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
                  }}
                >
                  {emojisReactions.filter((em) => em.code == emoji.code)[0]
                    .emoji ? (
                    emojisReactions.filter((em) => em.code == emoji.code)[0]
                      .emoji
                  ) : (
                    <img
                      src={
                        emojisReactions.filter((em) => em.code == emoji.code)[0]
                          .imageUrl
                      }
                      alt={
                        emojisReactions.filter((em) => em.code == emoji.code)[0]
                          .code
                      }
                      style={{
                        width: "18px",
                        height: "18px",
                        paddingTop: "3px",
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  )}
                </Paper>
              </Badge>
            ))}
          </div>
        )}
      </>
    );
  }

  renderIconReactions(item) {
    return (
      <div
        style={{
          display: "inline-flex",
          position: "absolute",
          top: "-15px",
          zIndex: "2",
          height: "22px",
          backgroundColor: "#f1f1f1",
          right: `10px`,
          marginLeft: "auto",
          marginRight: "0",
          borderRadius: "8px",
        }}
      >
        <Paper
          style={{
            top: "15px",
            left: "15px",
            position: "absolute",
            width: "20px",
            height: "21px",
            backgroundColor: "#f5f5f5",
            backgroundImage: `url("/chat-icons/Emoji_IconGrey.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "100% 100%",
            boxShadow:
              "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
          }}
          onClick={() => this.onReactionClick(item)}
        />
      </div>
    );
  }

  renderPhotoProfile(item, position) {
    return (
      <Grid item xs={1}>
        <img
          alt=""
          style={{
            width: "50px",
            height: "50px",
            left: position == "left" && "-54px",
            borderRadius: "30px",
            bottom: 0,
            backgroundColor: "#ffffff",
            position: "absolute",
            zIndex: "2",
          }}
          src={
            item.user_avatar_url ? item.user_avatar_url : `/default-avatar.png`
          }
        />
      </Grid>
    );
  }

  renderTheirMessages(item) {
    const { controlShowReactions } = this.state;

    return (
      <div
        style={{
          width: "100%",
          marginBottom: "3vh",
          position: "relative",
        }}
      >
        <Grid
          item
          style={{
            backgroundColor: item.user_deleted ? "#f9a872" : "#ffffff",
            height: "auto",
            minHeight: "50px",
            marginLeft: "58px",
            marginRight: "38px",
            marginTop: "7px",
            borderRadius: "2.2vh",
            position: "relative",
            boxShadow: "rgb(153 153 153 / 26%) 3px 3px 3px",
          }}
        >
          {this.renderPhotoProfile(item, "left")}
          {this.renderUserReactions(item, false)}
          {!item.user_deleted && this.renderIconReactions(item)}
          {controlShowReactions[`showReactions${item.id}`] &&
            this.renderEmojis(true, item.id)}
          <Typography
            style={{
              padding: !item.user_deleted ? "10px 14px" : "6px 14px",
              overflow: "hidden",
              color: item.user_deleted ? "#fff" : "#919191",
              fontStyle: item.user_deleted ? "italic" : "initial",
              textOverflow: "ellipsis",
              whiteSpace: "pre-line",
            }}
          >
            {item.user_deleted && (
              <BlockIcon
                style={{
                  color: "#fff",
                  top: "7px",
                  right: "3px",
                  position: "relative",
                }}
              />
            )}
            {item.content}
          </Typography>
          <Typography
            style={{
              fontSize: "10px",
              top: "-16px",
              left: "6px",
              position: "absolute",
              color: "#919191",
            }}
          >
            {`${item.user_first_name} ${item.user_last_name} - ${String(
              moment(item.created_at).format("MM-DD hh:mm A")
            )}`}
          </Typography>
        </Grid>
      </div>
    );
  }

  renderMyMessages(item) {
    return (
      <div
        style={{
          width: "100%",
          marginBottom: "3vh",
          position: "relative",
        }}
      >
        <Grid
          item
          style={{
            backgroundColor: item.user_deleted ? "#f9a872" : "#f07522",
            height: "auto",
            minHeight: "50px",
            marginLeft: "80px",
            marginRight: "16px",
            marginTop: "7px",
            borderRadius: "2.2vh",
            position: "relative",
            boxShadow: "rgb(153 153 153 / 26%) 3px 3px 3px",
          }}
        >
          {!item.user_deleted && (
            <div style={{ position: "absolute", bottom: 0, left: "-32px" }}>
              <TrashIcon
                onClick={() => this.handleClickDeleteMessage(item.id)}
                style={{
                  color: "#cfcfcf",
                }}
              />
            </div>
          )}
          {this.renderUserReactions(item, true)}
          <Typography
            style={{
              padding: !item.user_deleted ? "10px 14px" : "6px 14px",
              overflow: "hidden",
              color: "#fff",
              fontStyle: item.user_deleted ? "italic" : "initial",
              textOverflow: "ellipsis",
              whiteSpace: "pre-line",
            }}
          >
            {item.user_deleted && (
              <BlockIcon
                style={{
                  color: "#fff",
                  top: "7px",
                  right: "3px",
                  position: "relative",
                }}
              />
            )}
            {item.content}
          </Typography>
          <Typography
            style={{
              fontSize: "10px",
              top: "-16px",
              right: "6px",
              position: "absolute",
              color: "#f07522",
            }}
          >
            {`Me - ${String(moment(item.created_at).format("MM-DD hh:mm A"))}`}
          </Typography>
        </Grid>
      </div>
    );
  }

  renderAutoMessages(item) {
    return (
      <Grid key={item.id} container direction="column" alignItems="center">
        <Grid
          item
          style={{
            backgroundColor: "#e5e5e5",
            height: "auto",
            marginBottom: "3vh",
            textAlign: "center",
            position: "relative",
          }}
        >
          <Typography
            style={{
              color: "#989898",
              fontSize: "13px",
              paddingLeft: "10px",
              paddingRight: "9px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.content}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  renderDeleteModalConfirmation() {
    const { openDeleteModalConfirmation, messageId } = this.state;
    return (
      <ConfirmationComponent
        open={openDeleteModalConfirmation}
        handleAccept={() => {
          this.setState({ openDeleteModalConfirmation: false });
          this.handleDeleteMessage(messageId);
        }}
        handleCancel={() => {
          this.setState({ openDeleteModalConfirmation: false });
        }}
        title="Confirm"
        message="Are you sure you want to delete this message?"
        AcceptButtonText="Yes"
        CancelButtonText="No"
      ></ConfirmationComponent>
    );
  }

  render() {
    const {
      isLoading,
      snackbarOpen,
      snackbarMessage,
      messagesGroup,
      unreadMessages,
      group,
    } = this.state;
    const userData = LocalStorageResources.getObject("authData").data;
    var unreadLabel = false;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              backgroundColor: "#f5f5f5",
              height: "100%",
            }}
          >
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.goBack()}
              showMenu
              showNotifications
              title={"Prayr App"}
            />
            <TitleBackgroundComponent title={group.name} />
            <div
              style={{
                height: "78.5vh",
                width: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  top: "0.5vh",
                  width: "100%",
                  overflowY: "scroll",
                  overflowx: "hidden",
                  backgroundColor: "#f5f5f5",
                }}
              >
                {messagesGroup && (
                  <div style={{ margin: "5vh 0 4vh", paddingBottom: "2vh" }}>
                    {messagesGroup.map((item) => (
                      <>
                        {unreadMessages > 0 && !item.read && !unreadLabel && (
                          <>
                            {(unreadLabel = true)}
                            <Typography
                              style={{
                                width: "256px",
                                height: "20px",
                                borderRadius: "15px",
                                color: "#000",
                                textAlign: "center",
                                fontSize: "12px",
                                top: "-11px",
                                marginLeft: "55px",
                                position: "relative",
                              }}
                            >
                              {unreadMessages} unread message(s)
                            </Typography>
                            <div
                              style={{ float: "left", clear: "both" }}
                              ref={(la) => {
                                this.messagesUnread = la;
                                if (!this.state.isLoadUnread) {
                                  this.showUnread();
                                }
                              }}
                            />
                          </>
                        )}
                        {userData.id == item.user_id &&
                          !item.autoresponse &&
                          this.renderMyMessages(item)}

                        {userData.id != item.user_id &&
                          !item.autoresponse &&
                          this.renderTheirMessages(item)}

                        {userData.id != item.user_id &&
                          item.autoresponse &&
                          this.renderAutoMessages(item)}
                      </>
                    ))}
                  </div>
                )}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={(el) => {
                    this.messagesEnd = el;
                  }}
                />
              </div>
              {this.renderInputMessage()}
            </div>
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
        {this.renderDeleteModalConfirmation()}
      </div>
    );
  }
}

export default MessagesGroupComponent;
