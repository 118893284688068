import { api } from "../environments/environment";
import HttpService from "./http";
import ResourcesUtils from "./utils";

export default {
  async createOrUpdateUserEmailSettings(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/prayer_settings`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async get() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/prayer_setting`, {
        headers,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async getUserInfo() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/users/info`, {
        headers,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  async updateUserInfo(data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/auth`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  async updatePasswordUser(data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/auth/password`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  async updateEmailUser(data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/auth`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
};
