import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import ButtonComponent from "../../../shared/components/button";
import TitleDescriptionComponent from "../../../shared/components/title-description";
import InputComponent from "../../../shared/components/input";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Paper } from "@mui/material";
import LocalStorageResources from "../../../../resources/local-storage";
import Divider from "@mui/material/Divider";

class ShareFormComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      dataForm,
      inputChange,
      onSubmit,
      imageUrl,
      showImages,
      onToggleMessage,
      messageChannel,
      submitText,
      prayer,
    } = this.props;
    const userData = LocalStorageResources.getObject("authData").data;

    const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
      "& .MuiToggleButtonGroup-grouped": {
        "&.Mui-selected": {
          color: "#fff",
          backgroundColor: "#f07522",
        },
        "&:not(:first-of-type)": {
          borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
          borderRadius: theme.shape.borderRadius,
        },
      },
    }));

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ marginBottom: "2vh" }}
      >
        {dataForm.message_type == "pray_now" && (
          <Grid container direction="column" alignItems="center">
            <Grid item xs={9} style={{ marginTop: "2vh", width: "90%" }}>
              <Typography
                style={{
                  color: "#565656",
                  fontWeight: "bolder",
                  textAlign: "center",
                }}
              >
                Marked as Complete!
              </Typography>
              <Typography
                style={{
                  color: "#565656",
                  fontSize: "0.9rem",
                  marginBottom: "1vh",
                  textAlign: "center",
                }}
              >
                This Prayer was moved to the bottom of your list until tomorrow.
              </Typography>
              {dataForm.user_settings?.automatic_message_sending &&
                !dataForm.to && (
                  <Typography
                    style={{
                      color: "#565656",
                      fontSize: "0.9rem",
                      marginBottom: "1vh",
                      textAlign: "center",
                    }}
                  >
                    {`We were unable to automatically send the message because
                    this prayer request doesn't have an email address.`}
                  </Typography>
                )}
              <Divider style={{ width: "100%", marginTop: "0.5vh" }}></Divider>
            </Grid>
          </Grid>
        )}
        <TitleDescriptionComponent
          typeTitle={"secondary"}
          description={`Select how you want to message this person`}
          fontSizeSubtitle={"1rem"}
        >
          <StyledToggleButtonGroup
            color="primary"
            size="small"
            exclusive
            style={{
              borderRadius: "5vh",
              width: "80%",
              marginLeft: "10%",
              marginTop: "1.5vh",
              backgroundColor: "white",
              boxShadow:
                "rgb(0 0 0 / 16%) 0px 2px 5px, rgb(0 0 0 / 1%) 0px 2px 5px",
            }}
            fullWidth
          >
            <ToggleButton
              selected={messageChannel == "email"}
              value="email"
              style={{
                borderRadius: "5vh",
                margin: "5px",
                border: "0",
                padding: "5px",
              }}
              onClick={() => {
                onToggleMessage("email");
              }}
            >
              Email
            </ToggleButton>
            <ToggleButton
              value="sms"
              selected={messageChannel == "sms"}
              style={{
                borderRadius: "5vh",
                border: "0",
                margin: "5px",
                padding: "5px",
              }}
              onClick={() => {
                onToggleMessage("sms");
              }}
            >
              TEXT
            </ToggleButton>
            {prayer && userData.id != prayer.user_id && (
              <ToggleButton
                value="in-app"
                selected={messageChannel == "in-app"}
                style={{
                  borderRadius: "5vh",
                  border: "0",
                  margin: "5px",
                  padding: "5px",
                }}
                onClick={() => {
                  onToggleMessage("in-app");
                }}
              >
                In-App
              </ToggleButton>
            )}
          </StyledToggleButtonGroup>
        </TitleDescriptionComponent>

        {(messageChannel == "email" || messageChannel == "in-app") && (
          <Grid container direction="column" alignItems="center">
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                fontWeight: "bold",
                marginBottom: "2vh",
                marginTop: "2vh",
              }}
            >
              {messageChannel == "email"
                ? "Send an Email"
                : messageChannel == "in-app"
                ? "Send a Notification"
                : "Send"}
            </Typography>
            <Grid container spacing={2} style={{ width: "80%" }}>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#aaaaaa",
                    fontSize: "1rem",
                    fontWeight: "light",
                  }}
                >
                  To:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                {messageChannel == "in-app" && (
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#565656",
                      fontSize: "1rem",
                    }}
                  >
                    {dataForm.to}
                  </Typography>
                )}

                {messageChannel != "in-app" && (
                  <TextField
                    value={dataForm.to}
                    style={{ width: "100%" }}
                    id="outlined-start-adornment-1"
                    onChange={inputChange}
                    name="to"
                    sx={{
                      "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                        {
                          display: "none",
                          color: "#808080 !important",
                        },
                      "& .MuiOutlinedInput-input": {
                        padding: "0.8vh",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#808080",
                        marginLeft: "1vh",
                        top: "7%",
                      },
                      "& .MuiOutlinedInput-root": {
                        paddingLeft: "1vh",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        borderColor: "#f0f0f0 !important",
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#808080",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "4vh",
                      },
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#aaaaaa",
                    fontSize: "1rem",
                    fontWeight: "light",
                  }}
                >
                  Subject:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-start-adornment-2"
                  onChange={inputChange}
                  value={dataForm.subject}
                  name="subject"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#808080 !important",
                      },
                    "& .MuiOutlinedInput-input": {
                      padding: "0.8vh",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#808080",
                      marginLeft: "1vh",
                      top: "7%",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "1vh",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#f0f0f0 !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#808080",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4vh",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {messageChannel == "sms" && (
          <Grid container direction="column" alignItems="center">
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                fontWeight: "bold",
                marginBottom: "2vh",
                marginTop: "2vh",
              }}
            >
              Send a Text Message
            </Typography>

            <Grid container spacing={2} style={{ width: "80%" }}>
              <Grid item xs={3} style={{ marginTop: "2vh" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#aaaaaa",
                    fontSize: "1rem",
                    marginTop: "0.5vh",
                    fontWeight: "light",
                  }}
                >
                  Phone:
                </Typography>
              </Grid>
              <Grid item xs={9} style={{ marginTop: "2vh" }}>
                <TextField
                  style={{ width: "100%" }}
                  onChange={inputChange}
                  value={dataForm.phone}
                  name="phone"
                  id="outlined-start-adornment-3"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#808080 !important",
                      },
                    "& .MuiOutlinedInput-input": {
                      padding: "0.8vh",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#808080",
                      marginLeft: "1vh",
                      top: "7%",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "1vh",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#f0f0f0 !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#808080",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4vh",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} style={{ width: "80%" }}>
          <Grid item xs={3}>
            <Typography
              variant="subtitle1"
              style={{
                color: "#aaaaaa",
                fontSize: "1rem",
                marginTop: "2vh",
                fontWeight: "light",
              }}
            >
              Message:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <InputComponent
              inputValue={dataForm.content}
              inputChange={inputChange}
              borderRadius={"2vh"}
              name={"content"}
              label={"Comment"}
              maxRows={4}
              textArea
              width={"100%"}
            />
          </Grid>
        </Grid>
        {imageUrl && (
          <Grid container spacing={2} style={{ width: "80%" }}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#aaaaaa",
                  fontSize: "1rem",
                  marginTop: "2vh",
                  fontWeight: "light",
                }}
              >
                Image:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: "2vh" }}
              >
                <Paper
                  alt=""
                  onClick={showImages}
                  style={{
                    width: "100%",
                    height: "50vh",
                    backgroundImage: `url(` + imageUrl + `)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "3vh",
                    boxShadow:
                      "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 44%) 0px 3px 15px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!imageUrl && (
          <Grid container spacing={2} style={{ width: "80%" }}>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#aaaaaa",
                  fontSize: "1rem",
                  marginTop: "2vh",
                  fontWeight: "light",
                }}
              >
                Image:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container style={{ marginTop: "2vh" }}>
                <Paper
                  onClick={showImages}
                  sx={{
                    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                  }}
                  style={{
                    width: "100%",
                    padding: "4vh",
                    border: "0.10vh solid rgba(130, 130, 130, 0.44)",
                    textAlign: "center",
                    borderRadius: "2vh",
                    height: "12vh",
                    transition: "background 0.3s ease-in-out",
                    color: "#c2c2c2",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      color: "#c2c2c2",
                      margin: 0,
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      transform: "translateY(-50%)",
                    }}
                  >
                    <AddCircleOutlineIcon
                      fontSize="large"
                      style={{ width: "55px", height: "55px" }}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginTop: "3vh" }}
        >
          <ButtonComponent
            name={submitText || "SEND"}
            action={() => {
              onSubmit(messageChannel);
            }}
          />
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Typography
            style={{
              color: "#cfcfcf",
              fontSize: "0.9rem",
              marginTop: "2vh",
              padding: "0 6vh",
              textAlign: "center",
            }}
          >
            {messageChannel == "sms"
              ? "*This will launch the text app on your phone"
              : messageChannel == "email"
              ? "*Email will be sent from no-reply@myprayr.com"
              : ""}
          </Typography>
        </Grid>

        {dataForm.message_type == "pray_now" && messageChannel == "email" && (
          <Grid container direction="column" alignItems="center">
            <Typography
              onClick={() =>
                (location.href = "/my-profile/user-email-settings")
              }
              style={{
                color: "#565656",
                fontSize: "0.9rem",
                marginTop: "2vh",
              }}
            >
              (Setup Automate Messaging Defaults)
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default ShareFormComponent;
