import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MyProfileResources from "../../resources/my-profile";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Divider from "@mui/material/Divider";
import Grid from "@material-ui/core/Grid";
//import { webApp } from "../../environments/environment";
import { deepOrange } from "@mui/material/colors";

class MyProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: true,
      support: true,
      isLoading: false,
      userData: {
        initials: null,
        first_name: "",
        last_name: "",
        avatar_url: null,
        avatar: null,
      },
    };
  }

  handleClick(option) {
    this.setState({
      [option]: this.state[option] ? false : true,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      isLoading: true,
    });
    MyProfileResources.getUserInfo()
      .then((data) => {
        this.setState({
          userData: data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { userData } = this.state;
    return (
      <div>
        <HeaderBarComponent
          showLogo
          showBack
          showBackAction={() => this.props.history.push(`/`)}
          showMenu
          showNotifications
          title={"Prayr Apps"}
        />
        <div>
          <ListItem style={{ backgroundColor: "#e7e7e7", color: "#848283" }}>
            <ListItemText
              primary="Account"
              primaryTypographyProps={{ fontWeight: "600" }}
            />
          </ListItem>
          <List sx={{ width: "100%" }}>
            <ListItem
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              }
              onClick={() => this.props.history.push(`/my-profile/user-info`)}
            >
              <ListItemAvatar>
                {!userData.avatar_url && (
                  <Avatar sx={{ bgcolor: deepOrange[500] }}>
                    {userData.initials}
                  </Avatar>
                )}
                {userData.avatar_url && (
                  <Avatar>
                    <div
                      style={{
                        backgroundImage: userData.avatar_url
                          ? `url("` + userData.avatar_url + `")`
                          : null,
                        backgroundSize: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                    ></div>
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={userData.first_name + " " + userData.last_name}
              />
            </ListItem>
            <Divider />
            <ListItem
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              }
              onClick={() =>
                this.props.history.push(`/my-profile/reset-password-email`)
              }
            >
              <ListItemText primary="Email and Password" />
            </ListItem>
            {/* <Divider />
            <ListItem
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              }
              onClick={() => this.props.history.push(`/my-profile/license`)}
            >
              <ListItemText primary="License" />
            </ListItem> */}
          </List>
          {/* <ListItem style={{ backgroundColor: "#e7e7e7", color: "#848283" }}>
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{ fontWeight: "600" }}
            />
          </ListItem> */}
          {/* <List sx={{ width: "100%" }}>
            <ListItem
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              }
              onClick={() =>
                this.props.history.push(`/my-profile/user-email-settings`)
              }
            >
              <ListItemText primary="Prayer Notifications Settings" />
            </ListItem>
          </List> */}
          {/* <ListItem style={{ backgroundColor: "#e7e7e7", color: "#848283" }}>
            <ListItemText
              primary="Support"
              primaryTypographyProps={{ fontWeight: "600" }}
            />
          </ListItem>
          <List sx={{ width: "100%" }}>
            <ListItem
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              }
              onClick={() => (location.href = `${webApp}/privacy-policy`)}
            >
              <ListItemText primary="Privacy Policy" />
            </ListItem>
            <Divider />
            <ListItem
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              }
              onClick={() => (location.href = `${webApp}/terms-of-use`)}
            >
              <ListItemText primary="Terms of Use" />
            </ListItem>
            <Divider />
            <ListItem
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              }
              onClick={() => (location.href = `${webApp}/contact`)}
            >
              <ListItemText primary="Contact Us" />
            </ListItem>
            <Divider />
          </List> */}
        </div>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ position: "absolute", bottom: "0" }}
        >
          <ListItem
            style={{
              backgroundColor: "#e7e7e7",
              color: "#848283",
              textAlign: "center",
            }}
          >
            <ListItemText primary="PRAYR v1.0" />
          </ListItem>
        </Grid>
      </div>
    );
  }
}

export default MyProfileComponent;
