/* eslint-disable react/no-unescaped-entities */
import Grid from "@material-ui/core/Grid";
import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import TitleDescriptionComponent from "../../shared/components/title-description";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ButtonComponent from "../../shared/components/button";
import FaithEntriesResources from "../../../resources/faith-entries";
import Snackbar from "@material-ui/core/Snackbar";
import InputComponent from "../../shared/components/input";
import UploadImageComponent from "../../shared/components/upload-image";
import Backdrop from "@mui/material/Backdrop";
import ConfirmationComponent from "../../shared/confirmation/confirmation";
import CircularProgress from "@mui/material/CircularProgress";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import DateRangeIcon from "@mui/icons-material/DateRange";

class EditFaithComponent extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      isUpdating: false,
      id,
      event_date: moment(moment(), "MM/DD/yyyy"),
      faithEntry: {
        event_date: null,
        content: "",
        image: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangePicker = this.handleChangePicker.bind(this);
    this.update = this.update.bind(this);
    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.calendarRef = React.createRef();
    this.openCalendar = this.openCalendar.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }

  openCalendar() {
    this.calendarRef.current.click();
  }

  componentDidMount() {
    this.loadFaithEntry();
  }

  openDeleteModal() {
    this.setState({ openDeleteModalConfirmation: true });
  }

  deleteAvatar() {
    this.setState((prevState) => ({
      faithEntry: { ...prevState.faithEntry, image: "null", image_url: null },
    }));
  }

  loadFaithEntry() {
    const { id } = this.state;
    this.setState({
      isLoading: true,
    });
    FaithEntriesResources.get(id).then((data) => {
      this.setState({
        event_date: data.event_date,
        faithEntry: data,
        isLoading: false,
      });
    });
  }

  update() {
    const { faithEntry } = this.state;
    if (!faithEntry.event_date) {
      const snackbarMessage = "Enter a Event date";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    } else {
      if (!faithEntry.content) {
        const snackbarMessage = "Enter a Entry content";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
          isUpdating: true,
        });
      } else {
        var faithData = {};
        faithData.faith_entry = faithEntry;
        this.setState({ isLoading: true });
        FaithEntriesResources.update(faithData)
          .then(() => {
            this.props.history.push(`/faith-builder`);
          })
          .catch(() => {
            this.setState({ isLoading: false, isUpdating: false });
          });
      }
    }
  }

  back() {
    this.props.history.push(`/faith-builder`);
  }

  handleChangePicker(evt) {
    this.setState((prevState) => ({
      faithEntry: {
        ...prevState.faithEntry,
        event_date: evt.format("DD/MM/yyyy"),
      },
      event_date: evt,
    }));
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = target != "image" ? evt.target.value : evt.target.files[0];
    this.setState((prevState) => ({
      faithEntry: { ...prevState.faithEntry, [target]: value },
    }));
    if (target == "image") {
      var file = evt.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          this.setState((prevState) => ({
            faithEntry: { ...prevState.faithEntry, image: reader.result },
          }));
        };
      }
    }
  }

  delete() {
    const { faithEntry } = this.state;
    this.setState({
      isLoading: true,
      isUpdating: true,
    });
    FaithEntriesResources.delete(faithEntry.id)
      .then(() => {
        this.props.history.push(`/faith-builder`, {
          message: "The entry has been successfully deleted",
        });
      })
      .catch(() => {
        this.setState({ isLoading: false, isUpdating: false });
      });
  }

  renderDeleteModalConfirmation() {
    const { openDeleteModalConfirmation } = this.state;
    return (
      <ConfirmationComponent
        open={openDeleteModalConfirmation}
        handleAccept={() => {
          this.setState({ openDeleteModalConfirmation: false });
          this.delete();
        }}
        handleCancel={() => {
          this.setState({ openDeleteModalConfirmation: false });
        }}
        title="Confirm"
        message="Are you sure you want to delete this faith entry?"
        AcceptButtonText="Yes"
        CancelButtonText="No"
      ></ConfirmationComponent>
    );
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  button(props) {
    const computedClassName = props.active ? "active" : "muted";
    return <button className={computedClassName}>Click Me</button>;
  }

  render() {
    const {
      faithEntry,
      snackbarMessage,
      snackbarOpen,
      isLoading,
      event_date,
      isUpdating,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {(!isLoading || isUpdating) && (
          <div>
            <div>
              <HeaderBarComponent
                showLogo
                showBack
                showBackAction={() => this.props.history.push(`/faith-builder`)}
                showMenu
                showNotifications
                title={"Prayr Apps"}
              />
              <Grid container direction="column" alignItems="center">
                <div>
                  <Grid container alignItems="center" direction="column">
                    <TitleDescriptionComponent
                      title={"Edit Entry"}
                      description={`It is easy to forget God's faithfulness and all HE has
                      done for us over the years. Add entries to Faith Builder
                      as a permanent reminder. Perfect for times you are
                      struggling or when you want to worship HIM.`}
                    />
                  </Grid>

                  <Grid container direction="column" alignItems="center">
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <MobileDatePicker
                        label="Event Date"
                        inputFormat="MM/DD/yyyy"
                        value={event_date}
                        onChange={this.handleChangePicker}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={"event_date"}
                            style={{ marginTop: "2vh", width: "100%" }}
                            inputRef={this.calendarRef}
                            sx={{
                              "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                                {
                                  display: "none",
                                  color: "#808080 !important",
                                },
                              "& .MuiOutlinedInput-notchedOutline": {
                                display: "none",
                              },
                              "& .MuiInputLabel-root": {
                                color: "#808080",
                                position: "absolute",
                              },
                              "& .MuiOutlinedInput-root fieldset": {
                                borderColor: "#f0f0f0 !important",
                              },
                            }}
                            InputProps={{
                              style: {
                                color: "#808080",
                                backgroundColor: "#f0f0f0",
                                borderRadius: "4vh",
                                width: "80%",
                                //maxWidth: "350px",
                                margin: "auto",
                              },
                              endAdornment: (
                                <InputAdornment position="start">
                                  <DateRangeIcon
                                    style={{ color: "#808080" }}
                                    onClick={this.openCalendar}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <InputComponent
                      inputValue={faithEntry.content}
                      inputChange={this.handleChange}
                      name={"content"}
                      label={"ADD ENTRY CONTENT"}
                      textArea
                    />
                  </Grid>

                  <Grid container direction="column" alignItems="center">
                    <UploadImageComponent
                      targetName={"image"}
                      avatarChange={this.handleChange}
                      avatar={faithEntry.image ? faithEntry.image : null}
                      avatarUrl={
                        faithEntry.image_url ? faithEntry.image_url : null
                      }
                      paddingTopLabel={"9vh"}
                      borderColor={"#828282bd"}
                      deleteAvatar={this.deleteAvatar}
                      backgroundColor={"#ffffff00"}
                      width={"60vw"}
                      height={"21vh"}
                      borderSize={"0.25vh"}
                      color={"#808080"}
                      label={"Select a photo from camera roll"}
                    />
                  </Grid>

                  <Grid container direction="column" alignItems="center">
                    <ButtonComponent
                      name={"SAVE AND CLOSE"}
                      action={this.update}
                      backgroundColor={"#F07522"}
                      color={"#ffffff"}
                      borderRadius={"3vh"}
                      fontSize={"0.9rem"}
                      paddingTop={"1.1vh"}
                    />
                    <Button
                      style={{
                        marginBottom: "2vh",
                        color: "#F07522",
                        width: "10vh",
                        height: "6vh",
                        fontSize: "0.9rem",
                        textDecoration: "underline",
                      }}
                      variant="text"
                      onClick={() => {
                        this.setState({
                          openDeleteModalConfirmation: true,
                        });
                      }}
                    >
                      DELETE
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </div>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
            {this.renderDeleteModalConfirmation()}
          </div>
        )}
      </div>
    );
  }
}

export default EditFaithComponent;
