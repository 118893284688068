import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import ButtonComponent from "../shared/components/button";
import Snackbar from "@material-ui/core/Snackbar";
import AuthResources from "../../resources/auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

class ResetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    const queryString = require("query-string");
    const headers = queryString.parse(props.location.search);
    this.state = {
      user: {
        password: null,
        password_confirmation: null,
      },
      headers,
      showPassword: false,
      showPasswordConfirm: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendData = this.sendData.bind(this);
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    this.setState((prevState) => ({
      user: { ...prevState.user, [target]: value },
    }));
  }

  showPassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: showPassword ? false : true });
  }

  showPasswordConfirm() {
    const { showPasswordConfirm } = this.state;
    this.setState({ showPasswordConfirm: showPasswordConfirm ? false : true });
  }

  async sendData() {
    const { user, headers } = this.state;
    if (!user.password) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your new password",
      });
    } else {
      if (!user.password_confirmation) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "Confirm your new password",
        });
      } else {
        if (user.password != user.password_confirmation) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Passwords do not match",
          });
        } else {
          AuthResources.resetPassword(user, headers).then((response) => {
            response.json().then((parsedJson) => {
              if (
                parsedJson.errors &&
                parsedJson.errors.password &&
                parsedJson.errors.password.length > 0
              ) {
                this.setState({
                  snackbarOpen: true,
                  snackbarMessage: parsedJson.errors.password[0].toUpperCase(),
                });
              } else {
                this.props.history.push(`/`);
              }
            });
          });
        }
      }
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { snackbarOpen, snackbarMessage, showPassword, showPasswordConfirm } =
      this.state;

    return (
      <div>
        <div
          style={{
            backgroundImage: `url("loginbg-img.jpg")`,
            height: "100vh",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <HeaderBarComponent
            showLogo
            showBack
            showBackAction={() => this.props.history.push(`/`)}
            title={"Prayr App"}
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              marginTop: "8vh",
              position: "absolute",
            }}
          >
            <Grid item xs={10}>
              <Typography
                variant="h5"
                style={{ color: "#ffffff", textAlign: "center" }}
              >
                RESET PASSWORD
              </Typography>
            </Grid>

            <Grid
              item
              xs={10}
              style={{
                marginTop: "2vh",
              }}
            >
              <TextField
                label="NEW PASSWORD"
                style={{ width: "100%", marginTop: "2vh" }}
                id="outlined-start-adornment"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={this.handleChange}
                sx={{
                  "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled": {
                    display: "none",
                    color: "#ffffff !important",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#ffffff",
                    marginLeft: "1vh",
                    top: "7%",
                    fontSize: "1.5vh",
                  },
                  "& .MuiOutlinedInput-root": {
                    paddingLeft: "1vh",
                  },
                  "& .MuiOutlinedInput-root fieldset": {
                    borderColor: "#ffffff00 !important",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#ffffff",
                    backgroundColor: "#afafaf57",
                    borderRadius: "4vh",
                  },
                  endAdornment: showPassword ? (
                    <VisibilityIcon
                      onClick={() => this.showPassword()}
                      position="end"
                      style={{ marginRight: "1vh" }}
                    ></VisibilityIcon>
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => this.showPassword()}
                      position="end"
                      style={{ marginRight: "1vh" }}
                    ></VisibilityOffIcon>
                  ),
                }}
              />
              <TextField
                label="CONFIRM YOUR NEW PASSWORD"
                style={{ width: "100%", marginTop: "2vh" }}
                id="outlined-start-adornment"
                name="password_confirmation"
                type={showPasswordConfirm ? "text" : "password"}
                onChange={this.handleChange}
                sx={{
                  "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled": {
                    display: "none",
                    color: "#ffffff !important",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#ffffff",
                    marginLeft: "1vh",
                    top: "7%",
                    fontSize: "1.5vh",
                  },
                  "& .MuiOutlinedInput-root": {
                    paddingLeft: "1vh",
                  },
                  "& .MuiOutlinedInput-root fieldset": {
                    borderColor: "#ffffff00 !important",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#ffffff",
                    backgroundColor: "#afafaf57",
                    borderRadius: "4vh",
                  },
                  endAdornment: showPasswordConfirm ? (
                    <VisibilityIcon
                      onClick={() => this.showPasswordConfirm()}
                      position="end"
                      style={{ marginRight: "1vh" }}
                    ></VisibilityIcon>
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => this.showPasswordConfirm()}
                      position="end"
                      style={{ marginRight: "1vh" }}
                    ></VisibilityOffIcon>
                  ),
                }}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <ButtonComponent
                name={"RESET PASSWORD"}
                action={this.sendData}
                marginTop={"2vh"}
              />
            </Grid>
          </Grid>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default ResetPasswordComponent;
