import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import InputComponent from "../../shared/components/input";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TitleDescriptionComponent from "../../shared/components/title-description";
import MyProfileResources from "../../../resources/my-profile";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonComponent from "../../shared/components/button";

class UserEmailSettingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      email_settings: {
        default_subject: null,
        default_message: null,
        automatic_message_sending: false,
        terms_and_conditions: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.createOrUpdateUserEmailSettings =
      this.createOrUpdateUserEmailSettings.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      isLoading: true,
    });
    MyProfileResources.get()
      .then((data) => {
        this.setState({
          email_settings: {
            default_subject: data.default_subject ? data.default_subject : null,
            default_message: data.default_message ? data.default_message : null,
            automatic_message_sending: data.automatic_message_sending
              ? data.automatic_message_sending
              : false,
            terms_and_conditions: data.terms_and_conditions
              ? data.terms_and_conditions
              : null,
          },
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    if (target != "automatic_message_sending") {
      this.setState((prevState) => ({
        email_settings: { ...prevState.email_settings, [target]: value },
      }));
    } else {
      this.setState((prevState) => ({
        email_settings: {
          ...prevState.email_settings,
          [target]: event.target.checked,
        },
      }));
    }
  }

  createOrUpdateUserEmailSettings() {
    const { email_settings } = this.state;
    if (!email_settings.default_subject) {
      const snackbarMessage = "Enter a default subject";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    } else {
      if (!email_settings.default_message) {
        const snackbarMessage = "Enter a default message";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        var emailSettingsData = {};
        emailSettingsData.prayer_setting = email_settings;
        this.setState({ isLoading: true });
        MyProfileResources.createOrUpdateUserEmailSettings(emailSettingsData)
          .then(() => {
            this.props.history.push(`/my-profile`);
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      }
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { email_settings, snackbarOpen, snackbarMessage, isLoading } =
      this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/my-profile`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <Grid container direction="column" alignItems="center">
              <TitleDescriptionComponent
                title={"Prayer Notifications Settings"}
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <Grid item xs={9}>
                <FormGroup
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="automatic_message_sending"
                        onClick={this.handleChange}
                        value={email_settings.automatic_message_sending}
                        checked={email_settings.automatic_message_sending}
                      />
                    }
                    label="Automatically SEND Email or Text when I click on the PRAYR Icon"
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center">
              <Grid container spacing={2} style={{ width: "80%" }}>
                <Grid item xs={3} style={{ marginTop: "2vh" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#aaaaaa",
                      fontSize: "1rem",
                      marginTop: "0.5vh",
                      fontWeight: "light",
                    }}
                  >
                    Subject:
                  </Typography>
                </Grid>
                <Grid item xs={9} style={{ marginTop: "2vh" }}>
                <Typography
                    variant="subtitle1"
                    style={{
                      color: "#808080",
                      fontSize: "1rem",
                      marginTop: "0.5vh",
                      fontWeight: "light",
                    }}
                  >
                    {email_settings.default_subject}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center">
              <InputComponent
                label="Default Email or Text Message"
                inputValue={email_settings.default_message}
                inputChange={this.handleChange}
                name={"default_message"}
                textArea
              />
            </Grid>

            <Grid container direction="column" alignItems="center">
              <ButtonComponent
                name={"SAVE AND CLOSE"}
                marginTop={"3vh"}
                action={this.createOrUpdateUserEmailSettings}
              />
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default UserEmailSettingsComponent;
