import React from "react";
import { Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

class UploadImageComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      avatar,
      avatarUrl,
      avatarChange,
      borderColor,
      targetName,
      label,
      color,
      backgroundColor,
      borderSize,
      width,
      paddingTopLabel,
      deleteAvatar,
      marginBottom,
      height,
    } = this.props;
    return (
      <div
        style={{
          margin: "auto",
          marginTop: "2vh",
          marginBottom: !marginBottom ? "3vh" : marginBottom,
          position: "relative",
        }}
      >
        <div
          style={{
            width: width,
            maxWidth: "320px",
            padding: "4vh",
            background: backgroundColor,
            border:
              (!avatar || avatar == "null") && !avatarUrl
                ? `${borderSize} ${borderColor} dashed`
                : null,
            borderRadius: "2vh",
            textAlign: "center",
            height: height ? height : "7vh",
            transition: "background 0.3s ease-in-out",
            color: color,
          }}
        >
          {avatar && avatar != "null" && (
            <Paper
              alt=""
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                backgroundImage: `url(` + avatar + `)`,
                borderRadius: "2vh",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                right: "0",
                bottom: "0",
                cursor: "pointer",
                border: "0",
              }}
            >
              <DeleteIcon
                style={{
                  position: "absolute",
                  color: "#fff",
                  right: "2vh",
                  top: "2vh",
                  zIndex: "1",
                }}
                onClick={deleteAvatar}
              />
            </Paper>
          )}
          {(!avatar || avatar == "null") && avatarUrl && (
            <Paper
              alt=""
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                backgroundImage: `url(` + avatarUrl + `)`,
                borderRadius: "2vh",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                right: "0",
                bottom: "0",
                cursor: "pointer",
                border: "0",
              }}
            >
              <DeleteIcon
                style={{
                  position: "absolute",
                  color: "#fff",
                  right: "2vh",
                  top: "2vh",
                  zIndex: "1",
                }}
                onClick={deleteAvatar}
              />
            </Paper>
          )}
          <input
            type="file"
            name={targetName ? targetName : "avatar"}
            id={targetName ? targetName : "avatar"}
            required="required"
            onChange={avatarChange}
            className="input-file"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              opacity: "0",
              cursor: "pointer",
            }}
          />
          <div
            style={{
              paddingTop: paddingTopLabel,
              fontSize: "1.5vh",
              color: color,
            }}
          >
            {label}
          </div>
        </div>
      </div>
    );
  }
}

export default UploadImageComponent;
