export default {
  getString(key) {
    return window.localStorage.getItem(key);
  },
  getObject(key) {
    return JSON.parse(window.localStorage.getItem(key));
  },
  authClear() {
    window.localStorage.removeItem("uid");
    window.localStorage.removeItem("authData");
    window.localStorage.removeItem("client");
    window.localStorage.removeItem("token");
  },
};
