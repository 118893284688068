import React from "react";
import { Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const gradientImages = [
  {
    name: "Grad_1.png",
  },
  {
    name: "Grad_2.png",
  },
  {
    name: "Grad_3.png",
  },
  {
    name: "Grad_4.png",
  },
  {
    name: "Grad_5.png",
  },
  {
    name: "Grad_6.png",
  },
];

class UploadImagePersonalizedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.uploadFile = React.createRef();
    this.state = {
      chooseImageGradient: false,
    };
  }

  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  openChooseImageGradient() {
    this.setState({
      chooseImageGradient: true,
    });
  }

  handleClose() {
    this.setState({
      chooseImageGradient: false,
    });
  }

  render() {
    const {
      avatar,
      avatarUrl,
      avatarChange,
      targetName,
      deleteAvatar,
      label,
      gradientChange,
      backgroundColor,
    } = this.props;
    const { chooseImageGradient } = this.state;
    return (
      <>
        <div
          style={{
            padding: "2.5vh 3.8vh 0",
            color: "707070",
            textAlign: "center",
            fontSize: "0.9rem",
          }}
        >
          {label}
        </div>
        <div
          style={{
            margin: "1vh auto",
            marginBottom: "3vh",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "63vw",
              maxWidth: "320px",
              padding: "4vh",
              backgroundImage: backgroundColor
                ? `url("/gradient-images/${backgroundColor}")`
                : `url("/gradient-images/Grad_1.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              borderRadius: "2vh",
              textAlign: "center",
              height: "18vh",
              transition: "background 0.3s ease-in-out",
              color: "#8b8b8b",
            }}
          >
            {avatar && avatar != "null" && (
              <Paper
                alt=""
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0",
                  left: "0",
                  backgroundImage: `url(` + avatar + `)`,
                  borderRadius: "2vh",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  right: "0",
                  bottom: "0",
                  cursor: "pointer",
                  border: "0",
                }}
              >
                <DeleteIcon
                  style={{
                    position: "absolute",
                    color: "#fff",
                    right: "2vh",
                    top: "2vh",
                    zIndex: "1",
                  }}
                  onClick={deleteAvatar}
                />
              </Paper>
            )}
            {(!avatar || avatar == "null") && avatarUrl && (
              <Paper
                alt=""
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0",
                  left: "0",
                  backgroundImage: `url(` + avatarUrl + `)`,
                  borderRadius: "2vh",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  right: "0",
                  bottom: "0",
                  cursor: "pointer",
                  border: "0",
                }}
              >
                <DeleteIcon
                  style={{
                    position: "absolute",
                    color: "#fff",
                    right: "2vh",
                    top: "2vh",
                    zIndex: "1",
                  }}
                  onClick={deleteAvatar}
                />
              </Paper>
            )}
            <Button
              style={{
                color: "#8b8b8b",
                borderRadius: "3vh",
                width: "135px",
                height: "35px",
                fontSize: "13px",
                textTransform: "inherit",
                backgroundColor: "#ffffff",
                zIndex: 2,
                position: "absolute",
                bottom: "1.5vh",
                left: "1.5vh",
                paddingTop: "10px",
                padding: "8px 12px",
              }}
              size="large"
              variant="contained"
              onClick={() => {
                if (this.uploadFile) {
                  this.uploadFile.click();
                }
              }}
            >
              From camera roll
            </Button>
            <Button
              style={{
                color: "#8b8b8b",
                borderRadius: "3vh",
                width: "135px",
                height: "35px",
                fontSize: "13px",
                textTransform: "inherit",
                backgroundColor: "#ffffff",
                zIndex: 2,
                position: "absolute",
                bottom: "1.5vh",
                right: "1.5vh",
                paddingTop: "10px",
                padding: "8px 12px",
              }}
              size="large"
              variant="contained"
              onClick={() => this.openChooseImageGradient()}
            >
              Use one of ours
            </Button>
            <input
              type="file"
              name={targetName ? targetName : "avatar"}
              id={targetName ? targetName : "avatar"}
              ref={(el) => {
                this.uploadFile = el;
              }}
              required="required"
              onChange={avatarChange}
              className="input-file"
              style={{
                position: "absolute",
                display: "none",
                bottom: "2vh",
                left: "1vh",
                cursor: "pointer",
              }}
            />
          </div>
        </div>

        <Modal
          open={chooseImageGradient}
          onClose={() => this.handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              width: "307px",
              height: "440px",
              borderRadius: "20px",
              backgroundColor: "#ffffff",
              top: "25%",
              left: "50%",
              transform: "translate(-50%)",
              position: "absolute",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                padding: "15px 34px",
                fontSize: "18px",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Select an color to represent this prayer recipient
            </Typography>
            <div
              style={{
                display: "grid",
                height: "3vh",
                textAlign: "right",
                width: "3vh",
                gridTemplateColumns: "auto auto",
              }}
            >
              {gradientImages.map((gradientImage, i) => (
                <Paper
                  key={"gradientImage" + i}
                  onClick={() => {
                    gradientChange(gradientImage.name);
                    this.handleClose();
                  }}
                  style={{
                    display: "inline-grid",
                    width: "120px",
                    height: "90px",
                    borderRadius: "10px",
                    backgroundImage: `url("/gradient-images/${gradientImage.name}")`,
                    margin: "10px 18px 10px",
                    backgroundSize: "cover",
                  }}
                />
              ))}
            </div>
          </Box>
        </Modal>
      </>
    );
  }
}

export default UploadImagePersonalizedComponent;
