import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "@mui/material/Button";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Paper } from "@mui/material";

class SliderImagesComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      data,
      keyUrl,
      emptyBackground,
      actionImage,
      showPhotoCredit = true,
    } = this.props;

    return (
      <Swiper
        slidesPerView={2}
        spaceBetween={150}
        style={{
          marginLeft: "1.5vh",
          marginBottom: "2vh",
          marginTop: "2vh",
          overflow: "visible",
        }}
      >
        {data.map((item, i) => (
          <SwiperSlide key={"img" + i}>
            <Button onClick={() => actionImage(item)}>
              <Paper
                style={{
                  width: "30vh",
                  height: "50vh",
                  borderRadius: "3vh",
                  backgroundImage: `url("${item[keyUrl]}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  boxShadow:
                    "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 44%) 0px 3px 15px",
                }}
              />
            </Button>
            {item.creator_name && showPhotoCredit && (
              <Grid
                item
                xs={12}
                style={{
                  width: "30vh",
                  position: "relative",
                  maxWidth: "initial",
                  textAlign: "center",
                  marginLeft: "8px",
                }}
              >
                Photo Credit: &nbsp;
                <Typography
                  style={{
                    color: item.creator_link ? "#F07522" : "initial",
                    fontSize: "0.9rem",
                    textDecoration: item.creator_link ? "underline" : "inherit",
                    display: "inline-block",
                  }}
                  onClick={() =>
                    item.creator_link
                      ? window.open(
                          item.creator_link,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      : false
                  }
                >
                  {item.creator_name.slice(0, 15)}
                </Typography>
              </Grid>
            )}
          </SwiperSlide>
        ))}
        <SwiperSlide key={"imgEmpty"}>
          <div
            style={{
              width: "30vh",
              height: "50vh",
              backgroundColor: emptyBackground,
              border: 0,
            }}
          />
        </SwiperSlide>
      </Swiper>
    );
  }
}

export default SliderImagesComponent;
