import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Paper } from "@mui/material";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import TitleDescriptionComponent from "../../shared/components/title-description";
import MyPrayersResources from "../../../resources/my-prayers";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";

class ViewPersonComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      snackbarOpen: false,
      snackbarMessage: null,
      isLoading: false,
      id,
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      backUrl: this.props.location?.state?.backUrl
        ? this.props.location.state.backUrl
        : null,
      fromGroup: this.props.location?.state?.fromGroup
        ? this.props.location.state.fromGroup
        : null,
      prayer: {},
    };
    this.backAction = this.backAction.bind(this);
  }

  componentDidMount() {
    this.loadPrayer();
  }

  loadPrayer() {
    const { id, message } = this.state;
    this.setState({
      isLoading: true,
    });
    MyPrayersResources.get(id).then((data) => {
      if (data.group_id) {
        this.setState({
          fromGroup: { id: data.group_id },
        });
      }
      this.setState({
        prayer: data,
        isLoading: false,
      });
      if (message) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: message,
          message: null,
        });
      }
    });
  }

  backAction() {
    const { backUrl, fromGroup } = this.state;
    this.props.history.push(
      backUrl
        ? backUrl
        : fromGroup
        ? `/our-prayers/${fromGroup.id}/view-group`
        : `/my-prayers`
    );
  }

  sendEncouragement(type) {
    const { fromGroup, prayer } = this.state;
    this.props.history.push(`/my-prayers/${prayer.id}/view-person/${type}`, {
      backUrl: `/my-prayers/${prayer.id}/view-person/`,
      fromGroup,
    });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { prayer, isLoading, snackbarOpen, snackbarMessage } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showBack
              showBackAction={this.backAction}
              showMenu
              showLogo
              showNotifications
              title={"Prayr App"}
            />
            <div
              style={{
                backgroundImage: prayer.avatar_url
                  ? `url("` + prayer.avatar_url + `")`
                  : `url("/gradient-images/${prayer.background_color}")`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "35vh",
                position: "relative",
              }}
            >
              <div
                style={{
                  left: prayer.avatar_url ? "2vh" : null,
                  bottom: prayer.avatar_url ? "4vh" : null,
                  position: prayer.avatar_url ? "absolute" : null,
                  color: "#ffffff",
                  width: prayer.avatar_url ? "calc(100% - 4vh)" : null,
                  textAlign: !prayer.avatar_url ? "center" : null,
                }}
              />
            </div>
            <Grid container>
              <TitleDescriptionComponent
                title={`Encourage \n ${prayer.name}!`}
                typeTitle={"titleBody"}
                textTransform={"capitalize"}
                description={`The simple act of reaching out can be
                life changing. Get in touch with
                ${prayer.name} and let them know
                they are in your prayers`}
                textAlignSubtitle={"center"}
              />
            </Grid>

            <Grid
              container
              alignItems="center"
              style={{
                marginTop: "2vh",
                paddingLeft: "6vh",
                paddingRight: "6vh",
              }}
              spacing={3}
            >
              <Grid item xs={12}>
                <Typography
                  className="fontSourceSansProRegular"
                  variant="h6"
                  style={{
                    color: "#9b9b9b",
                    textAlign: "center",
                    fontWeight: "normal",
                  }}
                >
                  What would you like to send?
                </Typography>
              </Grid>

              <Grid item xs={4} style={{ textAlign: "center" }}>
                <Paper
                  onClick={() => this.sendEncouragement("quick-response")}
                  style={{
                    width: "70px",
                    height: "70px",
                    margin: "0 auto",
                    backgroundImage: `url("/Icon_sendEncouragement.png")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    boxShadow:
                      "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  style={{
                    textAlign: "center",
                    fontWeight: "normal",
                    color: "#9b9b9b",
                    marginTop: "1vh",
                  }}
                  onClick={() => this.sendEncouragement("quick-response")}
                >
                  MESSAGE
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "center" }}>
                <Paper
                  onClick={() => this.sendEncouragement("share-verse")}
                  style={{
                    width: "70px",
                    height: "70px",
                    margin: "0 auto",
                    backgroundImage: `url("/Icon_sendVerse.png")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    boxShadow:
                      "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  style={{
                    textAlign: "center",
                    fontWeight: "normal",
                    color: "#9b9b9b",
                    marginTop: "1vh",
                  }}
                  onClick={() => this.sendEncouragement("share-verse")}
                >
                  VERSE
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "center" }}>
                <Paper
                  onClick={() => this.sendEncouragement("share-photo")}
                  style={{
                    width: "70px",
                    height: "70px",
                    margin: "0 auto",
                    backgroundImage: `url("/Icon_sendPhoto.png")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    boxShadow:
                      "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  style={{
                    textAlign: "center",
                    fontWeight: "normal",
                    color: "#9b9b9b",
                    marginTop: "1vh",
                  }}
                  onClick={() => this.sendEncouragement("share-photo")}
                >
                  PHOTO
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default ViewPersonComponent;
