import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import ButtonComponent from "../shared/components/button";
import Snackbar from "@material-ui/core/Snackbar";
import AuthResources from "../../resources/auth";
import { app } from "../../environments/environment";

const checkAuth = function checkAuth() {
  return AuthResources.checkAuthSession();
};

class ForgotPasswordComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: null,
        redirect_url: `${app}/reset-password`,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendData = this.sendData.bind(this);
  }

  componentDidMount() {
    if (checkAuth().isLoggedIn) {
      location.href = "/";
    }
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    this.setState((prevState) => ({
      user: { ...prevState.user, [target]: value },
    }));
  }

  sendData() {
    const { user } = this.state;
    if (!user.email) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your email",
      });
    } else {
      AuthResources.forgotPassword(user)
        .then((response) => {
          response.json().then((parsedJson) => {
            if (parsedJson.errors && parsedJson.errors.length > 0) {
              this.setState({
                snackbarOpen: true,
                snackbarMessage: parsedJson.errors[0].toUpperCase(),
              });
            } else {
              this.props.history.push(`/login`, {
                message: "Check your email",
              });
            }
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { snackbarOpen, snackbarMessage } = this.state;

    return (
      <div>
        <div
          style={{
            backgroundImage: `url("loginbg-img.jpg")`,
            height: "900px",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <HeaderBarComponent
            showLogo
            showBack
            showBackAction={() => this.props.history.push(`/`)}
            title={"Prayr App"}
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              marginTop: "8vh",
              position: "absolute",
            }}
          >
            <Grid item xs={10}>
              <Typography
                variant="h5"
                className="fontLatoRegular"
                style={{
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                RESET YOUR PASSWORD
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#ffffff",
                  textAlign: "center",
                  marginTop: "2vh",
                  fontSize: "0.9rem",
                  paddingRight: "2vh",
                  paddingLeft: "2vh",
                }}
              >
                {`Misplace your login credentials? No worries, it happens to the
                best of us. Enter your email and we'll send you a quick and easy
                reset link`}
              </Typography>
              <TextField
                label="ENTER YOUR EMAIL"
                style={{ width: "100%", marginTop: "3vh" }}
                name="email"
                id="outlined-start-adornment"
                onChange={this.handleChange}
                sx={{
                  "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled": {
                    display: "none",
                    color: "#ffffff !important",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#ffffff",
                    marginLeft: "1vh",
                    top: "7%",
                    fontSize: "1.5vh",
                  },
                  "& .MuiOutlinedInput-root": {
                    paddingLeft: "1vh",
                  },
                  "& .MuiOutlinedInput-root fieldset": {
                    borderColor: "#ffffff00 !important",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#ffffff",
                    backgroundColor: "#afafaf57",
                    borderRadius: "4vh",
                  },
                }}
              />
            </Grid>

            <ButtonComponent
              name={"SEND RESET LINK"}
              action={this.sendData}
              marginTop={"2vh"}
            />
          </Grid>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default ForgotPasswordComponent;
