import React from "react";
import TextField from "@mui/material/TextField";

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      inputValue,
      inputChange,
      name,
      label,
      textArea,
      borderRadius,
      type,
      width,
    } = this.props;
    return (
      <TextField
        label={label}
        value={inputValue}
        name={name}
        style={{ width: width ? width : "80%", marginTop: "2vh" }}
        onChange={inputChange}
        className="fontLatoRegular"
        multiline={textArea ? true : false}
        maxRows={textArea ? 4 : null}
        InputLabelProps={{ shrink: true }}
        type={type || "input"}
        sx={{
          "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled": {
            display: "none",
            color: "#808080 !important",
          },
          "& .MuiInputLabel-root": {
            color: "#808080",
            position: "absolute",
            top: textArea ? "15%" : "50%",
          },
          "& .MuiOutlinedInput-root fieldset": {
            borderColor: "#ffffff !important",
          },
        }}
        InputProps={{
          style: {
            color: "#808080",
            backgroundColor: "#f0f0f0",
            borderRadius: !borderRadius ? "4vh" : borderRadius,
            height: textArea ? "20vh" : null,
          },
        }}
      />
    );
  }
}

export default InputComponent;
