import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import moment from "moment";
import AlarmIcon from "@mui/icons-material/Alarm";
import LoopIcon from "@mui/icons-material/Loop";
import InputAdornment from "@mui/material/InputAdornment";
import DateRangeIcon from "@mui/icons-material/DateRange";

const reminderRepetition = [
  ["never", "Never"],
  ["daily", "Daily"],
  ["weekly", "Weekly"],
  ["monthly", "Monthly"],
  ["annually", "Annually"],
];

const daysWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const monthsDate = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class ReminderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.openCalendar = this.openCalendar.bind(this);
  }

  openCalendar() {
    this.calendarRef.current.click();
  }

  calculateTextDate(value, text) {
    const { reminderDate } = this.props;
    let datePrint = "";
    switch (value) {
      case "weekly":
        datePrint = "Weekly on " + daysWeek[reminderDate.format("d")];
        break;
      case "monthly":
        datePrint = "Monthly on " + reminderDate.format("DD");
        break;
      case "annually":
        datePrint =
          "Annually on " +
          monthsDate[reminderDate.format("M")] +
          " " +
          reminderDate.format("DD");
        break;
      default:
        datePrint = text;
    }
    return datePrint;
  }

  render() {
    const {
      prayerReminder,
      onChange,
      reminderDate,
      onChangeReminderDate,
      reminderTime,
      onChangeReminderTime,
    } = this.props;
    return (
      <Grid container direction="column" alignItems="center">
        <div>
          <Grid container direction="column" alignItems="center">
            <Typography
              variant="subtitle1"
              style={{
                color: "#000000",
                textAlign: "center",
                marginTop: "2vh",
                fontSize: "0.8rem",
                position: "relative",
              }}
            >
              <AlarmIcon
                style={{ position: "absolute", top: "-0.3vh", left: "-4vh" }}
              />{" "}
              Remind Me On:
            </Typography>
          </Grid>
        </div>

        <div>
          <Grid container direction="column" alignItems="center">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <MobileDatePicker
                label="Date"
                inputFormat="MM/DD/yyyy"
                value={reminderDate}
                minDate={moment(moment(), "MM/DD/yyyy")}
                onChange={onChangeReminderDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"reminder_date"}
                    inputRef={this.calendarRef}
                    sx={{
                      "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                        {
                          display: "none",
                          color: "#ffffff !important",
                        },
                      "& .MuiOutlinedInput-notchedOutline": {
                        display: "none",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000000",
                        position: "absolute",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        borderColor: "#F9F6F2 !important",
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#ffffff",
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4vh",
                        width: "82vw",
                        maxWidth: "260px",
                      },
                      endAdornment: (
                        <InputAdornment position="start">
                          <DateRangeIcon
                            style={{ color: "#ffffff" }}
                            onClick={this.openCalendar}
                          />
                        </InputAdornment>
                      ),
                    }}
                    style={{ marginTop: "2vh" }}
                  />
                )}
              />
              <MobileTimePicker
                label="Time"
                inputFormat="hh:mm A"
                value={reminderTime}
                onChange={onChangeReminderTime}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={"reminder_time"}
                    sx={{
                      "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                        {
                          display: "none",
                          color: "#ffffff !important",
                        },
                      "& .MuiOutlinedInput-notchedOutline": {
                        display: "none",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000000",
                        position: "absolute",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        borderColor: "#F9F6F2 !important",
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#ffffff",
                        backgroundColor: "#c0c0c0",
                        borderRadius: "4vh",
                        width: "82vw",
                        maxWidth: "260px",
                      },
                    }}
                    style={{ marginTop: "2vh" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Typography
              variant="subtitle1"
              style={{
                color: "#000000",
                textAlign: "center",
                marginTop: "2vh",
                fontSize: "0.8rem",
                position: "relative",
              }}
            >
              <LoopIcon
                style={{ position: "absolute", top: "-0.3vh", left: "-4vh" }}
              />{" "}
              Repeat:
            </Typography>
            <TextField
              fullWidth
              select
              label="Repeat"
              style={{ marginTop: "2vh" }}
              name="reminder_repetition"
              value={prayerReminder.reminder_repetition}
              onChange={onChange}
              sx={{
                "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled": {
                  display: "none",
                  color: "#ffffff !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  display: "none",
                },
                "& .MuiInputLabel-root": {
                  color: "#000000",
                  position: "absolute",
                },
                "& .MuiOutlinedInput-root fieldset": {
                  borderColor: "#F9F6F2 !important",
                },
              }}
              InputProps={{
                style: {
                  color: "#ffffff",
                  backgroundColor: "#c0c0c0",
                  borderRadius: "4vh",
                  width: "82vw",
                  maxWidth: "260px",
                },
              }}
            >
              {reminderRepetition.map((g) => (
                <MenuItem key={g[0]} value={g[0]}>
                  {this.calculateTextDate(g[0], g[1])}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default ReminderComponent;
