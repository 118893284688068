import React from "react";
import Grid from "@material-ui/core/Grid";
import TitleDescriptionComponent from "../../../shared/components/title-description";
import ButtonComponent from "../../../shared/components/button";
import SliderImagesComponent from "../../../shared/components/slider-images";
import Typography from "@mui/material/Typography";
import MyPrayersResources from "../../../../resources/my-prayers";
import CategoriesListComponent from "./categories-list";

class ImageCategoriesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      data: [],
      loadData: false,
      selectedCategory: null,
      formSend: false,
      imageUrl: null,
      previewUrl: null,
    };

    this.changeCategory = this.changeCategory.bind(this);
    this.filterCategories = this.filterCategories.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadCategories();
  }

  loadCategories() {
    this.setState({
      isLoading: true,
    });
    MyPrayersResources.getAllPhotos().then((data) => {
      this.setState({
        categories: data,
        isLoading: false,
        selectedCategory: "all",
      });
      this.loadData();
    });
  }

  changeCategory(name) {
    this.setState({
      selectedCategory: name,
    });
    this.loadData();
  }

  loadData() {
    const { categories, selectedCategory } = this.state;
    this.setState({
      data: categories[selectedCategory],
      loadData: false,
    });
  }

  renderCategoriesList() {
    const { categories, selectedCategory } = this.state;
    const categoriesData = Object.keys(categories);
    return (
      <Grid item xs={10} style={{ width: "100vh" }}>
        <CategoriesListComponent
          categories={categoriesData}
          categoriesData={categories}
          showCount={false}
          selected={selectedCategory}
          changeCategory={this.changeCategory}
        />
      </Grid>
    );
  }

  filterCategories() {
    this.setState({
      loadData: true,
      photos: [],
    });
  }

  render() {
    const { actionImage } = this.props;
    const { selectedCategory, data, loadData } = this.state;
    return (
      <div>
        {loadData && (
          <Grid container direction="column" alignItems="center">
            <TitleDescriptionComponent
              title={"Find The Perfect Photo"}
              typeTitle={"titleBody"}
              description={`We understand how it can be difficult to convey what you are thinking
          with just a text, find the perfect image below`}
            />

            {this.renderCategoriesList()}
          </Grid>
        )}

        {!loadData && selectedCategory && (
          <div>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ marginBottom: "2vh" }}
            >
              <TitleDescriptionComponent
                title={"Send a Photo"}
                typeTitle={"titleBody"}
                description={`Images are incredibly powerful, when combined with scripture they become even more so. Select from our curated selection of ever updating encouragement photos or filter to find a specific topic`}
              />

              <Typography
                variant="h6"
                sx={{ mt: "2vh", ml: "3vh", fontSize: "2.8vh" }}
                style={{ color: "#424242" }}
              >
                {selectedCategory.toUpperCase()} PHOTOS
              </Typography>

              <Grid item xs={12}>
                <SliderImagesComponent
                  actionImage={actionImage}
                  data={data}
                  keyUrl={"image_url"}
                  emptyBackground={"#ffffff"}
                />
              </Grid>

              <ButtonComponent
                name={"FILTER PHOTOS"}
                marginBottom={"2vh"}
                action={this.filterCategories}
              />
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default ImageCategoriesComponent;
