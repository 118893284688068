import Grid from "@material-ui/core/Grid";
import React from "react";
import HeaderBarComponent from "../../../shared/header-bar/header-bar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ShareFormComponent from "../shared/share-form";
import MyPrayersResources from "../../../../resources/my-prayers";
import ImagesCategoriesComponent from "../shared/images-categories";
import Snackbar from "@material-ui/core/Snackbar";
import LocalStorageResources from "../../../../resources/local-storage";
import { web } from "../../../../environments/environment";
import MyProfileResources from "../../../../resources/my-profile";

class PrayNowComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      id,
      data: [],
      formSend: false,
      prayer: {},
      dataForm: {
        from: "no-reply@myprayr.com",
        to: null,
        subject: null,
        content: null,
        photo_id: null,
        phone: null,
        message_type: "pray_now",
        prayer_id: null,
        user_settings: null,
      },
      imageUrl: null,
      previewUrl: null,
      snackbarOpen: false,
      snackbarMessage: null,
      submitText: "SEND",
      messageChannel: "email",
      userEmailSettings: null,
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      backUrl: this.props.location?.state?.backUrl
        ? this.props.location.state.backUrl
        : null,
      fromGroup: this.props.location?.state?.fromGroup
        ? this.props.location.state.fromGroup
        : null,
    };
    this.onCloseShareForm = this.onCloseShareForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showFormWithImage = this.showFormWithImage.bind(this);
    this.menuBackAction = this.menuBackAction.bind(this);
    this.showImages = this.showImages.bind(this);
    this.toggleMessageChannel = this.toggleMessageChannel.bind(this);
  }

  componentDidMount() {
    this.loadPrayer();
  }

  toggleMessageChannel(messageChannel) {
    const { dataForm, prayer } = this.state;
    dataForm.from = "no-reply@myprayr.com";
    dataForm.to = prayer.email;
    dataForm.phone = prayer.phone;
    const userData = LocalStorageResources.getObject("authData").data;
    let buttonText = "";
    switch (messageChannel) {
      case "email":
        buttonText = "SEND";
        break;
      case "sms":
        buttonText = "SEND";
        break;
      case "in-app":
        dataForm.from = `${userData.first_name} ${userData.last_name}`;
        (dataForm.to = messageChannel == "in-app" ? prayer.name : prayer.email),
          (buttonText = "SEND");
        break;
      default:
        buttonText = "SEND";
        break;
    }
    this.setState({
      messageChannel: messageChannel,
      submitText: buttonText,
      dataForm: dataForm,
    });
  }

  loadPrayer() {
    const { id, message } = this.state;
    this.setState({
      isLoading: true,
    });
    MyPrayersResources.get(id).then((data) => {
      this.setState((prevState) => ({
        dataForm: {
          ...prevState.dataForm,
          to: data.email,
          content: data.default_message,
        },
        prayer: data,
      }));
      if (message) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: message,
          message: null,
        });
      }
      MyProfileResources.get().then((userEmailSettings) => {
        this.setState((prevState) => ({
          dataForm: {
            ...prevState.dataForm,
            subject: userEmailSettings.default_subject,
            content: userEmailSettings.default_message,
            user_settings: userEmailSettings,
          },
          userEmailSettings,
          isLoading: false,
        }));
      });
      this.toggleMessageChannel(data.message_type);
    });
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    this.setState((prevState) => ({
      dataForm: { ...prevState.dataForm, [target]: value },
    }));
  }

  submitForm(messageChannel = "email") {
    const { dataForm, prayer, fromGroup, previewUrl } = this.state;
    if (messageChannel == "email" || messageChannel == "in-app") {
      if (!dataForm.to) {
        const snackbarMessage = "Enter recipient";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!dataForm.subject) {
          const snackbarMessage = "Enter subject";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          if (!dataForm.content) {
            const snackbarMessage = "Enter content";
            this.setState({
              snackbarOpen: true,
              snackbarMessage,
            });
          } else {
            let sendData = {};
            let payload = {
              subject: dataForm.subject,
              content: dataForm.content,
              photo_id: dataForm.photo_id,
              prayer_id: prayer.id,
              message_type: dataForm.message_type,
            };
            if (messageChannel == "email") {
              (payload.to = dataForm.to), (sendData.email = payload);
              MyPrayersResources.sendMessage(sendData)
                .then((response) => {
                  response.json().then((parsedJson) => {
                    if (parsedJson.errors) {
                      const firstError = `${Object.entries(
                        parsedJson.errors
                      )[0][0].replace("_", " ")} ${
                        Object.entries(parsedJson.errors)[0][1]
                      }`;
                      this.setState({
                        isLoading: false,
                        snackbarOpen: true,
                        snackbarMessage: firstError,
                      });
                    } else {
                      this.props.history.push(
                        `/my-prayers/${prayer.id}/view-person`,
                        {
                          message: parsedJson.message,
                          backUrl: `/my-prayers/${prayer.id}/view-details`,
                          fromGroup,
                        }
                      );
                    }
                  });
                })
                .catch(() => {
                  const snackbarMessage = "No update email to prayer";
                  this.setState({
                    snackbarOpen: true,
                    snackbarMessage,
                  });
                });
            } else if (messageChannel == "in-app") {
              (payload.to_user_id = prayer.user_id),
                (sendData.in_app = payload);
              MyPrayersResources.sendMessageInApp(sendData).then(() => {
                this.props.history.push(`/my-prayers`, {
                  message: "The message has been sent",
                  backUrl: `/my-prayers`,
                  fromGroup,
                });
              });
            }
          }
        }
      }
    } else if (messageChannel == "sms") {
      if (!dataForm.phone) {
        const snackbarMessage = "Enter phone";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!dataForm.content) {
          const snackbarMessage = "Enter content";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          var prayerData = { prayer: { id: prayer.id, phone: dataForm.phone } };
          if (!prayer.group_id) {
            MyPrayersResources.updatePhone(prayerData)
              .then((response) => {
                response.json().then((parsedJson) => {
                  if (parsedJson.errors) {
                    this.setState({
                      isLoading: false,
                      snackbarOpen: true,
                      snackbarMessage: parsedJson.errors,
                    });
                  } else {
                    const options = {
                      backUrl: `/my-prayers/${prayer.id}/view-details`,
                      fromGroup,
                    };
                    if (parsedJson.message)
                      options["message"] = parsedJson.message;
                    const imagePreviewUrl = previewUrl
                      ? `${web}${previewUrl}`
                      : "";
                    location.href = `sms:${dataForm.phone};?&body=${dataForm.content} ${imagePreviewUrl}`;
                    this.props.history.push(
                      `/my-prayers/${prayer.id}/view-person`,
                      options
                    );
                  }
                });
              })
              .catch(() => {
                const snackbarMessage = "No update phone to prayer";
                this.setState({
                  snackbarOpen: true,
                  snackbarMessage,
                });
              });
          } else {
            MyPrayersResources.sendEncouragementSMS(prayer).then(() => {
              const imagePreviewUrl = previewUrl ? `${web}${previewUrl}` : "";
              location.href = `sms:${dataForm.phone};?&body=${dataForm.content} ${imagePreviewUrl}`;
              this.props.history.push(
                !fromGroup
                  ? `/my-prayers`
                  : `/our-prayers/${fromGroup.id}/view-group`,
                {
                  fromGroup,
                }
              );
            });
          }
        }
      }
    }
  }

  showImages() {
    this.setState({
      showImages: true,
      formSend: false,
    });
  }

  showFormWithImage(data) {
    this.setState((prevState) => ({
      dataForm: {
        ...prevState.dataForm,
        photo_id: data.id,
      },
      imageUrl: data.image_url,
      previewUrl: data.preview_url,
      formSend: true,
      showImages: false,
    }));
  }

  onCloseShareForm() {
    this.setState((prevState) => ({
      dataForm: {
        ...prevState.dataForm,
        photo_id: null,
        subject: null,
        to: null,
        content: null,
        image: null,
      },
      imageUrl: null,
      showImages: false,
    }));
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  menuBackAction() {
    this.props.history.push(`/my-prayers`);
  }

  render() {
    const {
      isLoading,
      prayer,
      dataForm,
      imageUrl,
      showImages,
      id,
      snackbarOpen,
      snackbarMessage,
      messageChannel,
      submitText,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={this.menuBackAction}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />
            {!showImages && (
              <div>
                <Grid container direction="column" alignItems="center">
                  <ShareFormComponent
                    dataForm={dataForm}
                    prayer={prayer}
                    imageUrl={imageUrl}
                    showImages={this.showImages}
                    onClose={this.onCloseShareForm}
                    onSubmit={this.submitForm}
                    inputChange={this.handleChange}
                    onToggleMessage={this.toggleMessageChannel}
                    submitText={submitText}
                    messageChannel={messageChannel}
                  />
                </Grid>
              </div>
            )}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
          </div>
        )}
        {!isLoading && showImages && (
          <ImagesCategoriesComponent
            prayerId={id}
            actionImage={this.showFormWithImage}
          />
        )}
      </div>
    );
  }
}

export default PrayNowComponent;
