import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import './fonts/lato/Lato-Black.ttf';
import './fonts/lato/Lato-BlackItalic.ttf';
import './fonts/lato/Lato-Bold.ttf';
import './fonts/lato/Lato-BoldItalic.ttf';
import './fonts/lato/Lato-Italic.ttf';
import './fonts/lato/Lato-Light.ttf';
import './fonts/lato/Lato-LightItalic.ttf';
import './fonts/lato/Lato-Regular.ttf';
import './fonts/lato/Lato-Thin.ttf';
import './fonts/lato/Lato-ThinItalic.ttf';
import './fonts/source-sans-pro/SourceSansPro-Black.ttf';
import './fonts/source-sans-pro/SourceSansPro-BlackItalic.ttf';
import './fonts/source-sans-pro/SourceSansPro-Bold.ttf';
import './fonts/source-sans-pro/SourceSansPro-BoldItalic.ttf';
import './fonts/source-sans-pro/SourceSansPro-ExtraLight.ttf';
import './fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.ttf';
import './fonts/source-sans-pro/SourceSansPro-Italic.ttf';
import './fonts/source-sans-pro/SourceSansPro-Light.ttf';
import './fonts/source-sans-pro/SourceSansPro-LightItalic.ttf';
import './fonts/source-sans-pro/SourceSansPro-Regular.ttf';
import './fonts/source-sans-pro/SourceSansPro-SemiBold.ttf';
import './fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.ttf';
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
