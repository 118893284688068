import React from "react";
import InputComponent from "../../shared/components/input";
import UploadImagePersonalizedComponent from "../../shared/components/upload-image-personalized";
// import UploadImageSquaredComponent from "../../shared/components/upload-image-squared";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../../shared/components/button";
import Button from "@mui/material/Button";

class FormComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      group,
      onChange,
      createOrUpdate,
      title,
      deletePhoto,
      // deleteLogo,
      description,
      descriptionTwo,
      deleteGroup,
      buttonSubmitText,
      deleteText,
      closeForm,
      onChangeGradient,
    } = this.props;
    return (
      <Grid container direction="column" alignItems="center">
        <div>
          <Grid container alignItems="center" direction="column">
            <Grid item xs={9} style={{ marginTop: "2vh" }}>
              {title && (
                <Typography
                  variant="h6"
                  style={{
                    color: "#4b4b4b",
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  {title}
                </Typography>
              )}
              {description && (
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#707070",
                    textAlign: "center",
                    marginTop: "2vh",
                    fontSize: "15px",
                    marginBottom: "1vh",
                    paddingLeft: "2vh",
                    paddingRight: "2vh",
                    lineHeight: "17px",
                  }}
                >
                  {description}
                </Typography>
              )}
              {descriptionTwo && (
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#707070",
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: "2vh",
                    paddingRight: "2vh",
                    lineHeight: "17px",
                  }}
                >
                  {descriptionTwo}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <InputComponent
              inputValue={group.name}
              inputChange={onChange}
              name={"name"}
              label={"Group Name"}
            />
            <InputComponent
              inputValue={group.description}
              inputChange={onChange}
              name={"description"}
              label={"Group Details"}
              textArea
            />
          </Grid>

          <Grid container alignItems="center" direction="column">
            <UploadImagePersonalizedComponent
              avatar={group.photo ? group.photo : null}
              avatarUrl={group.photo_url ? group.photo_url : null}
              backgroundColor={group.background_color}
              avatarChange={onChange}
              gradientChange={onChangeGradient}
              targetName={"photo"}
              deleteAvatar={deletePhoto}
              label={"Group Photo (Optional)"}
            />
          </Grid>

          {/* <Grid container alignItems="center" direction="column">
            <UploadImageSquaredComponent
              avatar={group.logo ? group.logo : null}
              avatarUrl={group.logo_url ? group.logo_url : null}
              avatarChange={onChange}
              targetName={"logo"}
              deleteAvatar={deleteLogo}
              label={"Group Logo (Optional)"}
            />
          </Grid> */}

          <Grid container direction="column" alignItems="center" style={{marginTop: "3vh"}}>
            <ButtonComponent
              name={buttonSubmitText ? buttonSubmitText : "SAVE CHANGES"}
              action={createOrUpdate}
              backgroundColor={"#F07522"}
              color={"#ffffff"}
              borderRadius={"3vh"}
              fontSize={"0.9rem"}
              paddingTop={"1.1vh"}
              marginBottom={"2vh"}
            />

            <Button
              type="submit"
              style={{
                padding: 0,
                color: "#F07522",
                fontSize: "0.9rem",
                marginBottom: "2vh",
              }}
              onClick={closeForm}
            >
              Cancel
            </Button>

            {deleteGroup && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "80px",
                  backgroundColor: "#f7eded",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#ff0000",
                    borderRadius: "3vh",
                    border: "1px #ff0000 solid",
                    width: "218px",
                    minWidth: "218px",
                    height: "35px",
                    minHeight: "35px",
                    fontSize: "0.9rem",
                    textTransform: "capitalize",
                    margin: "20px",
                  }}
                  onClick={deleteGroup}
                  variant="text"
                >
                  {deleteText ? deleteText : "Delete"}
                </Button>
              </div>
            )}
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default FormComponent;
