import React from "react";
import InputComponent from "../../shared/components/input";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../../shared/components/button";
import Button from "@mui/material/Button";
import UploadImagePersonalizedComponent from "../../shared/components/upload-image-personalized";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import ReminderComponent from "../shared/reminder";
import CheckIcon from "@mui/icons-material/Check";

const daysWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const monthsDate = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const StyledToggleButtonEncouragement = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      "&.Mui-selected": {
        color: "#fff",
        backgroundColor: "#f07522 !important",
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  })
);

class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openContactInfo: false,
      openReminder: false,
    };
  }

  openContactInfo(open) {
    this.setState({
      openContactInfo: open,
    });
  }

  openReminder(open) {
    const { onChange, prayerReminder } = this.props;
    this.setState({
      openReminder: open,
    });
    if (open && prayerReminder.reminder_frequency == "never") {
      onChange({
        target: { name: "reminder_frequency", value: "custom" },
      });
    }
  }

  calculateTextDate(value) {
    const { lastSaveReminderDate } = this.props;

    let datePrint = "";
    switch (value) {
      case "weekly":
        datePrint = "Weekly on " + daysWeek[lastSaveReminderDate.format("d")];
        break;
      case "monthly":
        datePrint = "Monthly on " + lastSaveReminderDate.format("DD");
        break;
      case "annually":
        datePrint =
          "Annually on " +
          monthsDate[lastSaveReminderDate.format("M")] +
          " " +
          lastSaveReminderDate.format("DD");
        break;
      case "daily":
        datePrint = "Daily ";
        break;
      default:
        datePrint = lastSaveReminderDate.format("LL");
    }
    return datePrint;
  }

  renderModalContactInfo() {
    const {
      onChange,
      onSelectEncouragementSendMethod,
      closeContactInfo,
      saveContactInfo,
      contactInfo,
    } = this.props;
    const { openContactInfo } = this.state;

    return (
      <Modal
        open={openContactInfo}
        onClose={() => {
          this.openContactInfo(false);
          closeContactInfo();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            width: "307px",
            height: "auto",
            borderRadius: "20px",
            backgroundColor: "#ffffff",
            top: "12vh",
            left: "50%",
            transform: "translate(-50%)",
            position: "absolute",
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Typography
              variant="subtitle1"
              style={{
                color: "#5f5f5f",
                textAlign: "center",
                fontSize: "16px",
                padding: "12px",
              }}
            >
              Messaging Options
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#5f5f5f",
                textAlign: "center",
                fontSize: "15px",
                padding: "5px 20px",
              }}
            >
              {`Enter this person's contact information below and set their preferred method of contact`}
            </Typography>
            <InputComponent
              inputValue={contactInfo.email}
              inputChange={onChange}
              name={"email"}
              label={"Email (optional)"}
            />
            <InputComponent
              inputValue={contactInfo.phone}
              inputChange={onChange}
              name={"phone"}
              label={"Cell Number (optional)"}
            />

            <StyledToggleButtonEncouragement
              color="primary"
              size="small"
              exclusive
              style={{
                borderRadius: "50px",
                width: "80%",
                marginTop: "15px",
                backgroundColor: "white",
                boxShadow:
                  "rgb(0 0 0 / 16%) 0px 2px 5px, rgb(0 0 0 / 1%) 0px 2px 5px",
              }}
              fullWidth
            >
              <ToggleButton
                value="email"
                selected={
                  !contactInfo.message_type ||
                  contactInfo.message_type == "email"
                }
                style={{
                  borderRadius: "50px",
                  border: "0",
                  margin: "5px",
                  padding: "5px",
                }}
                onClick={() => onSelectEncouragementSendMethod("email")}
              >
                EMAIL
              </ToggleButton>
              <ToggleButton
                value="sms"
                selected={contactInfo.message_type == "sms"}
                style={{
                  borderRadius: "50px",
                  border: "0",
                  margin: "5px",
                  padding: "5px",
                }}
                onClick={() => onSelectEncouragementSendMethod("sms")}
              >
                TEXT
              </ToggleButton>
            </StyledToggleButtonEncouragement>
            <ButtonComponent
              name={"SAVE"}
              action={() => {
                this.openContactInfo(false);
                saveContactInfo();
              }}
              backgroundColor={"#F07522"}
              color={"#ffffff"}
              borderRadius={"3vh"}
              fontSize={"0.9rem"}
              paddingTop={"1.1vh"}
              marginTop={"3vh"}
              marginBottom={"2vh"}
            />
          </Grid>
        </Box>
      </Modal>
    );
  }

  renderModalReminder() {
    const {
      prayer,
      reminderDate,
      onChangeReminderDate,
      reminderTime,
      onChangeReminderTime,
      onChange,
      prayerReminder,
      saveReminder,
      deleteReminder,
      closeReminder,
    } = this.props;
    const { openReminder } = this.state;

    return (
      <Modal
        open={openReminder}
        onClose={() => {
          this.openReminder(false);
          closeReminder();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          style={{
            width: "307px",
            height: "auto",
            borderRadius: "20px",
            backgroundColor: "#ffffff",
            top: "12vh",
            left: "50%",
            transform: "translate(-50%)",
            position: "absolute",
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Typography
              variant="subtitle1"
              style={{
                color: "#5f5f5f",
                textAlign: "center",
                fontSize: "16px",
                padding: "12px",
              }}
            >
              Reminder to Pray Options
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#5f5f5f",
                textAlign: "center",
                fontSize: "15px",
                padding: "5px 20px",
              }}
            >
              Set one reminder or a repeating reminder below. You can snooze or
              delete them at any time.
            </Typography>

            <ReminderComponent
              prayer={prayer}
              reminderDate={reminderDate}
              onChangeReminderDate={onChangeReminderDate}
              reminderTime={reminderTime}
              onChangeReminderTime={onChangeReminderTime}
              onChange={onChange}
              prayerReminder={prayerReminder}
            />

            <ButtonComponent
              name={"SAVE"}
              action={() => {
                saveReminder();
                this.openReminder(false);
              }}
              backgroundColor={"#F07522"}
              color={"#ffffff"}
              borderRadius={"3vh"}
              fontSize={"0.9rem"}
              paddingTop={"1.1vh"}
              marginTop={"3vh"}
            />
            <Button
              type="submit"
              style={{
                padding: 0,
                color: "#F07522",
                fontSize: "0.9rem",
                marginTop: "2vh",
                marginBottom: "2vh",
              }}
              onClick={() => {
                deleteReminder();
                this.openReminder(false);
              }}
            >
              TURN OFF PRAYER NOTIFICATIONS
            </Button>
          </Grid>
        </Box>
      </Modal>
    );
  }

  renderAutomaticMessaging() {
    const { prayer } = this.props;
    return (
      <div
        style={{
          width: "80%",
          borderRadius: "2vh",
          textAlign: "center",
          border: "2px #e1e1e1 solid",
          height: "190px",
          transition: "background 0.3s ease-in-out",
          color: "#8b8b8b",
          margin: "auto",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "40px",
            backgroundColor: "#edf1f7",
            borderTopLeftRadius: "2vh",
            borderTopRightRadius: "2vh",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: "#5f5f5f",
              textAlign: "center",
              fontSize: "16px",
              padding: "8px",
            }}
          >
            Set Messaging Options
          </Typography>
        </div>

        {!prayer.email && !prayer.phone && (
          <Typography
            variant="subtitle1"
            style={{
              color: "#5f5f5f",
              textAlign: "center",
              fontSize: "15px",
              padding: "9px 12px",
            }}
          >
            {`Enter this person's contact information and set their preferred method of contact`}
          </Typography>
        )}

        {(prayer.email || prayer.phone) && (
          <Typography
            variant="subtitle1"
            style={{
              color: "#5f5f5f",
              textAlign: "center",
              fontSize: "15px",
              padding: "12px 30px",
            }}
          >
            Preferred method for receiving messages is{" "}
            {prayer.message_type == "email" ? "email:" : "phone number:"}{" "}
            <span style={{ color: "#f07522", display: "block" }}>
              {prayer.message_type == "email" ? prayer.email : prayer.phone}
            </span>
          </Typography>
        )}

        <ButtonComponent
          name={
            (prayer.email && prayer.message_type == "email") ||
            (prayer.phone && prayer.message_type == "sms")
              ? "CHANGE CONTACT INFO"
              : "ADD CONTACT INFO"
          }
          action={() => this.openContactInfo(true)}
          backgroundColor={"#F07522"}
          color={"#ffffff"}
          borderRadius={"3vh"}
          fontSize={"0.9rem"}
          paddingTop={"1.1vh"}
        />
      </div>
    );
  }

  renderSetReminders() {
    const { prayer, reminderTime } = this.props;
    return (
      <div
        style={{
          width: "80%",
          borderRadius: "2vh",
          textAlign: "center",
          border: "2px #e1e1e1 solid",
          height: "140px",
          transition: "background 0.3s ease-in-out",
          color: "#8b8b8b",
          margin: "2vh auto",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "40px",
            backgroundColor: "#edf1f7",
            borderTopLeftRadius: "2vh",
            borderTopRightRadius: "2vh",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: "#5f5f5f",
              textAlign: "center",
              fontSize: "16px",
              padding: "8px",
            }}
          >
            Set a Reminder to Pray
          </Typography>
          {prayer.reminder_frequency != "custom" && (
            <>
              <Paper
                style={{
                  borderRadius: "50px",
                  width: "60px",
                  height: "30px",
                  marginTop: "15px",
                  backgroundColor: "#a3a3a3",
                  boxShadow:
                    "rgb(0 0 0 / 16%) 0px 2px 5px, rgb(0 0 0 / 1%) 0px 2px 5px",
                  position: "relative",
                  margin: "15px auto",
                }}
                onClick={() => this.openReminder(true)}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    border: "0",
                    height: "20px",
                    width: "26px",
                    position: "absolute",
                    top: "3px",
                    right: "5px",
                    color: "#c5c5c5",
                  }}
                >
                  <CloseIcon />
                </div>
                <div
                  style={{
                    borderRadius: "10px",
                    border: "0",
                    height: "20px",
                    width: "21px",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                  }}
                ></div>
              </Paper>
              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  padding: "0px 30px",
                  color: "#5f5f5f",
                }}
              >
                {`No Reminder`}
              </Typography>
            </>
          )}
          {prayer.reminder_frequency == "custom" && (
            <>
              <Paper
                style={{
                  borderRadius: "50px",
                  width: "60px",
                  height: "30px",
                  marginTop: "15px",
                  backgroundColor: "#f07522",
                  boxShadow:
                    "rgb(0 0 0 / 16%) 0px 2px 5px, rgb(0 0 0 / 1%) 0px 2px 5px",
                  position: "relative",
                  margin: "15px auto",
                }}
                onClick={() => this.openReminder(true)}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    border: "0",
                    height: "20px",
                    width: "21px",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                ></div>
                <div
                  style={{
                    borderRadius: "10px",
                    border: "0",
                    height: "20px",
                    width: "26px",
                    position: "absolute",
                    top: "5px",
                    left: "5px",
                    color: "#c5c5c5",
                  }}
                >
                  <CheckIcon />
                </div>
              </Paper>
              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "center",
                  fontSize: "15px",
                  padding: "0px 30px",
                  color: "#f07522",
                }}
              >
                {`${this.calculateTextDate(
                  prayer.reminder_repetition
                )} at ${reminderTime.format("hh:mm A")}`}
              </Typography>
            </>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      prayer,
      onChange,
      encouragementPreferences,
      reminder,
      createOrUpdate,
      title,
      description,
      deleteAvatar,
      hiddenFields,
      closeForm,
      onChangeGradient,
    } = this.props;
    return (
      <Grid container direction="column" alignItems="center">
        {!encouragementPreferences && !reminder && (
          <div style={{ width: "100%" }}>
            <Grid container alignItems="center" direction="column">
              <Grid item xs={9} style={{ marginTop: "2vh" }}>
                {title && (
                  <Typography
                    variant="h6"
                    style={{
                      color: "#4b4b4b",
                      textAlign: "center",
                      fontSize: "1.5rem",
                    }}
                  >
                    {title}
                  </Typography>
                )}
                {description && (
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#707070",
                      textAlign: "center",
                      marginTop: "2vh",
                      fontSize: "15px",
                      marginBottom: "1vh",
                      paddingLeft: "2vh",
                      paddingRight: "2vh",
                      lineHeight: "17px",
                    }}
                  >
                    {description}
                  </Typography>
                )}
              </Grid>

              <Grid container direction="column" alignItems="center">
                {!hiddenFields && (
                  <InputComponent
                    inputValue={prayer.name}
                    inputChange={onChange}
                    name={"name"}
                    label={"Name"}
                  />
                )}
                <InputComponent
                  inputValue={prayer.description}
                  inputChange={onChange}
                  name={"description"}
                  label={"Prayer Request Details"}
                  textArea
                />
              </Grid>

              {!hiddenFields && (
                <>
                  <Grid container direction="column" alignItems="center">
                    <UploadImagePersonalizedComponent
                      avatar={prayer.avatar ? prayer.avatar : null}
                      avatarUrl={prayer.avatar_url ? prayer.avatar_url : null}
                      backgroundColor={prayer.background_color}
                      avatarChange={onChange}
                      gradientChange={onChangeGradient}
                      targetName={"avatar"}
                      deleteAvatar={deleteAvatar}
                      label={
                        "Images create powerful connections within prayer. Select a photo to represent this prayer recipient."
                      }
                    />
                  </Grid>
                  {this.renderAutomaticMessaging()}
                  {this.renderSetReminders()}
                </>
              )}
            </Grid>

            {prayer.group_id && (
              <Grid container direction="column" alignItems="center">
                <Typography
                  style={{
                    color: "#808080",
                    fontSize: "0.9rem",
                    marginBottom: "2vh",
                    padding: "0 8vh 0 8vh",
                    textAlign: "center",
                  }}
                >
                  *This prayer request is public for all members of the group.
                </Typography>
              </Grid>
            )}

            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                position: prayer.group_id && "absolute",
                bottom: prayer.group_id && "3vh",
              }}
            >
              <ButtonComponent
                name={"SAVE CHANGES"}
                action={createOrUpdate}
                backgroundColor={"#F07522"}
                color={"#ffffff"}
                borderRadius={"3vh"}
                fontSize={"0.9rem"}
                paddingTop={"1.1vh"}
                marginBottom={"2vh"}
              />

              <Button
                type="submit"
                style={{
                  padding: 0,
                  color: "#F07522",
                  fontSize: "0.9rem",
                  marginBottom: "2vh",
                }}
                onClick={closeForm}
              >
                Cancel
              </Button>
            </Grid>
          </div>
        )}
        {!hiddenFields && this.renderModalContactInfo()}
        {!hiddenFields && this.renderModalReminder()}
      </Grid>
    );
  }
}

export default FormComponent;
