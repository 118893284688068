import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import { withStyles, alpha } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import HomeIcon from "@material-ui/icons/Home";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import React from "react";
import "./header-bar.css";
import GroupIcon from "@mui/icons-material/Group";
import PanToolIcon from "@mui/icons-material/PanTool";
import PersonIcon from "@mui/icons-material/Person";
import AuthResources from "../../../resources/auth";
import Badge from "@mui/material/Badge";
import NotificationsComponent from "../components/notifications";
import NotificationsResources from "../../../resources/notifications";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  grow: {
    flexGrow: 1,
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  search: {
    color: "#ffffff",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1) * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1) * 3,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(1) * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    color: "#ffffff !important",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1) * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  menuLogo: {
    width: "220px",
  },
  offlineBar: {
    background: "#000000",
  },
});

const routes = [
  { name: "Home", url: "/home", icon: <HomeIcon /> },
  { name: "My Prayers", url: "/my-prayers", icon: <GroupIcon /> },
  { name: "Our Prayers", url: "/our-prayers", icon: <GroupIcon /> },
  { name: "Faith Builder", url: "/faith-builder", icon: <PanToolIcon /> },
  { name: "Answered Prayers", url: "/answered-prayers", icon: <GroupIcon /> },
  { name: "My Settings", url: "/my-profile", icon: <PersonIcon /> },
  { name: "Feedback", url: "/feedback", icon: <PersonIcon /> },
];

class HeaderBarComponents extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: false,
      openMenu: false,
      openNotifications: false,
      notifications: [],
    };

    this.onCloseNotifications = this.onCloseNotifications.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
  }

  loadDrawerRoutes() {
    return routes.map(({ name, url }) => (
      <ListItem button key={`${name}`} component="a" href={`${url}`}>
        <ListItemText primary={name} style={{ paddingLeft: "15px" }} />
      </ListItem>
    ));
  }

  componentDidMount() {
    if (
      AuthResources.checkAuthSession().isLoggedIn &&
      this.props.showNotifications
    ) {
      this.getNotifications();
    }
  }

  getNotifications() {
    NotificationsResources.getAll().then((notifications) => {
      this.setState({ notifications });
    });
  }

  handleChange = (event) => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = () => {
    this.setState({ drawerOpen: true, openMenu: true });
  };

  handleNotifications = () => {
    const { showNotificationsAction } = this.props;
    if (showNotificationsAction) {
      showNotificationsAction(true);
    }
    this.setState({ openNotifications: true });
  };

  onCloseNotifications = () => {
    const { showNotificationsAction } = this.props;
    if (showNotificationsAction) {
      showNotificationsAction(false);
    }
    this.setState({ drawerOpen: false, openNotifications: false });
    this.getNotifications();
  };

  render() {
    const {
      showLogo,
      showMenu,
      showBack,
      menuTransparent,
      showBackAction,
      showNotifications,
      homePage,
    } = this.props;
    const { openMenu, openNotifications, notifications } = this.state;
    return (
      <div className={styles.root}>
        <AppBar
          position="static"
          style={{
            ...(menuTransparent && {
              boxShadow:
                "0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%)",
            }),
            ...(!menuTransparent && {
              background:
                "linear-gradient(180deg, rgba(255,178,122,1) 0%, rgba(245,138,66,1) 46%, rgba(241,124,44,1) 92%)",
              boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            }),
          }}
        >
          <Toolbar
            style={{
              marginTop: homePage ? "2vh" : null,
              minHeight: "43px",
              maxHeight: "43px",
            }}
          >
            {showBack && (
              <Paper
                onClick={
                  showBackAction
                    ? showBackAction
                    : this.context.router.history.goBack
                }
                className={styles.menuButton}
                style={{
                  width: "17.7px",
                  height: "20.7px",
                  marginLeft: "8px",
                  backgroundImage: `url("/header-bar/Icon_Back.png")`,
                  backgroundSize: "cover",
                  backgroundColor: "#ffffff00",
                  boxShadow:
                    "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
                }}
              />
            )}
            <div style={{ flex: 3, textAlign: "center" }}>
              {showLogo && (
                <Paper
                  style={{
                    width: !homePage ? "20px" : "15vh",
                    height: !homePage ? "31px" : "2.8vh",
                    position: "absolute",
                    marginLeft: !homePage ? "36vw" : "30vw",
                    margin: !homePage ? "-15px auto" : "-12px auto",
                    left: 0,
                    right: 0,
                    backgroundImage: !homePage
                      ? `url("/header-bar/Icon_PrayerHeader.png")`
                      : `url("/prayr_white.png")`,
                    backgroundSize: "cover",
                    backgroundColor: "#ffffff00",
                    boxShadow:
                      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
                  }}
                  onClick={() => (location.href = "/home")}
                />
              )}
            </div>
            {showNotifications && (
              <Badge
                onClick={this.handleNotifications}
                className={styles.menuButton}
                style={{
                  color: "#ffffff",
                  marginRight: homePage ? "-18px" : null,
                }}
                edge="start"
                sx={{
                  "& .MuiBadge-badge": {
                    top: "3px",
                    left: "3px",
                    backgroundColor: "#F07522",
                  },
                }}
                color="primary"
                showZero={false}
                badgeContent={notifications.length}
              >
                <Paper
                  style={{
                    width: "25px",
                    height: "28.43px",
                    backgroundImage: `url("/header-bar/Icon_Notifications.png")`,
                    backgroundSize: "cover",
                    backgroundColor: "#ffffff00",
                    boxShadow:
                      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
                  }}
                />
              </Badge>
            )}
            {showMenu && (
              <Paper
                style={{
                  width: "25px",
                  height: "20px",
                  marginLeft: "38px",
                  marginRight: "8px",
                  backgroundImage: `url("/header-bar/Icon_Menu.png")`,
                  backgroundSize: "cover",
                  backgroundColor: "#ffffff00",
                  boxShadow:
                    "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
                }}
                onClick={this.handleMenu}
              />
            )}
          </Toolbar>
        </AppBar>
        {openNotifications && (
          <NotificationsComponent
            onClose={this.onCloseNotifications}
            notifications={notifications}
            refreshNotifications={() => this.getNotifications()}
          />
        )}
        <Drawer
          open={this.state.drawerOpen}
          anchor={"right"}
          className={"drawerMenu"}
          onClose={() =>
            this.setState({
              drawerOpen: false,
              openMenu: false,
            })
          }
          PaperProps={{
            className: "drawerContentMenu",
          }}
        >
          {openMenu && (
            <div>
              <Box style={{ width: "100%", textAlign: "center" }}>
                <img
                  className={this.props.classes.menuLogo}
                  src="/MainMenu_Header.png"
                  style={{ width: "100%" }}
                  alt=""
                />
              </Box>
              <Box role="presentation">
                {this.loadDrawerRoutes()}
                <ListItem
                  button
                  key={`Logout`}
                  component="a"
                  onClick={() => {
                    AuthResources.logOut();
                    window.location.reload();
                  }}
                >
                  <ListItemText
                    style={{ paddingLeft: "15px" }}
                    primary={"Log Out"}
                  />
                </ListItem>
              </Box>
              <Box
                style={{
                  width: "100%",
                  height: "165px",
                  textAlign: "center",
                  bottom: 0,
                  position: "absolute",
                  background:
                    "linear-gradient(180deg, rgba(69,69,69,1) 25%, rgba(0,0,0,1) 95%)",
                }}
              >
                <div style={{ width: "245px" }}>
                  <Paper
                    sx={{
                      boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                    }}
                    style={{
                      backgroundColor: "#fff0",
                      backgroundImage: `url("/favicon-96x96.png")`,
                      backgroundSize: "cover",
                      zIndex: 2,
                      width: "50px",
                      height: "50px",
                      right: 0,
                      left: 0,
                      margin: "8px auto",
                    }}
                  />
                  <Typography
                    style={{
                      color: "#a5a5a5",
                      textAlign: "center",
                      fontSize: "14px",
                      marginBottom: "5px",
                    }}
                  >
                    Install the PRAYR™ app on your device Home Screen.
                  </Typography>
                  <a
                    style={{
                      color: "#ffffff",
                      textAlign: "center",
                      fontSize: "16px",
                      textDecoration: "none",
                    }}
                    href="/PRAYR_InstallGuide.pdf"
                    target="_blank"
                  >
                    VIEW INSTRUCTIONS
                  </a>
                </div>
              </Box>
            </div>
          )}
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(HeaderBarComponents);
