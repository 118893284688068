import { api } from "../environments/environment";
import HttpService from "./http";
import ResourcesUtils from "./utils";
import moment from "moment";

export default {
  async getHomepageContent() {
    try {
        const headers = await ResourcesUtils.buildHeaders();
        const response = await HttpService.get(`${api}/section_contents/get_homepage_content`, {
          headers,
          params: {date: moment().format("DD/MM/yyyy")},
        });
        return response.data;
      } catch (error) {
        return error;
      }
  },
};
