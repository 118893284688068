import { api } from "../environments/environment";
import HttpService from "./http";
import ResourcesUtils from "./utils";

export default {
  async getAll(defaultSort = true) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      let data;
      const response = await HttpService.get(`${api}/faith_entries`, {
        headers,
      });
      data = response.data.map((faith) => ({
        ...faith,
      }));
      if (defaultSort){
        data = data.sort((a, b) => (a.id < b.id ? 1 : -1));
      }

      return data;
    } catch (error) {
      return error;
    }
  },

  async create(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/faith_entries`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async update(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/faith_entries/${data.faith_entry.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async get(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/faith_entries/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async delete(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.delete(`${api}/faith_entries/${id}`, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
};
