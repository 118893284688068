import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import TitleDescriptionComponent from "../../shared/components/title-description";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import { Paper } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import NotificationsResources from "../../../resources/notifications";
import CircularProgress from "@mui/material/CircularProgress";

class NotificationsDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      id,
      notification: {
        entity_id: null,
        entity_type: null,
        scheduled_date: null,
        scheduled_time: null,
        subject: null,
        content: null,
        sent_by_name: null,
        photo_url: null,
      },
    };
    this.deleteNotification = this.deleteNotification.bind(this);
  }

  componentDidMount() {
    this.loadNotification();
  }

  deleteNotification(id) {
    this.setState({
      isLoading: true,
    });
    NotificationsResources.delete(id).then(() => {
      this.setState({ isLoading: false });
      this.props.history.push(`/`);
    });
  }

  loadNotification() {
    const { id } = this.state;
    this.setState({
      isLoading: true,
    });

    NotificationsResources.get(id).then((notification) => {
      this.setState({ notification, isLoading: false });
    });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { snackbarOpen, snackbarMessage, isLoading, notification, id } =
      this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <Grid container direction="column" alignItems="center">
              <Grid container alignItems="center" direction="column">
                <TitleDescriptionComponent title={"Encouragement"} />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: "#ffffff" }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ marginTop: "5vh", marginBottom: "1vh", width: "80%" }}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    From:
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {notification.sent_by_name}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    At:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {moment.utc(notification.scheduled_at).local().format(
                      "MMM. DD, YYYY"
                    )} &nbsp;&nbsp;
                    {moment.utc(notification.scheduled_at).local().format("hh:mm A")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ marginBottom: "1vh", width: "80%" }}
              >
                <Grid item xs={12}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {notification.subject}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {notification.content}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {notification.photo_url && (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: "2vh" }}
              >
                <Paper
                  alt=""
                  style={{
                    width: "63vw",
                    height: "50vh",
                    maxWidth: "350px",
                    backgroundImage: `url(` + notification.photo_url + `)`,
                    backgroundSize: "cover",
                    borderRadius: "3vh",
                    boxShadow:
                      "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 44%) 0px 3px 15px",
                  }}
                />
              </Grid>
            )}

            <Grid container direction="column" alignItems="center">
              <Button
                type="submit"
                variant="text"
                style={{
                  marginBottom: "2vh",
                  color: "#F07522",
                  width: "10vh",
                  height: "6vh",
                  fontSize: "0.9rem",
                  textDecoration: "underline",
                }}
                onClick={() => this.deleteNotification(id)}
              >
                Delete
              </Button>
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default NotificationsDetailsComponent;
