/* eslint-disable react/no-unescaped-entities */
import Grid from "@material-ui/core/Grid";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SliderImagesComponent from "../shared/components/slider-images";
import PhotosResources from "../../resources/photos";
import SectionContentResources from "../../resources/section-content";
import DownloadWallpaperComponent from "../shared/components/download-wallpaper";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

class HomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPhotos: [],
      downloadWallpaper: false,
      selectedWallpaper: null,
      showNotifications: false,
      headerContent: null,
      featureContent: null,
    };
    this.showDownloadWallpaper = this.showDownloadWallpaper.bind(this);
    this.hideDownloadWallpaper = this.hideDownloadWallpaper.bind(this);
    this.showNotifications = this.showNotifications.bind(this);
  }

  componentDidMount() {
    this.loadHomepageResources();
  }

  showDownloadWallpaper(image) {
    this.setState({
      downloadWallpaper: true,
      selectedWallpaper: image,
    });
  }

  showNotifications(status) {
    this.setState({
      showNotifications: status,
    });
  }

  hideDownloadWallpaper() {
    this.setState({
      downloadWallpaper: false,
      selectedWallpaper: null,
    });
  }

  loadHomepageResources() {
    this.setState({
      isLoading: true,
    });
    SectionContentResources.getHomepageContent().then((data) => {
      this.setState({
        headerContent: data.header,
        featureContent: data.feature,
      });
      this.loadSliderImages();
    });
  }

  loadSliderImages() {
    PhotosResources.getAllLockScreens().then((data) => {
      this.setState({
        loadPhotos: data,
        isLoading: false,
      });
    });
  }

  render() {
    const {
      loadPhotos,
      showNotifications,
      headerContent,
      featureContent,
      isLoading,
    } = this.state;
    const { downloadWallpaper, selectedWallpaper } = this.state;
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && downloadWallpaper && (
          <div style={{ height: "100vh" }}>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={this.hideDownloadWallpaper}
              showNotifications
              showMenu
              title={"Prayr Apps"}
            />
            <DownloadWallpaperComponent image={selectedWallpaper} />
          </div>
        )}
        {!isLoading && !downloadWallpaper && (
          <div>
            <div style={{ height: "75vh", position: "relative" }}>
              <div style={{ zIndex: "2", width: "100%", position: "absolute" }}>
                <HeaderBarComponent
                  showLogo
                  showNotifications
                  showNotificationsAction={this.showNotifications}
                  showBack={showNotifications}
                  showBackAction={() => (location.href = "/")}
                  menuTransparent={!showNotifications}
                  showMenu
                  homePage={!showNotifications}
                  title={"Prayr App"}
                />
              </div>

              <div
                style={{
                  width: "100vw",
                  maxWidth: "500px",
                  position: "absolute",
                }}
              >
                {headerContent && (
                  <div id="headerContent">
                    <p
                      className="fontLatoRegular"
                      style={{
                        color: "#ffffff",
                        fontSize: "3.2vh",
                        textAlign: "left",
                        position: "absolute",
                        zIndex: !showNotifications ? 2 : null,
                        padding: "0 2vh",
                        bottom: "6vh",
                        width: "100%",
                        maxWidth: "350px",
                      }}
                    >
                      {headerContent.title}
                    </p>
                    <img
                      key={"imgHeaderContent"}
                      alt=""
                      src={headerContent.image_url}
                      style={{
                        width: "100%",
                        height: "75vh",
                        position: "relative",
                        boxShadow:
                          "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 44%) 0px 3px 15px",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            {!showNotifications && (
              <>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ top: "-5vh", position: "relative", width: "100%" }}
                >
                  <Grid item xs={12} style={{ width: "100%" }}>
                    <Paper
                      sx={{ mt: "1vh" }}
                      style={{
                        width: "90%",
                        margin: "auto",
                        height: "18vh",
                        zIndex: "2",
                        backgroundImage: 'url("our-prayers.jpg")',
                        position: "relative",
                        backgroundSize: "cover",
                        borderRadius: "1.1vh",
                      }}
                      onClick={() => {
                        this.props.history.push(`/our-prayers`);
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="fontLatoRegular"
                        style={{
                          position: "absolute",
                          marginLeft: "1vh",
                          bottom: "2vh",
                          marginTop: "0.8vh",
                          left: "2vh",
                          zIndex: "1",
                          fontSize: "3.1vh",
                          color: "#ffffff",
                        }}
                      >
                        <div>Our Prayers</div>
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          position: "absolute",
                          marginLeft: "1vh",
                          bottom: "1vh",
                          marginTop: "0.8vh",
                          left: "2vh",
                          zIndex: "1",
                          fontSize: "1.2vh",
                          color: "#ffffff",
                        }}
                      >
                        <div>GROUPS YOU LEAD OR ARE A MEMBER OF</div>
                      </Typography>
                    </Paper>
                    <Paper
                      sx={{ mt: "4vh" }}
                      style={{
                        width: "90%",
                        margin: "35px auto",
                        height: "18vh",
                        zIndex: "2",
                        backgroundImage: 'url("my-prayers.jpg")',
                        position: "relative",
                        backgroundSize: "cover",
                        borderRadius: "1.1vh",
                      }}
                      onClick={() => {
                        this.props.history.push(`/my-prayers`);
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="fontLatoRegular"
                        style={{
                          position: "absolute",
                          marginLeft: "1vh",
                          bottom: "2vh",
                          marginTop: "0.8vh",
                          left: "2vh",
                          zIndex: "1",
                          fontSize: "3.1vh",
                          color: "#ffffff",
                        }}
                      >
                        <div>My Prayers</div>
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          position: "absolute",
                          marginLeft: "1vh",
                          bottom: "1vh",
                          marginTop: "0.8vh",
                          left: "2vh",
                          zIndex: "1",
                          fontSize: "1.1vh",
                          color: "#ffffff",
                        }}
                      >
                        <div>YOUR DAILY INDIVIDUAL PRAYERS</div>
                      </Typography>
                    </Paper>
                    <Paper
                      sx={{ mt: "4vh" }}
                      style={{
                        width: "90%",
                        margin: "35px auto",
                        height: "18vh",
                        zIndex: "2",
                        backgroundImage: 'url("faith-builder.jpg")',
                        position: "relative",
                        backgroundSize: "cover",
                        borderRadius: "1.1vh",
                      }}
                      onClick={() => {
                        this.props.history.push(`/faith-builder`);
                      }}
                    >
                      <Typography
                        variant="h6"
                        className="fontLatoRegular"
                        style={{
                          position: "absolute",
                          marginLeft: "1vh",
                          bottom: "2vh",
                          marginTop: "0.8vh",
                          left: "2vh",
                          zIndex: "1",
                          fontSize: "3.1vh",
                          color: "#ffffff",
                        }}
                      >
                        <div>Faith Builder</div>
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{
                          position: "absolute",
                          marginLeft: "1vh",
                          bottom: "1vh",
                          marginTop: "0.8vh",
                          left: "2vh",
                          zIndex: "1",
                          fontSize: "1.2vh",
                          color: "#ffffff",
                        }}
                      >
                        <div>EXPLORE YOUR HISTORY</div>
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>

                {featureContent && (
                  <>
                    <Grid container direction="column" alignItems="center">
                      <div style={{ position: "relative", width: "100%" }}>
                        <img
                          key={"imgFeatureContent"}
                          alt=""
                          src={featureContent.image_url}
                          style={{
                            width: "100%",
                            height: "41vh",
                            backgroundSize: "cover",
                            borderRadius: "0vh",
                            position: "relative",
                          }}
                        ></img>
                        <Typography
                          variant="h6"
                          className="fontLatoRegular"
                          style={{
                            position: "absolute",
                            marginLeft: "2vh",
                            bottom: "7vh",
                            left: "2vh",
                            zIndex: "1",
                            fontSize: "3vh",
                            color: "#ffffff",
                            lineHeight: "normal",
                          }}
                        >
                          <div>{featureContent.title}</div>
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{
                            position: "absolute",
                            marginLeft: "2vh",
                            bottom: "4vh",
                            marginTop: "0.8vh",
                            left: "2vh",
                            zIndex: "1",
                            fontSize: "1.1vh",
                            color: "#ffffff",
                          }}
                        >
                          <div>{featureContent.subtitle}</div>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid container direction="column">
                      <Typography
                        variant="body2"
                        style={{
                          marginTop: "3vh",
                          marginBottom: "1vh",
                          color: "#b6b6b6",
                          fontSize: "1.8vh",
                          textAlign: "justify",
                          padding: "0 1vh 0",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: featureContent
                            ? featureContent.content.body
                            : `This paragraph will give information about this week's featured organization / ministry / individual. This bio block will be able to be updated to include <a href="/" style={{ color: "#f18232", textDecoration: "none" }} >hyperlinks</a> as well as normal text. This should be kept to about this length or else it gets a bit bulky`,
                        }}
                      ></Typography>
                    </Grid>
                  </>
                )}

                <Grid container direction="column" alignItems="flex-start">
                  <Typography
                    className="fontLatoRegular"
                    variant="h6"
                    sx={{ mt: "2vh", ml: "3vh", fontSize: "2.8vh" }}
                    style={{ color: "#424242" }}
                  >
                    Custom Lock Screens
                  </Typography>
                </Grid>

                <SliderImagesComponent
                  data={loadPhotos}
                  keyUrl={"image_url"}
                  actionImage={this.showDownloadWallpaper}
                  showPhotoCredit={false}
                  emptyBackground={"#f5f5f5"}
                />

                <Paper
                  style={{
                    width: "100%",
                    height: "24vh",
                    marginTop: "5vh",
                    backgroundImage: 'url("footer.png")',
                    backgroundSize: "cover",
                    borderRadius: "0vh",
                  }}
                ></Paper>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default HomeComponent;
