import Pusher from "pusher-js";
import { api } from "../environments/environment";
import ResourcesUtils from "../resources/utils";

export default {
  async subscribeChannelMessages(
    newMessage,
    updateMessage,
    newReactionMessage,
    pusherChannel
  ) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      var pusher = new Pusher("1584941ff7072f08a677", {
        cluster: "us2",
        authEndpoint: `${api}/pusher/auth`,
        auth: { headers },
      });
      var channel = pusher.subscribe(`presence-${pusherChannel}`);
      channel.bind("new_message", (data) => {
        newMessage(data);
      });
      channel.bind("update_message", (data) => {
        updateMessage(data);
      });
      channel.bind("change_reactions", (data) => {
        newReactionMessage(data);
      });
    } catch (err) {
      console.error(err);
    }
  },
  async subscribeAllChannelMessages(handleAction, groups) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      var pusher = new Pusher("1584941ff7072f08a677", {
        cluster: "us2",
        authEndpoint: `${api}/pusher/auth`,
        auth: { headers },
      });
      groups.map((group) => {
        var channel = pusher.subscribe(`presence-${group.pusher_channel}`);
        channel.bind("new_message", (data) => {
          handleAction(data, group);
        });
      });
    } catch (err) {
      console.error(err);
    }
  },
};
