import DbResources from "./db";

export default {
  async buildHeaders() {
    const db = DbResources.getInstance().settings;
    const {
      settingValue: { token, client, uid },
    } = await db.where("settingKey").equalsIgnoreCase("auth").first();
    return {
      "access-token": token,
      client,
      uid,
    };
  },
};
