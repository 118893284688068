import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";

class ButtonComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      name,
      action,
      width,
      right,
      marginTop,
      marginBottom,
      backgroundColor,
      color,
      borderRadius,
      height,
      fontSize,
      paddingTop,
      textTransform,
    } = this.props;

    return (
      <Grid container direction="column" alignItems="center">
        <Button
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#F07522",
            color: color ? color : "#ffffff",
            borderRadius: borderRadius ? borderRadius : "3vh",
            width: width ? width : "218px",
            minWidth: width ? width : "218px",
            height: height ? height : "35px",
            minHeight: "35px",
            fontSize: fontSize ? fontSize : "0.9rem",
            paddingTop: paddingTop ? paddingTop : "1.1vh",
            textTransform: textTransform ? textTransform : "capitalize",
            right: right ? right : null,
            marginTop: marginTop ? marginTop : null,
            marginBottom: marginBottom ? marginBottom : "null",
          }}
          onClick={action}
          size="large"
          variant="contained"
        >
          {name}
        </Button>
      </Grid>
    );
  }
}

export default ButtonComponent;
