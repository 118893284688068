import { api } from "../environments/environment";
import HttpService from "./http";
import ResourcesUtils from "./utils";

export default {
  async getAll() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      let data;
      const response = await HttpService.get(`${api}/prayers`, {
        headers,
      });
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      return data;
    } catch (error) {
      return error;
    }
  },
  async getAllAnswered() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      let data;
      const response = await HttpService.get(`${api}/prayers/answereds`, {
        headers,
      });
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      return data;
    } catch (error) {
      return error;
    }
  },
  async create(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/prayers`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async sendMessage(data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/emails`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async sendMessageInApp(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/in_app_notifications`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async update(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/prayers/${data.prayer.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async updatePhone(data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/prayers/${data.prayer.id}/update_phone`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async sendEncouragementSMS(data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/prayers/${data.id}/send_encouragement_sms`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async get(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/prayers/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async delete(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.delete(`${api}/prayers/${id}`, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  async getAllVerses() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/verses`, {
        headers,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getAllPhotos() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/photos`, {
        headers,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getAllQuickMessages() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/messages`, {
        headers,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async prayNow(data, sendEmail = false) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        // const dataJson = { to: data.email };
        fetch(`${api}/prayers/${data.id}/pray_now?sendEmail=${sendEmail}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          // body: JSON.stringify(dataJson),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  async prayAnswered(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      (({ status }) => ({ status }))(data.prayer);
      await fetch(`${api}/prayers/${data.id}/pray_answered`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async introFlow() {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/users/view_intro_flow`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify({ view_intro_flow: true }),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
};
