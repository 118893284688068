import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ButtonComponent from "../../shared/components/button";
import InputComponent from "../../shared/components/input";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AuthResources from "../../../resources/auth";
import MyProfileResources from "../../../resources/my-profile";
import TextField from "@mui/material/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

class ResetPasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      user: {
        email: null,
        unconfirmed_email: null,
        password: null,
        password_confirmation: null,
        current_password: null,
      },
      showCurrentPassword: false,
      showPassword: false,
      showPasswordConfirm: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendData = this.sendData.bind(this);
    this.sendDataEmail = this.sendDataEmail.bind(this);
    this.sendEmailVerification = this.sendEmailVerification.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  showCurrentPassword() {
    const { showCurrentPassword } = this.state;
    this.setState({ showCurrentPassword: showCurrentPassword ? false : true });
  }

  showPassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: showPassword ? false : true });
  }

  showPasswordConfirm() {
    const { showPasswordConfirm } = this.state;
    this.setState({ showPasswordConfirm: showPasswordConfirm ? false : true });
  }

  loadData(loading = true) {
    this.setState({
      isLoading: loading,
    });

    MyProfileResources.getUserInfo()
      .then((data) => {
        AuthResources.saveAttributes(data);
        this.setState({
          user: {
            confirmed: data.confirmed,
            current_email: data.email,
            email: data.email ? data.email : null,
            unconfirmed_email: data.unconfirmed_email
              ? data.unconfirmed_email
              : null,
          },
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  sendEmailVerification = (email, confirm_current_email = false) => {
    if (!email) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your email",
      });
    } else {
      let userData = {};
      userData.user = { email, confirm_current_email };
      AuthResources.resendEmailVerify(userData)
        .then((response) => {
          response.json().then((parsedJson) => {
            if (parsedJson.errors && parsedJson.errors.length > 0) {
              this.setState({
                snackbarOpen: true,
                snackbarMessage: parsedJson.errors[0].toUpperCase(),
              });
            } else {
              this.setState({
                snackbarOpen: true,
                snackbarMessage: "Email sent successfully",
              });
            }
            this.loadData(false);
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    this.setState((prevState) => ({
      user: { ...prevState.user, [target]: value },
    }));
  }

  async sendDataEmail() {
    const { user } = this.state;
    if (!user.email) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your email",
      });
    } else {
      var userData = {};
      userData.user = user;
      this.setState({ isLoading: true });
      MyProfileResources.updateEmailUser(userData).then((response) => {
        response.json().then((parsedJson) => {
          if (parsedJson.errors) {
            const firstError = `${Object.entries(
              parsedJson.errors
            )[0][0].replace("_", " ")} ${
              Object.entries(parsedJson.errors)[0][1]
            }`;
            this.setState({
              snackbarOpen: true,
              isLoading: false,
              snackbarMessage: firstError,
            });
          } else {
            this.loadData();
            this.setState({
              snackbarOpen: true,
              isLoading: false,
              snackbarMessage: "A verification link has been sent.",
            });
          }
        });
      });
    }
  }

  async sendData() {
    const { user } = this.state;
    if (!user.current_password) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your current password",
      });
    } else {
      if (!user.password) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "Enter your new password",
        });
      } else {
        if (!user.password_confirmation) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Confirm your new password",
          });
        } else {
          if (user.password != user.password_confirmation) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: "Passwords do not match",
            });
          } else {
            MyProfileResources.updatePasswordUser(user).then((response) => {
              response.json().then((parsedJson) => {
                if (
                  parsedJson.errors &&
                  parsedJson.errors.full_messages &&
                  parsedJson.errors.full_messages.length > 0
                ) {
                  this.setState({
                    snackbarOpen: true,
                    snackbarMessage: parsedJson.errors.full_messages[0],
                  });
                } else {
                  this.setState({
                    snackbarOpen: true,
                    snackbarMessage: parsedJson.message,
                  });
                }
              });
            });
          }
        }
      }
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const {
      snackbarOpen,
      snackbarMessage,
      isLoading,
      user,
      showCurrentPassword,
      showPassword,
      showPasswordConfirm,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/my-profile`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />
            <Grid container direction="column" alignItems="center">
              <Typography
                variant="h5"
                className="fontSourceSansPro"
                style={{
                  margin: "2vh auto 2vh",
                  color: "#808080",
                  textAlign: "center",
                  height: "auto",
                }}
              >
                Password
              </Typography>

              <Grid container direction="column" alignItems="center">
                <TextField
                  label={"Current Password"}
                  name={"current_password"}
                  style={{ width: "80%", marginTop: "2vh" }}
                  onChange={this.handleChange}
                  className="fontLatoRegular"
                  InputLabelProps={{ shrink: true }}
                  type={showCurrentPassword ? "text" : "password"}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#808080 !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#808080",
                      position: "absolute",
                      top: "50%",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#808080",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4vh",
                    },
                    endAdornment: showCurrentPassword ? (
                      <VisibilityIcon
                        onClick={() => this.showCurrentPassword()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityIcon>
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => this.showCurrentPassword()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityOffIcon>
                    ),
                  }}
                />

                <TextField
                  label={"New Password"}
                  name={"password"}
                  style={{ width: "80%", marginTop: "2vh" }}
                  onChange={this.handleChange}
                  className="fontLatoRegular"
                  InputLabelProps={{ shrink: true }}
                  type={showPassword ? "text" : "password"}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#808080 !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#808080",
                      position: "absolute",
                      top: "50%",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#808080",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4vh",
                    },
                    endAdornment: showPassword ? (
                      <VisibilityIcon
                        onClick={() => this.showPassword()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityIcon>
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => this.showPassword()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityOffIcon>
                    ),
                  }}
                />

                <TextField
                  label={"Confirmation New Password"}
                  name={"password_confirmation"}
                  style={{ width: "80%", marginTop: "2vh" }}
                  onChange={this.handleChange}
                  className="fontLatoRegular"
                  InputLabelProps={{ shrink: true }}
                  type={showPasswordConfirm ? "text" : "password"}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#808080 !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#808080",
                      position: "absolute",
                      top: "50%",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#808080",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4vh",
                    },
                    endAdornment: showPasswordConfirm ? (
                      <VisibilityIcon
                        onClick={() => this.showPasswordConfirm()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityIcon>
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => this.showPasswordConfirm()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityOffIcon>
                    ),
                  }}
                />
              </Grid>

              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: "2vh" }}
              >
                <ButtonComponent
                  name={"UPDATE PASSWORD"}
                  action={this.sendData}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ marginTop: "2vh" }}
            >
              <Typography
                variant="h5"
                className="fontSourceSansPro"
                style={{
                  margin: "2vh auto 2vh",
                  color: "#808080",
                  textAlign: "center",
                  height: "auto",
                }}
              >
                Email Address
              </Typography>

              <Grid container direction="column" alignItems="center">
                <InputComponent
                  label="Email Address"
                  inputChange={this.handleChange}
                  inputValue={user.email}
                  name={"email"}
                />
              </Grid>

              {!user.confirmed && (
                <Grid container style={{ width: "80%", marginTop: "1vh" }}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: "#565656",
                        fontSize: "0.8rem",
                        textAlign: "left",
                        padding: "0 2vh",
                      }}
                    >
                      {`Your current email ${user.current_email} has not been verified.`}
                      <Button
                        onClick={() =>
                          this.sendEmailVerification(user.current_email, true)
                        }
                        style={{
                          color: "#F07522",
                          textDecoration: "underline",
                          paddingTop: "0",
                          marginTop: "0",
                        }}
                      >
                        Resend
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {user.unconfirmed_email && (
                <Grid container style={{ width: "80%", marginTop: "1vh" }}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        color: "#565656",
                        fontSize: "0.8rem",
                        textAlign: "left",
                        padding: "0 2vh",
                      }}
                    >
                      {`A verification link has been sent to you new email: ${user.unconfirmed_email}.`}
                      <Button
                        onClick={() =>
                          this.sendEmailVerification(user.unconfirmed_email)
                        }
                        style={{
                          color: "#F07522",
                          textDecoration: "underline",
                          paddingTop: "0",
                          marginTop: "0",
                        }}
                      >
                        Resend
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: "2vh" }}
              >
                <ButtonComponent
                  name={"UPDATE EMAIL"}
                  action={this.sendDataEmail}
                />
              </Grid>
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ResetPasswordComponent;
