import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import ConstructionIcon from "@mui/icons-material/Construction";

class NotificationsPreferencesComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <HeaderBarComponent
          showLogo
          showBack
          showBackAction={() => this.props.history.push(`/`)}
          showMenu
          showNotifications
          title={"Prayr Apps"}
        />
        <ConstructionIcon
          size="large"
          style={{ fontSize: "46vh", color: "#F9F6F2" }}
        />
      </div>
    );
  }
}

export default NotificationsPreferencesComponent;
