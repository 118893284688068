import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class ConfirmationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      open: this.props.open,
      handleAccept: this.props.handleAccept,
      handleCancel: this.props.handleCancel,
      AcceptButtonText: this.props.AcceptButtonText,
      CancelButtonText: this.props.CancelButtonText,
    };
  }

  render() {
    const {
      title,
      message,
      open,
      handleAccept,
      handleCancel,
      AcceptButtonText,
      CancelButtonText,
    } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAccept} color="primary" variant="contained">
              {AcceptButtonText}
            </Button>
            <Button onClick={handleCancel} color="primary" variant="contained">
              {CancelButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmationComponent;
