/* eslint-disable react/no-unescaped-entities */
import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@mui/material/Typography";
import AuthResources from "../../resources/auth";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ButtonComponent from "../shared/components/button";
import MyProfileResources from "../../resources/my-profile";

class QuickStartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      termsAndConditions: false,
    };
  }

  saveTermsCondition() {
    const { termsAndConditions } = this.state;
    if (termsAndConditions) {
      this.setState({ isLoading: true });
      AuthResources.saveTermsCondition(true);
      var emailSettingsData = {
        prayer_setting: {
          terms_and_conditions: true,
        },
      };
      MyProfileResources.createOrUpdateUserEmailSettings(emailSettingsData);
      this.props.history.push(`/`);
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  setTermsAndConditions(status) {
    this.setState({ termsAndConditions: status });
  }

  renderFooter() {
    const { termsAndConditions } = this.state;

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          backgroundColor: "#f7f4f2",
          width: "100%",
          marginTop: "3vh",
          maxWidth: "500px",
          flexGrow: 1,
        }}
      >
        {!termsAndConditions && (
          <Paper
            style={{
              borderRadius: "50px",
              width: "60px",
              height: "30px",
              marginTop: "15px",
              backgroundColor: "#a3a3a3",
              boxShadow:
                "rgb(0 0 0 / 16%) 0px 2px 5px, rgb(0 0 0 / 1%) 0px 2px 5px",
              position: "relative",
              margin: "35px auto 8px",
            }}
            onClick={() => this.setTermsAndConditions(true)}
          >
            <div
              style={{
                borderRadius: "10px",
                border: "0",
                height: "20px",
                width: "26px",
                position: "absolute",
                top: "3px",
                right: "5px",
                color: "#c5c5c5",
              }}
            >
              <CloseIcon />
            </div>
            <div
              style={{
                borderRadius: "10px",
                border: "0",
                height: "20px",
                width: "21px",
                backgroundColor: "white",
                position: "absolute",
                top: "5px",
                left: "5px",
              }}
            ></div>
          </Paper>
        )}
        {termsAndConditions && (
          <Paper
            style={{
              borderRadius: "50px",
              width: "60px",
              height: "30px",
              marginTop: "15px",
              backgroundColor: "#f07522",
              boxShadow:
                "rgb(0 0 0 / 16%) 0px 2px 5px, rgb(0 0 0 / 1%) 0px 2px 5px",
              position: "relative",
              margin: "35px auto 8px",
            }}
            onClick={() => this.setTermsAndConditions(false)}
          >
            <div
              style={{
                borderRadius: "10px",
                border: "0",
                height: "20px",
                width: "21px",
                backgroundColor: "white",
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            ></div>
            <div
              style={{
                borderRadius: "10px",
                border: "0",
                height: "20px",
                width: "26px",
                position: "absolute",
                top: "5px",
                left: "5px",
                color: "#c5c5c5",
              }}
            >
              <CheckIcon />
            </div>
          </Paper>
        )}
        <Typography
          variant="subtitle1"
          style={{
            color: "#4a4a4a",
            textAlign: "center",
            fontSize: "15px",
            paddingRight: "3vh",
            paddingLeft: "3vh",
            marginBottom: "40px",
          }}
        >
          I accept the{" "}
          <Typography
            variant="h6"
            style={{
              color: "#F07522",
              fontSize: "15px",
              textDecoration: "underline",
              display: "inline-block",
            }}
            onClick={() =>
              window.open(
                "https://myprayr.com/terms",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            terms and conditions
          </Typography>
        </Typography>
        <ButtonComponent
          name={"GET STARTED"}
          action={() => this.saveTermsCondition()}
          backgroundColor={termsAndConditions ? "#F07522" : "#a3a3a3"}
          color={"#ffffff"}
          borderRadius={"3vh"}
          fontSize={"0.9rem"}
          paddingTop={"1.1vh"}
          marginBottom={"2vh"}
        />
      </Grid>
    );
  }

  renderTexts(title, description) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ marginTop: "3vh" }}
      >
        <Grid item xs={10}>
          <Typography
            variant="h5"
            style={{
              color: "#4a4a4a",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#4a4a4a",
              textAlign: "center",
              fontSize: "15px",
              paddingRight: "3vh",
              paddingLeft: "3vh",
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { snackbarMessage, snackbarOpen, isLoading } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        <div style={{ display: "flex", flexFlow: "column", height: "100vh" }}>
          <img
            src="/quick-start.png"
            style={{ width: "100%", height: "150px" }}
            alt=""
          />
          <div
            style={{
              backgroundColor: "#ffffff",
              position: "relative",
            }}
          >
            <>
              {this.renderTexts(
                "My Prayers",
                "Maintain a personal list of people you want to pray for and easily send them love and encouragement."
              )}
              {this.renderTexts(
                "Our Prayers",
                "Connect to groups in your chosen community and pray for each other."
              )}
              {this.renderTexts(
                "Faith Builder",
                "Journal the ways the Lord has blessed your life and answered prayers so you never forget His faithfulness."
              )}
            </>
          </div>
          {this.renderFooter()}
        </div>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default QuickStartComponent;
