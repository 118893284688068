import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import PhotosResources from "../../../resources/photos";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

class DownloadWallpaperComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  downloadWallpaper(image) {
    this.setState({
      isLoading: true,
    });
    PhotosResources.getPhoto(image.id)
      .then(async (data) => {
        const url = data.url;
        const response = await fetch(url);
        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.style.display = "none";

        anchor.href = blobURL;
        anchor.download = `${image.name}.png`;

        document.body.appendChild(anchor);
        anchor.click();

        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { image } = this.props;
    const { isLoading } = this.state;
    return (
      <Grid container direction="column" alignItems="center">
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        <Grid item xs={9} style={{ marginTop: "2vh" }}>
          <Typography
            variant="h6"
            style={{
              color: "#000000",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Custom Lock Screen
          </Typography>
        </Grid>
        <Grid item xs={9} style={{ marginTop: "2vh", marginBottom: "2vh" }}>
          <img
            alt=""
            src={image.image_url}
            style={{
              width: "30vh",
              height: "50vh",
              borderRadius: "3vh",
              boxShadow:
                "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 44%) 0px 3px 15px",
            }}
          />
        </Grid>
        {image.creator_name && image.creator_link && (
          <Grid
            item
            xs={9}
            style={{ marginBottom: "4vh", textAlign: "center" }}
          >
            Photo Creator: &nbsp;
            <Typography
              variant="h6"
              style={{
                color: image.creator_link ? "#F07522" : "initial",
                fontSize: "0.9rem",
                textDecoration: image.creator_link ? "underline" : "inherit",
                display: "inline-block",
              }}
              onClick={() =>
                image.creator_link
                  ? window.open(
                      image.creator_link,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  : false
              }
            >
              {image.creator_name}
            </Typography>
          </Grid>
        )}
        <Grid container direction="column" alignItems="center">
          <Button
            onClick={() => this.downloadWallpaper(image)}
            style={{
              backgroundColor: "#F07522",
              color: "#ffffff",
              borderRadius: "3vh",
              width: "218px",
              minWidth: "218px",
              height: "35px",
              minHeight: "35px",
              fontSize: "0.9rem",
              paddingTop: "10px",
            }}
            size="large"
            variant="contained"
          >
            Download Wallpaper
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default DownloadWallpaperComponent;
