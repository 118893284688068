import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import ButtonComponent from "../shared/components/button";

class VerifyAccountComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { onAction } = this.props;

    return (
      <div>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{
            marginTop: "8vh",
            position: "absolute",
          }}
        >
          <Grid item xs={10}>
            <Typography
              variant="h5"
              style={{
                color: "#ffffff",
                textAlign: "center",
              }}
            >
              VERIFY YOUR EMAIL
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="subtitle1"
              style={{
                color: "#ffffff",
                textAlign: "center",
                marginTop: "2vh",
                fontSize: "0.9rem",
                paddingRight: "2vh",
                paddingLeft: "2vh",
              }}
            >
              {`A verification link has been sent to your email. Please check your email and click on the verification link in order to finish the registration process.`}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Button
              size="large"
              onClick={onAction}
              style={{
                color: "#ffffff",
                textAlign: "center",
                textTransform: "none",
                textDecoration: "underline",
                marginTop: "2vh",
              }}
            >
              Resend email verification link
            </Button>
          </Grid>
          <ButtonComponent
            name={"LOGIN TO YOUR ACCOUNT"}
            action={() => (location.href = "/login")}
            marginTop={"2vh"}
          />
        </Grid>
      </div>
    );
  }
}

export default VerifyAccountComponent;
