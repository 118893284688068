import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";

class FeedbackComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    return (
      <div>
        <HeaderBarComponent
          showLogo
          showBack
          showBackAction={() => this.props.history.push(`/`)}
          showMenu
          showNotifications
          title={"Prayr Apps"}
        />
        <Grid container direction="column" alignItems="center">
          <h2 style={{ marginTop: "4vh", marginBottom: "2vh" }}>
            We welcome your feedback
          </h2>
          <Typography
            variant="subtitle1"
            style={{
              color: "#5f5f5f",
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            Please contact us at
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: "#F07522",
              fontSize: "18px",
              textDecoration: "underline",
              display: "inline-block",
            }}
            onClick={() =>
              window.open(
                "mailto:feedback@myprayr.com",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            feedback@myprayr.com
          </Typography>
        </Grid>
      </div>
    );
  }
}

export default FeedbackComponent;
