import Grid from "@material-ui/core/Grid";
import { Paper } from "@mui/material";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import Typography from "@mui/material/Typography";
import OurPrayersResources from "../../resources/our-prayers";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import PusherChannelSubscriber from "../../resources/pusher-channel-subscriber";
import BottomNavigationComponent from "../shared/components/bottom-navigation";
import TitleBackgroundComponent from "../shared/components/title-background";
import Button from "@mui/material/Button";
import ConfirmationComponent from "../shared/confirmation/confirmation";

class OurPrayersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: null,
      loadGroups: [],
      isLoading: false,
      anchorEl: null,
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      openDeclineInvitationGroupModalConfirmation: false,
      group: null,
    };
    this.handleNewMessage = this.handleNewMessage.bind(this);
  }

  componentDidMount() {
    this.loadGroups();
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  loadGroups() {
    const { message } = this.state;
    this.setState({
      isLoading: true,
    });
    OurPrayersResources.getAllGroups().then((data) => {
      this.setState({
        loadGroups: data,
        isLoading: false,
      });
      data.map((group) => {
        this.setState({
          [`group${group.id}`]: group.unread_messages,
        });
      });
      this.loadPusherNewMessages(data);
      if (message) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: message,
          message: null,
        });
      }
    });
  }

  changeStatusInvitationGroup(status, groupAccepted = null) {
    const group = groupAccepted ? groupAccepted : this.state.group;
    const data = {
      group_prayer: {
        status,
      },
    };
    OurPrayersResources.changeStatusInvitationGroup(
      group.invitation_id,
      data
    ).then(() => {
      if (status == "accepted") {
        this.props.history.push(`/our-prayers/${group.id}/view-group`, {
          message: "Invitation Accepted",
        });
        window.location.reload(false);
      } else {
        this.props.history.push(`/our-prayers`, {
          message: "Invitation Declined",
        });
        window.location.reload(false);
      }
    });
  }

  renderDeclinedInvitationGroup() {
    const { openDeclineInvitationGroupModalConfirmation } = this.state;
    return (
      <ConfirmationComponent
        open={openDeclineInvitationGroupModalConfirmation}
        handleAccept={() => {
          this.setState({ openDeclineInvitationGroupModalConfirmation: false });
          this.changeStatusInvitationGroup("declined");
        }}
        handleCancel={() => {
          this.setState({
            openDeclineInvitationGroupModalConfirmation: false,
            group: null,
          });
        }}
        title="Confirm"
        message="Are you sure you want to decline the invitation to join this group?"
        AcceptButtonText="Yes"
        CancelButtonText="No"
      ></ConfirmationComponent>
    );
  }

  renderChooseInvitation(group) {
    return (
      <Paper
        style={{
          position: "absolute",
          width: "100%",
          margin: "auto",
          bottom: "-2px",
          left: 0,
          right: 0,
          height: "95px",
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          zIndex: "2",
          backgroundColor: "#f07522",
        }}
      >
        <Typography
          style={{
            marginTop: "2px",
            marginLeft: "2vh",
            fontSize: "14px",
            color: "#ffffff",
          }}
        >
          {`You've been invited to`}
        </Typography>
        <Typography
          style={{
            fontSize: "22px",
            color: "#ffffff",
            marginLeft: "2vh",
            marginTop: "-8px",
          }}
        >
          {group.name}
        </Typography>
        <Button
          style={{
            color: "#8b8b8b",
            borderRadius: "3vh",
            width: "135px",
            height: "35px",
            fontSize: "12px",
            textTransform: "inherit",
            backgroundColor: "#ffffff",
            zIndex: 2,
            position: "absolute",
            bottom: "10px",
            left: "1.5vh",
            paddingTop: "10px",
          }}
          size="large"
          variant="contained"
          onClick={() => {
            this.setState({
              group,
            });
            this.changeStatusInvitationGroup("accepted", group);
          }}
        >
          JOIN
        </Button>
        <Button
          style={{
            color: "#8b8b8b",
            borderRadius: "3vh",
            width: "135px",
            height: "35px",
            fontSize: "12px",
            textTransform: "inherit",
            backgroundColor: "#ffffff",
            zIndex: 2,
            position: "absolute",
            bottom: "10px",
            right: "1.5vh",
            paddingTop: "10px",
          }}
          size="large"
          variant="contained"
          onClick={() =>
            this.setState({
              openDeclineInvitationGroupModalConfirmation: true,
              group,
            })
          }
        >
          DECLINE
        </Button>
      </Paper>
    );
  }

  handleNewMessage(data, group) {
    this.setState((prevState) => {
      return { [`group${group.id}`]: prevState[`group${group.id}`] + 1 };
    });
  }

  loadPusherNewMessages(groups) {
    PusherChannelSubscriber.subscribeAllChannelMessages(
      this.handleNewMessage,
      groups
    );
  }

  renderBottomNavigation() {
    return (
      <BottomNavigationComponent
        iconOne={`url("/footer-bar/Icon-inviteOrange.png")`}
        actionOne={() =>
          this.props.history.push(
            `/our-prayers/invitations-group/add-invitation`
          )
        }
        iconTwo={`url("/footer-bar/Icon_AddOrange.png")`}
        actionTwo={() => this.props.history.push(`/our-prayers/add-group`)}
      />
    );
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { loadGroups, isLoading, snackbarOpen, snackbarMessage } = this.state;

    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div style={{ marginBottom: "15vh" }}>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <TitleBackgroundComponent
              title={"Our Prayers"}
              marginBottom={"2vh"}
            />

            {loadGroups.length > 0 && (
              <div style={{ marginBottom: "10vh" }}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ marginBottom: "3vh" }}
                >
                  <Grid item xs={11}>
                    {loadGroups.map((item) => (
                      <div
                        key={"load" + item.id}
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div
                            className={"group-div" + item.id}
                            style={{ position: "relative" }}
                          >
                            {item.owner && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  color: "#fff",
                                  fontWeight: "bolder",
                                  zIndex: "3",
                                  width: "30px",
                                  minWidth: "30px",
                                  minHeight: "18px",
                                  height: "18px",
                                  paddingBottom: "6px",
                                  paddingTop: "6px",
                                  textAlign: "center",
                                  backgroundColor: "#f07522",
                                  borderRadius: "14px 0 14px 0",
                                }}
                              >
                                <Paper
                                  style={{
                                    backgroundImage: `url("/Owner_Icon_White.png")`,
                                    width: "13px",
                                    backgroundSize: "cover",
                                    marginLeft: "8px",
                                    marginTop: "1px",
                                    height: "15px",
                                    backgroundColor: "#ffffff00",
                                    boxShadow:
                                      "0px 0px 0px 0px rgb(0 0 0 / 0%)",
                                  }}
                                />
                              </div>
                            )}

                            {item.invitation_status != "pending" &&
                              this.state[`group${item.id}`] > 0 && (
                                <Paper
                                  style={{
                                    position: "absolute",
                                    bottom: "0",
                                    right: "0",
                                    color: "#f07522",
                                    fontWeight: "bolder",
                                    padding: "0 3px 0 3px",
                                    zIndex: "3",
                                    width: "auto",
                                    minWidth: "30px",
                                    minHeight: "18px",
                                    height: "18px",
                                    paddingBottom: "6px",
                                    paddingTop: "6px",
                                    textAlign: "center",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "14px 0 14px",
                                  }}
                                >
                                  {this.state[`group${item.id}`]}
                                </Paper>
                              )}

                            <Paper
                              key={"div-group-" + item.id}
                              sx={{
                                mt: "",
                                ".group-options:hover": {
                                  display: "none",
                                  color: "#ffffff !important",
                                },
                              }}
                              onClick={() => {
                                if (
                                  !(
                                    item.invitation_status &&
                                    item.invitation_status == "pending"
                                  )
                                ) {
                                  this.props.history.push(
                                    `/our-prayers/${item.id}/view-group`
                                  );
                                }
                              }}
                              style={{
                                width: "88vw",
                                height: "23vh",
                                maxWidth: "450px",
                                backgroundImage: item.photo_url
                                  ? `url("` + item.photo_url + `")`
                                  : `url("/gradient-images/${item.background_color}")`,
                                borderRadius: "16px",
                                position: "relative",
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                              }}
                            ></Paper>
                            {item.invitation_status != "pending" && (
                              <Typography
                                onClick={() => {
                                  this.props.history.push(
                                    `/our-prayers/${item.id}/view-group`
                                  );
                                }}
                                variant="h6"
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  left: "2vh",
                                  zIndex: "1",
                                  fontSize: "21px",
                                  color: "#ffffff",
                                  overflow: "hidden",
                                }}
                              >
                                {item.name?.length > 20
                                  ? `${item.name.slice(0, 20)}...`
                                  : item.name}
                              </Typography>
                            )}

                            {item.invitation_status &&
                              item.invitation_status == "pending" &&
                              this.renderChooseInvitation(item)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </div>
            )}
            {loadGroups.length == 0 && (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ marginBottom: "3vh" }}
              >
                <Grid
                  item
                  xs={11}
                  style={{ marginLeft: "25px", marginRight: "25px" }}
                >
                  <div
                    key={"loadNoPrayer"}
                    style={{
                      marginTop: "25px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        paddingLeft: "3px",
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <Paper
                          sx={{
                            mt: "",
                            ".prayer-options:hover": {
                              display: "none",
                              color: "#ffffff !important",
                            },
                          }}
                          onClick={() =>
                            this.props.history.push(`/our-prayers/add-group`)
                          }
                          style={{
                            width: "84vw",
                            height: "30vh",
                            maxWidth: "435px",
                            position: "relative",
                            backgroundSize: "cover",
                            borderRadius: "2vh",
                            border: "1px #c3c3c3 solid",
                            boxShadow:
                              "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                          }}
                        ></Paper>
                        <Typography
                          onClick={() =>
                            this.props.history.push(`/our-prayers/add-group`)
                          }
                          variant="h6"
                          style={{
                            position: "absolute",
                            top: "9vh",
                            left: "0",
                            right: "0",
                            zIndex: "1",
                            fontSize: "16px",
                            color: "#c3c3c3",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          Click here to add
                        </Typography>
                        <Typography
                          onClick={() =>
                            this.props.history.push(`/our-prayers/add-group`)
                          }
                          variant="h6"
                          style={{
                            position: "absolute",
                            top: "11.5vh",
                            left: "0",
                            right: "0",
                            zIndex: "1",
                            fontSize: "16px",
                            color: "#c3c3c3",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          a new group
                        </Typography>
                        <Paper
                          onClick={() =>
                            this.props.history.push(`/our-prayers/add-group`)
                          }
                          style={{
                            position: "absolute",
                            top: "16vh",
                            zIndex: "1",
                            width: "40px",
                            height: "40px",
                            margin: "auto",
                            left: 0,
                            right: 0,
                            backgroundImage: `url("/Icon_Add_gray.png")`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            boxShadow:
                              "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {this.renderBottomNavigation()}
        {this.renderDeclinedInvitationGroup()}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default OurPrayersComponent;
