import Grid from "@material-ui/core/Grid";
import React from "react";
import HeaderBarComponent from "../../../shared/header-bar/header-bar";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Paper } from "@mui/material";
import CategoriesListComponent from "../shared/categories-list";
import Snackbar from "@material-ui/core/Snackbar";
import TitleDescriptionComponent from "../../../shared/components/title-description";
import ButtonComponent from "../../../shared/components/button";
import ShareFormComponent from "../shared/share-form";
import MyPrayersResources from "../../../../resources/my-prayers";
import ImagesCategoriesComponent from "../shared/images-categories";
import LocalStorageResources from "../../../../resources/local-storage";
import { web } from "../../../../environments/environment";

class ShareVerseComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      id,
      categories: [],
      data: [],
      showImages: false,
      loadData: false,
      selectedCategory: null,
      formSend: false,
      prayer: {},
      dataForm: {
        from: "no-reply@myprayr.com",
        to: null,
        subject: "I pray this verse speaks to you!",
        content: null,
        photo_id: null,
        phone: null,
        message_type: "verse",
      },
      imageUrl: null,
      previewUrl: null,
      submitText: "SEND",
      messageChannel: "email",
      snackbarOpen: false,
      snackbarMessage: null,
      backUrl: this.props.location?.state?.backUrl
        ? this.props.location.state.backUrl
        : null,
      fromGroup: this.props.location?.state?.fromGroup
        ? this.props.location.state.fromGroup
        : null,
    };
    this.changeCategory = this.changeCategory.bind(this);
    this.filterCategories = this.filterCategories.bind(this);
    this.onCloseShareForm = this.onCloseShareForm.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showForm = this.showForm.bind(this);
    this.showFormWithImage = this.showFormWithImage.bind(this);
    this.menuBackAction = this.menuBackAction.bind(this);
    this.showImages = this.showImages.bind(this);
    this.toggleMessageChannel = this.toggleMessageChannel.bind(this);
  }

  componentDidMount() {
    this.loadPrayer();
    this.loadCategories();
  }

  loadPrayer() {
    const { id } = this.state;
    this.setState({
      isLoading: true,
    });
    MyPrayersResources.get(id).then((data) => {
      if (data.group_id) {
        this.setState({
          fromGroup: { id: data.group_id },
        });
      }
      this.setState({
        prayer: data,
        isLoading: false,
      });
      this.toggleMessageChannel(data.message_type);
    });
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    this.setState((prevState) => ({
      dataForm: { ...prevState.dataForm, [target]: value },
    }));
  }

  toggleMessageChannel(messageChannel) {
    const { dataForm, prayer } = this.state;
    dataForm.from = "no-reply@myprayr.com";
    dataForm.to = prayer.email;
    dataForm.phone = prayer.phone;
    const userData = LocalStorageResources.getObject("authData").data;
    let buttonText = "";
    switch (messageChannel) {
      case "email":
        buttonText = "SEND";
        break;
      case "sms":
        buttonText = "SEND";
        break;
      case "in-app":
        dataForm.from = `${userData.first_name} ${userData.last_name}`;
        (dataForm.to = messageChannel == "in-app" ? prayer.name : prayer.email),
          (buttonText = "SEND");
        break;
      default:
        buttonText = "SEND";
        break;
    }
    this.setState({
      messageChannel: messageChannel,
      submitText: buttonText,
      dataForm: dataForm,
    });
  }

  submitForm(messageChannel = "email") {
    const { dataForm, prayer, fromGroup, previewUrl } = this.state;
    if (messageChannel == "email" || messageChannel == "in-app") {
      if (!dataForm.to) {
        const snackbarMessage = "Enter recipient";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!dataForm.subject) {
          const snackbarMessage = "Enter subject";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          if (!dataForm.content) {
            const snackbarMessage = "Enter content";
            this.setState({
              snackbarOpen: true,
              snackbarMessage,
            });
          } else {
            let sendData = {};
            let payload = {
              subject: dataForm.subject,
              content: dataForm.content,
              photo_id: dataForm.photo_id,
              prayer_id: prayer.id,
              message_type: dataForm.message_type,
            };
            if (messageChannel == "email") {
              (payload.to = dataForm.to), (sendData.email = payload);
              MyPrayersResources.sendMessage(sendData)
                .then((response) => {
                  response.json().then((parsedJson) => {
                    if (parsedJson.errors) {
                      const firstError = `${Object.entries(
                        parsedJson.errors
                      )[0][0].replace("_", " ")} ${
                        Object.entries(parsedJson.errors)[0][1]
                      }`;
                      this.setState({
                        isLoading: false,
                        snackbarOpen: true,
                        snackbarMessage: firstError,
                      });
                    } else {
                      this.props.history.push(
                        !fromGroup
                          ? `/my-prayers`
                          : `/our-prayers/${fromGroup.id}/view-group`,
                        {
                          message: parsedJson.message,
                          fromGroup,
                        }
                      );
                    }
                  });
                })
                .catch(() => {
                  const snackbarMessage = "No update email to prayer";
                  this.setState({
                    snackbarOpen: true,
                    snackbarMessage,
                  });
                });
            } else if (messageChannel == "in-app") {
              (payload.to_user_id = prayer.user_id),
                (sendData.in_app = payload);
              MyPrayersResources.sendMessageInApp(sendData).then(() => {
                this.props.history.push(
                  !fromGroup
                    ? `/my-prayers`
                    : `/our-prayers/${fromGroup.id}/view-group`,
                  {
                    message: "The message has been sent",
                    fromGroup,
                  }
                );
              });
            }
          }
        }
      }
    } else if (messageChannel == "sms") {
      if (!dataForm.phone) {
        const snackbarMessage = "Enter phone";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!dataForm.content) {
          const snackbarMessage = "Enter content";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          var prayerData = { prayer: { id: prayer.id, phone: dataForm.phone } };
          if (!prayer.group_id) {
            MyPrayersResources.updatePhone(prayerData)
              .then((response) => {
                response.json().then((parsedJson) => {
                  if (parsedJson.errors) {
                    this.setState({
                      isLoading: false,
                      snackbarOpen: true,
                      snackbarMessage: parsedJson.errors,
                    });
                  } else {
                    const options = {
                      fromGroup,
                    };
                    if (parsedJson.message)
                      options["message"] = parsedJson.message;
                    const imagePreviewUrl = previewUrl
                      ? `${web}${previewUrl}`
                      : "";
                    location.href = `sms:${dataForm.phone};?&body=${dataForm.content} ${imagePreviewUrl}`;
                    this.props.history.push(
                      !fromGroup
                        ? `/my-prayers`
                        : `/our-prayers/${fromGroup.id}/view-group`,
                      options
                    );
                  }
                });
              })
              .catch(() => {
                const snackbarMessage = "No update phone to prayer";
                this.setState({
                  snackbarOpen: true,
                  snackbarMessage,
                });
              });
          } else {
            MyPrayersResources.sendEncouragementSMS(prayer).then(() => {
              const imagePreviewUrl = previewUrl ? `${web}${previewUrl}` : "";
              location.href = `sms:${dataForm.phone};?&body=${dataForm.content} ${imagePreviewUrl}`;
              this.props.history.push(
                !fromGroup
                  ? `/my-prayers`
                  : `/our-prayers/${fromGroup.id}/view-group`,
                {
                  fromGroup,
                }
              );
            });
          }
        }
      }
    }
  }

  showImages() {
    this.setState({
      showImages: true,
      formSend: false,
    });
  }

  loadCategories() {
    this.setState({
      isLoading: true,
    });
    MyPrayersResources.getAllVerses().then((data) => {
      this.setState({
        categories: data,
        isLoading: false,
      });
    });
  }

  showForm(content, reference) {
    const { prayer } = this.state;
    this.setState((prevState) => ({
      dataForm: {
        ...prevState.dataForm,
        content: content + " " + reference,
        to: prayer.email,
      },
      formSend: true,
      showImages: false,
    }));
  }

  showFormWithImage(data) {
    this.setState((prevState) => ({
      dataForm: {
        ...prevState.dataForm,
        photo_id: data.id,
      },
      imageUrl: data.image_url,
      previewUrl: data.preview_url,
      formSend: true,
      showImages: false,
    }));
  }

  onCloseShareForm() {
    this.setState((prevState) => ({
      dataForm: {
        ...prevState.dataForm,
        photo_id: null,
        subject: "I pray this verse speaks to you!",
        to: null,
        content: null,
        image: null,
      },
      imageUrl: null,
      formSend: false,
      showImages: false,
    }));
  }

  changeCategory(name) {
    const { categories } = this.state;
    this.setState({
      selectedCategory: name,
      data: categories[name],
      loadData: true,
    });
  }

  loadData() {
    const { categories, selectedCategory } = this.state;
    if (selectedCategory) {
      this.setState({
        data: categories[selectedCategory],
        loadData: true,
      });
    } else {
      this.setState({
        loadData: false,
        snackbarOpen: true,
        snackbarMessage: "Select a verse",
      });
    }
  }

  renderCategoriesList() {
    const { categories, selectedCategory } = this.state;
    const categoriesData = Object.keys(categories);
    return (
      <Grid item xs={10} style={{ width: "100vh" }}>
        <CategoriesListComponent
          categories={categoriesData}
          categoriesData={categories}
          showCount={true}
          selected={selectedCategory}
          changeCategory={this.changeCategory}
        />
      </Grid>
    );
  }

  filterCategories() {
    this.setState({
      loadData: false,
      showImages: false,
      formSend: false,
      selectedCategory: null,
      data: [],
    });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  menuBackAction() {
    const { formSend, loadData, prayer, showImages, fromGroup, backUrl } =
      this.state;
    if (formSend) {
      this.onCloseShareForm();
    } else {
      if (loadData) {
        if (showImages) {
          this.setState({
            formSend: true,
            showImages: false,
          });
        } else {
          this.filterCategories();
        }
      } else {
        this.props.history.push(
          backUrl ? backUrl : `/my-prayers/${prayer.id}/view-person/`,
          {
            backUrl: `/my-prayers/${prayer.id}/view-details`,
            fromGroup,
          }
        );
      }
    }
  }

  render() {
    const {
      isLoading,
      loadData,
      data,
      snackbarOpen,
      snackbarMessage,
      formSend,
      prayer,
      dataForm,
      imageUrl,
      previewUrl,
      showImages,
      id,
      messageChannel,
      submitText,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={this.menuBackAction}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />
            {!loadData && !formSend && (
              <div>
                <Grid container direction="column" alignItems="center">
                  <div className="backgroundColorContent">
                    <Grid container direction="column" alignItems="center">
                      <TitleDescriptionComponent
                        title={"Choose a Verse"}
                        typeTitle={"secondary"}
                        description={`The scriptures guide us in every way, use this filter to
                        quickly find the perfect verse based on subject matter`}
                      />
                    </Grid>
                  </div>

                  {this.renderCategoriesList()}
                </Grid>
              </div>
            )}

            {data.length > 0 && loadData && !formSend && !showImages && (
              <div>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ marginBottom: "2vh" }}
                >
                  <div className="backgroundColorContent">
                    <Grid container direction="column" alignItems="center">
                      <TitleDescriptionComponent
                        title={"Choose a Verse"}
                        typeTitle={"secondary"}
                        description={`The scriptures guide us in every way, use this filter to
                        quickly find the perfect verse based on subject matter`}
                      />
                    </Grid>
                  </div>

                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="#436d8a"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: "2vh",
                      }}
                    >
                      Verses
                    </Typography>
                    {data.map((item, i) => (
                      <div key={"cardVerse" + i}>
                        <div
                          key={"divVerse" + i}
                          style={{
                            marginTop: "2vh",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflowY: "auto",
                              justifyContent: !"center",
                            }}
                          >
                            <Paper
                              key={"secondDivVerse" + i}
                              style={{
                                height: "auto",
                                marginLeft: "25px",
                                marginRight: "25px",
                                position: "relative",
                                backgroundSize: "cover",
                                borderRadius: "2vh",
                                backgroundColor: "#053d59",
                                paddingBottom: "2vh",
                              }}
                              onClick={() =>
                                this.showForm(item.content, item.reference)
                              }
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  textAlign: "center",
                                  marginTop: "1vh",
                                  marginBottom: "0.5vh",
                                  zIndex: "1",
                                  fontSize: "1.1rem",
                                  width: "92%",
                                  color: "#9dbdd4",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.reference}
                              </Typography>

                              <Typography
                                variant="h6"
                                style={{
                                  marginTop: !"8vh",
                                  zIndex: "1",
                                  fontSize: "1rem",
                                  lineHeight: "1.4",
                                  marginLeft: "1vh",
                                  marginRight: "1vh",
                                  color: "#bbcad5",
                                  fontWeight: "100",
                                  textAlign: "center",
                                }}
                              >
                                {item.content?.length > 200
                                  ? `${item.content.slice(0, 200)}...`
                                  : item.content}
                              </Typography>
                            </Paper>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Grid>
                </Grid>
                <ButtonComponent
                  name={"BACK TO CATEGORIES"}
                  marginBottom={"2vh"}
                  action={this.filterCategories}
                />
              </div>
            )}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
          </div>
        )}
        {!isLoading && formSend && !showImages && (
          <ShareFormComponent
            dataForm={dataForm}
            prayer={prayer}
            imageUrl={imageUrl}
            previewUrl={previewUrl}
            showImages={this.showImages}
            onClose={this.onCloseShareForm}
            onSubmit={this.submitForm}
            inputChange={this.handleChange}
            onToggleMessage={this.toggleMessageChannel}
            submitText={submitText}
            messageChannel={messageChannel}
          />
        )}
        {!isLoading && !formSend && showImages && (
          <ImagesCategoriesComponent
            prayerId={id}
            actionImage={this.showFormWithImage}
          />
        )}
      </div>
    );
  }
}

export default ShareVerseComponent;
