import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthResources from "../../../resources/auth";
import LocalStorageResources from "../../../resources/local-storage";

const checkAuth = function checkAuth() {
  return AuthResources.checkAuthSession();
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (checkAuth().isLoggedIn) {
        const userData = LocalStorageResources.getObject("authData").data;
        const pathname = window.location.pathname;
        if (userData.terms_and_conditions != true && pathname != '/quick-start'){
          return <Redirect to={{ pathname: "/quick-start" }} />;
        }else if (userData.terms_and_conditions && pathname == '/quick-start'){
          return <Redirect to={{ pathname: "/" }} />;
        }else{
          return <Component {...props} />;
        }
      } else {
        // return <Redirect to={{ pathname: "/login" }} />;
        location.href = `/login${props.location.search}`;
      }
    }}
  />
);

export default AuthRoute;
