import React from "react";
// import Fab from "@material-ui/core/Fab";
// import AddIcon from "@material-ui/icons/Add";
import Typography from "@mui/material/Typography";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FaithEntriesResources from "../../resources/faith-entries";
import moment from "moment";
import BottomNavigationComponent from "../shared/components/bottom-navigation";
import Snackbar from "@material-ui/core/Snackbar";

class FaithBuilderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadFaithEntries: [],
      isLoading: false,
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      snackbarOpen: false,
      snackbarMessage: null,
    };
  }

  componentDidMount() {
    this.loadFaithEntries();
  }

  loadFaithEntries() {
    const { message } = this.state;
    this.setState({
      isLoading: true,
    });
    FaithEntriesResources.getAll(false).then((data) => {
      var dataGroup = [];
      if (data.length > 0) {
        dataGroup = data.reduce(function (rv, x) {
          const event_date = String(
            moment.utc(x["event_date"]).local().format("MMMM - YYYY")
          );
          x["event_date"] = x.event_date.split("-")[2];
          (rv[event_date] = rv[event_date] || []).push(x);
          return rv;
        }, {});
      }
      this.setState({
        loadFaithEntries: dataGroup,
        isLoading: false,
      });
      if (message) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: message,
          message: null,
        });
      }
    });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  renderBottomNavigation() {
    return (
      <BottomNavigationComponent
        iconOne={`url("/footer-bar/Icon_AddOrange.png")`}
        actionOne={() => this.props.history.push(`/faith-builder/add-faith`)}
        widthOne={"40px"}
        heightOne={"38px"}
      />
    );
  }

  renderEventsList() {
    const { loadFaithEntries } = this.state;
    const current_date = String(moment().format("MMMM - YYYY"));
    const events_date = Object.entries(loadFaithEntries);
    return events_date.map(([event_date, events], i) => (
      <div key={i}>
        <Paper
          variant="outlined"
          elevation={0}
          style={{
            padding: "1.2vh 2.2vh",
            color: "#fff",
            border: "0",
            borderRadius: 0,
            backgroundColor: current_date == event_date ? "#023046" : "#023046",
          }}
        >
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs>
              <Typography
                noWrap
                style={{ textAlign: "center", color: "#6a859d" }}
              >
                {event_date
                  .replaceAll(" - ", "")
                  .replaceAll(" ", "")
                  .replaceAll("0", "")
                  .replaceAll("1", "")
                  .replaceAll("2", "")
                  .replaceAll("3", "")
                  .replaceAll("4", "")
                  .replaceAll("5", "")
                  .replaceAll("6", "")
                  .replaceAll("7", "")
                  .replaceAll("8", "")
                  .replaceAll("9", "")
                  .replaceAll("0", "")
                  .toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {events.length > 0 && (
          <div style={{ paddingBottom: "2vh" }}>
            <Grid container>
              <Timeline
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginBottom: 0,
                }}
              >
                {events.map((faith, i) => (
                  <TimelineItem
                    style={{
                      marginTop: "4vh",
                      minHeight: "inherit",
                    }}
                    key={faith.id}
                    sx={{
                      "&.MuiTimelineItem-root:before": {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                    onClick={() =>
                      this.props.history.push(
                        `/faith-builder/${faith.id}/edit-faith`
                      )
                    }
                  >
                    <TimelineSeparator>
                      {i == 0 && (
                        <TimelineConnector
                          style={{
                            backgroundColor: "#d4d4d4",
                            position: "absolute",
                            top: "-4vh",
                            width: "2px",
                            height: "4vh",
                          }}
                        />
                      )}
                      <TimelineDot
                        style={{
                          backgroundColor: "#F07522",
                          fontSize: "1.4vh",
                          marginBottom: "0px",
                          marginTop: "0px",
                          padding: "0.9vh 1vh",
                        }}
                      >
                        {faith.event_date}
                      </TimelineDot>
                      <TimelineConnector
                        style={{ backgroundColor: "#d4d4d4" }}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      style={{
                        padding: "1vh 2vh",
                        backgroundColor: "#fff",
                        borderRadius: "2.2vh",
                        marginLeft: "2vh",
                        boxShadow: "rgb(153 153 153 / 26%) 3px 3px 3px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          borderRadius: 1,
                          bgcolor: "#fff",
                          overflow: "hidden",
                          marginTop: "0px",
                          color: "#9d9d9d",
                        }}
                        container
                        wrap="nowrap"
                        spacing={1}
                      >
                        {faith.content}
                      </Box>
                      {faith.image_url && (
                        <Box
                          key={"div-prayer-" + faith.id}
                          style={{
                            height: "18vh",
                            width: "55vw",
                            maxWidth: "280px",
                            marginBottom: "5px",
                            backgroundImage: faith.image_url
                              ? `url("` + faith.image_url + `")`
                              : null,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                          }}
                        />
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
          </div>
        )}
      </div>
    ));
  }

  render() {
    const { isLoading, loadFaithEntries, snackbarOpen, snackbarMessage } =
      this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              paddingBottom: loadFaithEntries.length > 0 && "92vh",
              height: "100vh",
              backgroundColor: "#f5f5f5",
            }}
          >
            <HeaderBarComponent
              showBack
              showBackAction={() => this.props.history.push(`/`)}
              showMenu
              showLogo
              showNotifications
              title={"Prayr App"}
            />
            <div
              style={{
                backgroundImage: `url("FaithBuilder_Header.png")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "24vh",
                position: "relative",
              }}
            ></div>

            <Grid
              item
              xs={12}
              style={{ paddingBottom: "15vh", backgroundColor: "#f5f5f5" }}
            >
              {this.renderEventsList()}
            </Grid>

            {loadFaithEntries.length == 0 && (
              <Grid container direction="column" alignItems="center">
                <Grid item xs={9} style={{ marginTop: "2vh" }}>
                  <Typography
                    variant="h5"
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    No Entries Yet!
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#000000",
                      textAlign: "center",
                      marginTop: "1vh",
                      fontSize: "2vh",
                    }}
                  >
                    Once you have faith entries, youll see them here.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {this.renderBottomNavigation()}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default FaithBuilderComponent;
