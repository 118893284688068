import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import ButtonComponent from "../shared/components/button";
import Snackbar from "@material-ui/core/Snackbar";
import AuthResources from "../../resources/auth";

class ConfirmationEmailComponent extends React.Component {
  constructor(props) {
    super(props);
    const queryString = require("query-string");
    const headers = queryString.parse(props.location.search);
    this.state = {
      messageValidation: '',
      user: {
        email: null,
      },
      headers,
    };
  }

  componentDidMount() {
    this.sendData();
  }

  sendData() {
    const { headers } = this.state;
    AuthResources.validationToken(headers).then((response) => {
      response.json().then((parsedJson) => {
        if (
          parsedJson.errors &&
          parsedJson.errors.length > 0
        ) {
          this.setState({
            tokenValidate: false,
            messageValidation: parsedJson.errors[0],
          });
        } else {
          this.setState({
            tokenValidate: true,
            messageValidation: parsedJson.message,
          });
        }
      });
    });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const { snackbarOpen, snackbarMessage, messageValidation } =
      this.state;

    return (
      <div>
        <div
          style={{
            backgroundImage: `url("loginbg-img.jpg")`,
            height: "100vh",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <HeaderBarComponent
            showLogo
            showBack
            showBackAction={() => this.props.history.push(`/`)}
            title={"Prayr App"}
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              marginTop: "8vh",
              position: "absolute",
            }}
          >
            <Grid item xs={10}>
              <Typography
                variant="h5"
                className="fontLatoRegular"
                style={{ color: "#ffffff", textAlign: "center" }}
              >
                VERIFY YOUR ACCOUNT
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography
                variant="subtitle1"
                style={{
                  color: "#ffffff",
                  textAlign: "center",
                  marginTop: "2vh",
                  fontSize: "0.9rem",
                  paddingRight: "2vh",
                  paddingLeft: "2vh",
                }}
              >
                { messageValidation }
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              bottom: "8vh",
              position: "absolute",
            }}
          >
            <ButtonComponent
              name={"LOGIN TO YOUR ACCOUNT"}
              action={() => (location.href = "/login")}
              width={"83.1%"}
              marginTop={"2vh"}
              right={"0.1vh"}
              style={{
                bottom: "6vh",
                position: "absolute",
              }}
            />
          </Grid>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default ConfirmationEmailComponent;
