import axios from "axios";
import { api } from "../environments/environment";
import LocalStorageResources from "./local-storage";
import DbResources from "./db";

export default {
  login(email, password) {
    const url = `${api}/auth/sign_in`;
    return axios.post(url, { email, password }, { timeout: 10000 });
  },
  saveAuthData(token, authData, uid, client, settingKey = "auth") {
    const db = DbResources.getInstance();
    const { id } = db.settings
      .filter(({ settingKey }) => settingKey === "auth")
      .first();
    if (!id) {
      db.settings.add({
        settingKey,
        settingValue: {
          token,
          authData,
          uid,
          client,
        },
      });
    } else {
      db.settings.put(
        {
          settingKey,
          settingValue: {
            token,
            authData,
            uid,
            client,
          },
        },
        id
      );
    }
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("authData", JSON.stringify(authData));
    window.localStorage.setItem("uid", uid);
    window.localStorage.setItem("client", client);
  },
  checkAuthSession() {
    if (window.localStorage.getItem("lastActivityTime") === null) {
      const today = new Date();
      window.localStorage.setItem("lastActivityTime", today.getTime());
    }
    const token = window.localStorage.getItem("token");
    const lastActivityTime = window.localStorage.getItem("lastActivityTime");
    return {
      isLoggedIn: token && token !== "",
      lastActivityTime: lastActivityTime,
    };
  },
  getAuthData() {
    return LocalStorageResources.getObject("authData");
  },
  logOut() {
    LocalStorageResources.authClear();
    const db = DbResources.getInstance();
    db.settings.clear();
  },
  saveTermsCondition(conditions = null) {
    const userData = LocalStorageResources.getObject("authData").data;
    userData["terms_and_conditions"] = conditions;
    window.localStorage.setItem("authData", JSON.stringify({ data: userData }));
  },

  saveAttributes(attrs = {}) {
    const userData = LocalStorageResources.getObject("authData").data;
    Object.assign(userData, attrs);
    window.localStorage.setItem("authData", JSON.stringify({ data: userData }));
  },

  async forgotPassword(data) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/auth/password`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            resolve(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },
  async resetPassword(data, headers) {
    Object.assign(data, headers);
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/auth/password`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async register(data) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/auth`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async resendEmailVerify(data) {
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/auth/confirmation`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async validationToken(validationToken) {
    const url =
      `${api}/auth/confirmation?` + new URLSearchParams(validationToken);
    return new Promise((resolve, reject) => {
      try {
        fetch(url, {
          method: "GET",
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
};
