import React from "react";
import Typography from "@mui/material/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

class CategoriesListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { categories, changeCategory, categoriesData, showCount } =
      this.props;
    return (
      <div
        style={{
          width: "100%",
          overflowY: "auto",
        }}
      >
        <Typography
          variant="h5"
          color="#436d8a"
          style={{ fontWeight: "bold", textAlign: "center", marginTop: "2vh" }}
        >
          Topics
        </Typography>

        <List>
          {categories.map((item, i) => (
            <ListItem
              key={"categoriesListComponent" + i}
              className="item itemTopics"
              onClick={() => changeCategory(item)}
            >
              {item}
              <span
                style={{ position: "absolute", right: "2vh", color: "#79a0bb" }}
              >
                {showCount ? `${categoriesData[item].length}` : null}
              </span>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

export default CategoriesListComponent;
