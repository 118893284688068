import React from "react";
import HeaderBarComponent from "../../../shared/header-bar/header-bar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ShareFormComponent from "../shared/share-form";
import ImagesCategoriesComponent from "../shared/images-categories";
import MyPrayersResources from "../../../../resources/my-prayers";
import LocalStorageResources from "../../../../resources/local-storage";
import Snackbar from "@material-ui/core/Snackbar";
import { web } from "../../../../environments/environment";

class SharePhoto extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      id,
      categories: [],
      data: [],
      loadData: false,
      selectedCategory: null,
      formSend: false,
      prayer: {},
      dataForm: {
        from: "no-reply@myprayr.com",
        to: null,
        subject: "I pray this encourages you!",
        content: null,
        photo_id: null,
        phone: null,
        message_type: "photo",
      },
      imageUrl: null,
      previewUrl: null,
      snackbarOpen: false,
      snackbarMessage: "",
      submitText: "SEND",
      messageChannel: "email",
      backUrl: this.props.location?.state?.backUrl
        ? this.props.location.state.backUrl
        : null,
      fromGroup: this.props.location?.state?.fromGroup
        ? this.props.location.state.fromGroup
        : null,
    };
    this.closeForm = this.closeForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showForm = this.showForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.menuBackAction = this.menuBackAction.bind(this);
    this.toggleMessageChannel = this.toggleMessageChannel.bind(this);
  }

  componentDidMount() {
    this.loadPrayer();
  }

  loadPrayer() {
    const { id } = this.state;
    this.setState({
      isLoading: true,
    });
    MyPrayersResources.get(id).then((data) => {
      if (data.group_id) {
        this.setState({
          fromGroup: { id: data.group_id },
        });
      }
      this.setState({
        prayer: data,
        isLoading: false,
      });
      this.toggleMessageChannel(data.message_type);
    });
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = evt.target.value;
    this.setState((prevState) => ({
      dataForm: { ...prevState.dataForm, [target]: value },
    }));
  }

  toggleMessageChannel(messageChannel) {
    const { dataForm, prayer } = this.state;
    dataForm.from = "no-reply@myprayr.com";
    dataForm.to = prayer.email;
    dataForm.phone = prayer.phone;
    const userData = LocalStorageResources.getObject("authData").data;
    let buttonText = "";
    switch (messageChannel) {
      case "email":
        buttonText = "SEND";
        break;
      case "sms":
        buttonText = "SEND";
        break;
      case "in-app":
        dataForm.from = `${userData.first_name} ${userData.last_name}`;
        (dataForm.to = messageChannel == "in-app" ? prayer.name : prayer.email),
          (buttonText = "SEND");
        break;
      default:
        buttonText = "SEND";
        break;
    }
    this.setState({
      messageChannel: messageChannel,
      submitText: buttonText,
      dataForm: dataForm,
    });
  }

  submitForm(messageChannel = "email") {
    const { dataForm, prayer, fromGroup, previewUrl } = this.state;
    if (messageChannel == "email" || messageChannel == "in-app") {
      if (!dataForm.to) {
        const snackbarMessage = "Enter recipient";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!dataForm.subject) {
          const snackbarMessage = "Enter subject";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          if (!dataForm.content) {
            const snackbarMessage = "Enter content";
            this.setState({
              snackbarOpen: true,
              snackbarMessage,
            });
          } else {
            let sendData = {};
            let payload = {
              subject: dataForm.subject,
              content: dataForm.content,
              photo_id: dataForm.photo_id,
              prayer_id: prayer.id,
              message_type: dataForm.message_type,
            };
            if (messageChannel == "email") {
              (payload.to = dataForm.to), (sendData.email = payload);
              MyPrayersResources.sendMessage(sendData)
                .then((response) => {
                  response.json().then((parsedJson) => {
                    if (parsedJson.errors) {
                      const firstError = `${Object.entries(
                        parsedJson.errors
                      )[0][0].replace("_", " ")} ${
                        Object.entries(parsedJson.errors)[0][1]
                      }`;
                      this.setState({
                        isLoading: false,
                        snackbarOpen: true,
                        snackbarMessage: firstError,
                      });
                    } else {
                      this.props.history.push(
                        !fromGroup
                          ? `/my-prayers`
                          : `/our-prayers/${fromGroup.id}/view-group`,
                        {
                          message: parsedJson.message,
                          fromGroup,
                        }
                      );
                    }
                  });
                })
                .catch(() => {
                  const snackbarMessage = "No update email to prayer";
                  this.setState({
                    snackbarOpen: true,
                    snackbarMessage,
                  });
                });
            } else if (messageChannel == "in-app") {
              (payload.to_user_id = prayer.user_id),
                (sendData.in_app = payload);
              MyPrayersResources.sendMessageInApp(sendData).then(() => {
                this.props.history.push(
                  !fromGroup
                    ? `/my-prayers`
                    : `/our-prayers/${fromGroup.id}/view-group`,
                  {
                    message: "The message has been sent",
                    fromGroup,
                  }
                );
              });
            }
          }
        }
      }
    } else if (messageChannel == "sms") {
      if (!dataForm.phone) {
        const snackbarMessage = "Enter phone";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!dataForm.content) {
          const snackbarMessage = "Enter content";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          var prayerData = { prayer: { id: prayer.id, phone: dataForm.phone } };
          if (!prayer.group_id) {
            MyPrayersResources.updatePhone(prayerData)
              .then((response) => {
                response.json().then((parsedJson) => {
                  if (parsedJson.errors) {
                    this.setState({
                      isLoading: false,
                      snackbarOpen: true,
                      snackbarMessage: parsedJson.errors,
                    });
                  } else {
                    const options = {
                      fromGroup,
                    };
                    if (parsedJson.message)
                      options["message"] = parsedJson.message;
                    const imagePreviewUrl = previewUrl
                      ? `${web}${previewUrl}`
                      : "";
                    location.href = `sms:${dataForm.phone};?&body=${dataForm.content} ${imagePreviewUrl}`;
                    this.props.history.push(
                      !fromGroup
                        ? `/my-prayers`
                        : `/our-prayers/${fromGroup.id}/view-group`,
                      options
                    );
                  }
                });
              })
              .catch(() => {
                const snackbarMessage = "No update phone to prayer";
                this.setState({
                  snackbarOpen: true,
                  snackbarMessage,
                });
              });
          } else {
            MyPrayersResources.sendEncouragementSMS(prayer).then(() => {
              const imagePreviewUrl = previewUrl ? `${web}${previewUrl}` : "";
              location.href = `sms:${dataForm.phone};?&body=${dataForm.content} ${imagePreviewUrl}`;
              this.props.history.push(
                !fromGroup
                  ? `/my-prayers`
                  : `/our-prayers/${fromGroup.id}/view-group`,
                {
                  fromGroup,
                }
              );
            });
          }
        }
      }
    }
  }

  showForm(data) {
    const { prayer } = this.state;
    this.setState((prevState) => ({
      dataForm: {
        ...prevState.dataForm,
        photo_id: data.id,
        to: prayer.email,
      },
      imageUrl: data.image_url,
      previewUrl: data.preview_url,
      formSend: true,
    }));
  }

  closeForm() {
    this.setState((prevState) => ({
      dataForm: {
        ...prevState.dataForm,
        subject: "I pray this encourages you!",
        to: null,
        content: null,
        image: null,
      },
      formSend: false,
    }));
  }

  menuBackAction() {
    const { formSend, loadData, prayer, fromGroup, backUrl } = this.state;

    if (formSend) {
      this.closeForm();
    } else {
      if (loadData) {
        this.loadData();
      } else {
        this.props.history.push(
          backUrl ? backUrl : `/my-prayers/${prayer.id}/view-person/`,
          {
            backUrl: `/my-prayers/${prayer.id}/view-details`,
            fromGroup,
          }
        );
      }
    }
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const {
      isLoading,
      formSend,
      prayer,
      dataForm,
      imageUrl,
      previewUrl,
      id,
      snackbarOpen,
      snackbarMessage,
      messageChannel,
      submitText,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={this.menuBackAction}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />
            {!formSend && (
              <ImagesCategoriesComponent
                prayerId={id}
                actionImage={this.showForm}
              />
            )}

            {formSend && (
              <ShareFormComponent
                dataForm={dataForm}
                prayer={prayer}
                imageUrl={imageUrl}
                previewUrl={previewUrl}
                showImages={this.closeForm}
                onClose={this.closeForm}
                onSubmit={this.submitForm}
                inputChange={this.handleChange}
                onToggleMessage={this.toggleMessageChannel}
                submitText={submitText}
                messageChannel={messageChannel}
              />
            )}

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SharePhoto;
