import { api } from "../environments/environment";
//import HttpService from "./http";
import ResourcesUtils from "./utils";

export default {
  async messageReaction(groupId, messageId, data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/groups/${groupId}/messages/${messageId}/reactions`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
};
