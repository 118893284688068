import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";

class TitleBackgroundComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, marginBottom } = this.props;

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          backgroundColor: "#023046",
          position: "relative",
          height: "auto",
          marginBottom: marginBottom && marginBottom,
        }}
      >
        <Grid item xs={10} style={{ width: "90%" }}>
          <Typography
            variant="h5"
            style={{
              color: "#ffffff",
              textAlign: "center",
              margin: "1vh auto 1vh",
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default TitleBackgroundComponent;
