import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import MyPrayersResources from "../../../resources/my-prayers";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormComponent from "../shared/form";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import validator from "validator";

class AddPersonComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      prayer: {
        name: null,
        description: null,
        background_color: "Grad_1.png",
        email: null,
        phone: null,
        avatar: null,
        reminder_frequency: "never",
        reminder_date: null,
        reminder_time: null,
        reminder_repetition: null,
        message_type: "email",
      },
      prayerReminder: {
        reminder_frequency: "never",
        reminder_date: null,
        reminder_time: null,
        reminder_repetition: null,
      },
      contactInfo: {
        message_type: "email",
        email: null,
        phone: null,
      },
      reminderDate: moment(moment(), "MM/DD/yyyy"),
      lastSaveReminderDate: moment(moment(), "MM/DD/yyyy"),
      reminderTime: moment(moment(), "hh:mm A").add(1, "hours"),
      lastSaveReminderTime: moment(moment(), "hh:mm A").add(1, "hours"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeGradient = this.handleChangeGradient.bind(this);
    this.handleChangeEncouragementSendMethod =
      this.handleChangeEncouragementSendMethod.bind(this);
    this.saveReminder = this.saveReminder.bind(this);
    this.closeReminder = this.closeReminder.bind(this);
    this.deleteReminder = this.deleteReminder.bind(this);
    this.onChangeReminderDate = this.onChangeReminderDate.bind(this);
    this.onChangeReminderTime = this.onChangeReminderTime.bind(this);
    this.create = this.create.bind(this);
    this.deleteAvatar = this.deleteAvatar.bind(this);
    this.deleteGradient = this.deleteGradient.bind(this);
    this.saveContactInfo = this.saveContactInfo.bind(this);
    this.closeContactInfo = this.closeContactInfo.bind(this);
  }

  handleChange(evt) {
    const { prayerReminder } = this.state;
    const target = evt.target.name;
    const value = target != "avatar" ? evt.target.value : evt.target.files[0];
    if (target == "reminder_repetition" || target == "reminder_frequency") {
      if (
        target == "reminder_frequency" &&
        value == "custom" &&
        prayerReminder.reminder_frequency == "never"
      ) {
        this.initDateReminder(value);
      }
      this.setState((prevState) => ({
        prayerReminder: { ...prevState.prayerReminder, [target]: value },
      }));
    } else {
      if (target == "phone" || target == "email") {
        this.setState((prevState) => ({
          contactInfo: { ...prevState.contactInfo, [target]: value },
        }));
      } else {
        this.setState((prevState) => ({
          prayer: { ...prevState.prayer, [target]: value },
        }));
        if (target == "avatar") {
          var file = evt.target.files[0];
          if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = async () => {
              this.setState((prevState) => ({
                prayer: { ...prevState.prayer, avatar: reader.result },
              }));
              this.deleteGradient();
            };
          }
        }
      }
    }
  }

  handleChangeGradient(gradientName) {
    this.setState((prevState) => ({
      prayer: {
        ...prevState.prayer,
        background_color: gradientName,
      },
    }));
    this.deleteAvatar();
  }

  handleChangeEncouragementSendMethod(value) {
    this.setState((prevState) => ({
      contactInfo: {
        ...prevState.contactInfo,
        message_type: value,
      },
    }));
  }

  saveReminder() {
    const { prayerReminder, reminderDate, reminderTime } = this.state;
    let dateComplete = `${prayerReminder.reminder_date} ${prayerReminder.reminder_time}`;
    let dateCompleteFormat = moment(dateComplete, "DD-MM-YYYY HH:mm A");
    this.setState((prevState) => ({
      prayer: {
        ...prevState.prayer,
        reminder_frequency: prayerReminder.reminder_frequency,
        reminder_date: moment.utc(dateCompleteFormat).format("YYYY-MM-DD"),
        reminder_time: moment.utc(dateCompleteFormat).format("HH:mm A"),
        reminder_repetition: prayerReminder.reminder_repetition,
      },
      lastSaveReminderDate: reminderDate,
      lastSaveReminderTime: reminderTime,
    }));
  }

  closeReminder() {
    const { prayer, lastSaveReminderDate, lastSaveReminderTime } = this.state;
    this.setState({
      prayerReminder: {
        reminder_frequency: prayer.reminder_frequency,
        reminder_date: prayer.reminder_date,
        reminder_time: prayer.reminder_time,
        reminder_repetition: prayer.reminder_repetition,
      },
      reminderDate: lastSaveReminderDate,
      reminderTime: lastSaveReminderTime,
    });
  }

  deleteReminder() {
    this.setState((prevState) => ({
      prayerReminder: {
        ...prevState.prayerReminder,
        reminder_frequency: "never",
        reminder_date: null,
        reminder_time: null,
        reminder_repetition: null,
      },
      prayer: {
        ...prevState.prayer,
        reminder_frequency: "never",
        reminder_date: null,
        reminder_time: null,
        reminder_repetition: null,
      },
    }));
  }

  onChangeReminderDate(evt) {
    this.setState((prevState) => ({
      prayerReminder: {
        ...prevState.prayerReminder,
        reminder_date: evt.format("DD/MM/yyyy"),
      },
      reminderDate: evt,
    }));
  }

  onChangeReminderTime(evt) {
    this.setState((prevState) => ({
      prayerReminder: {
        ...prevState.prayerReminder,
        reminder_time: evt.format("hh:mm A"),
      },
      reminderTime: evt,
    }));
  }

  initDateReminder(value) {
    const todayDate = moment(moment(), "MM/DD/yyyy");
    const todayTime = moment("8:00 AM", "hh:mm A");
    if (value != "never") {
      this.setState((prevState) => ({
        prayerReminder: {
          ...prevState.prayerReminder,
          reminder_date: todayDate.format("DD/MM/yyyy"),
          reminder_time: todayTime.format("hh:mm A"),
          reminder_repetition: "never",
        },
        reminderDate: todayDate,
        reminderTime: todayTime,
      }));
    } else {
      if (value == "never") {
        this.setState((prevState) => ({
          prayerReminder: {
            ...prevState.prayerReminder,
            reminder_date: null,
            reminder_time: null,
            reminder_repetition: null,
          },
          reminderDate: todayDate,
          reminderTime: todayTime,
        }));
      }
    }
  }

  create() {
    const { prayer } = this.state;

    if (!prayer.avatar && !prayer.background_color) {
      const snackbarMessage = "Enter a profile photo or background color";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    } else {
      if (!prayer.name) {
        const snackbarMessage = "Enter a name";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!prayer.description) {
          const snackbarMessage = "Enter a prayer request details";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          var prayerData = {};
          prayerData.prayer = prayer;
          this.setState({ isLoading: true });
          MyPrayersResources.create(prayerData)
            .then(() => {
              this.props.history.push(`/my-prayers`);
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        }
      }
    }
  }

  deleteAvatar() {
    this.setState((prevState) => ({
      prayer: { ...prevState.prayer, avatar: null },
    }));
  }

  deleteGradient() {
    this.setState((prevState) => ({
      prayer: { ...prevState.prayer, background_color: null },
    }));
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  saveContactInfo() {
    const { contactInfo } = this.state;
    let emailContact = contactInfo.email
      ? contactInfo.email.replaceAll(" ", "")
      : null;
    let isValidEmailContact = true;
    if (emailContact) {
      isValidEmailContact = validator.isEmail(emailContact);
      emailContact = isValidEmailContact ? emailContact : null;
    }
    this.setState((prevState) => ({
      prayer: {
        ...prevState.prayer,
        message_type: contactInfo.message_type,
        email: emailContact,
        phone: contactInfo.phone,
      },
      contactInfo: {
        ...prevState.contactInfo,
        email: emailContact,
      },
    }));
    if (!isValidEmailContact) {
      const snackbarMessage = "Enter a valid email";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    }
  }

  closeContactInfo() {
    const { prayer } = this.state;
    this.setState({
      contactInfo: {
        message_type: prayer.message_type,
        email: prayer.email,
        phone: prayer.phone,
      },
    });
  }

  render() {
    const {
      prayer,
      snackbarMessage,
      snackbarOpen,
      isLoading,
      reminderDate,
      reminderTime,
      prayerReminder,
      lastSaveReminderDate,
      lastSaveReminderTime,
      contactInfo,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        <div>
          <HeaderBarComponent
            showLogo
            showBack
            showBackAction={() => this.props.history.push(`/my-prayers`)}
            showMenu
            showNotifications
            title={"Prayr Apps"}
          />
          <Grid container direction="column" alignItems="center">
            <FormComponent
              title={"Add Prayer Recipient"}
              description={`We believe in the power of an image to create a connection
                within prayer. Please select the image you would like used
                throughout the app.`}
              prayer={prayer}
              createOrUpdate={this.create}
              deleteAvatar={this.deleteAvatar}
              onChange={this.handleChange}
              onChangeGradient={this.handleChangeGradient}
              onSelectEncouragementSendMethod={
                this.handleChangeEncouragementSendMethod
              }
              redirectToEmailSettings={() =>
                this.props.history.push(`/my-profile/user-email-settings`)
              }
              closeForm={() => this.props.history.push(`/my-prayers`)}
              reminderDate={reminderDate}
              reminderTime={reminderTime}
              onChangeReminderDate={this.onChangeReminderDate}
              onChangeReminderTime={this.onChangeReminderTime}
              initDateReminder={this.initDateReminder}
              prayerReminder={prayerReminder}
              saveReminder={this.saveReminder}
              closeReminder={this.closeReminder}
              deleteReminder={this.deleteReminder}
              lastSaveReminderDate={lastSaveReminderDate}
              lastSaveReminderTime={lastSaveReminderTime}
              contactInfo={contactInfo}
              saveContactInfo={this.saveContactInfo}
              closeContactInfo={this.closeContactInfo}
            />
          </Grid>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default AddPersonComponent;
