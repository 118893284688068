import { api } from "../environments/environment";
import HttpService from "./http";
import ResourcesUtils from "./utils";

export default {
  async getAll() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      let data;
      const response = await HttpService.get(`${api}/notifications`, {
        headers,
      });
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      data = data.sort((a, b) => (a.id < b.id ? 1 : -1));

      return data;
    } catch (error) {
      return error;
    }
  },
  async delete(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.delete(`${api}/notifications/${id}`, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async get(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/notifications/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }
};