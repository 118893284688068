import axios from 'axios';
import LocalStorageResources from './local-storage';

const HttpService = axios.create({
  defaultInterceptors: true,
});

HttpService.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        LocalStorageResources.authClear();
        window.location.href = `/login`;
      }
    }
    return Promise.reject(error);
  },
);

export default HttpService;
