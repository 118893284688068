import { api } from "../environments/environment";
import HttpService from "./http";
import ResourcesUtils from "./utils";

export default {
  async getAllGroups() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      let data;
      const response = await HttpService.get(`${api}/groups`, {
        headers,
      });
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      data = data.sort((a, b) => (a.id < b.id ? 1 : -1));
      return data;
    } catch (error) {
      return error;
    }
  },
  async createGroup(data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/groups`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async updateGroup(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/groups/${data.group.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async deleteGroup(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.delete(`${api}/groups/${id}`, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  async getGroup(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/groups/${id}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getAllGroupPrayers(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      let data;
      const response = await HttpService.get(`${api}/groups/${id}/prayers`, {
        headers,
      });
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      return data;
    } catch (error) {
      return error;
    }
  },
  async getInvitationsGroup(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      let data;
      const response = await HttpService.get(`${api}/groups/${id}/members`, {
        headers,
      });
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      return data;
    } catch (error) {
      return error;
    }
  },
  async createInvitationGroup(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/groups/${data.group_id}/invite`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async changeStatusInvitationGroup(id, data) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/group_prayers/${id}`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify(data),
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  async leaveGroup(id) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/group_prayers/${id}/leave_group`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  async closeGroup(id) {
    const headers = await ResourcesUtils.buildHeaders();
    return new Promise((resolve, reject) => {
      try {
        fetch(`${api}/groups/${id}/close`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...headers,
          },
        }).then((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  async deleteInvitationGroup(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.delete(
        `${api}/group_prayers/${data.id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  async resendInvitationGroup(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(
        `${api}/group_prayers/${data.id}/resend`,
        {
          headers,
        }
      );
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      data = data.sort((a, b) => (a.id < b.id ? 1 : -1));
      return data;
    } catch (error) {
      return error;
    }
  },
  async reinviteInvitationGroup(data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(
        `${api}/group_prayers/${data.id}/reinvite`,
        {
          headers,
        }
      );
      data = response.data.map((prayer) => ({
        ...prayer,
      }));
      data = data.sort((a, b) => (a.id < b.id ? 1 : -1));
      return data;
    } catch (error) {
      return error;
    }
  },
  async sendMessageGroup(id, data) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/groups/${id}/messages`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(data),
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
  async messagesGroup(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/groups/${id}/messages`, {
        headers,
      });
      let data = response.data.map((prayer) => ({
        ...prayer,
      }));
      data = data.sort((a, b) => (a.id < b.id ? -1 : 1));
      return data;
    } catch (error) {
      return error;
    }
  },
  async markAsReadMessagesGroup(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/groups/${id}/messages/read`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },

  async deleteMessagesGroup(groupId, id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      await fetch(`${api}/groups/${groupId}/messages/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...headers,
        },
      }).then((data) => {
        return data;
      });
    } catch (error) {
      return error;
    }
  },
};
