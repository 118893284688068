import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import OurPrayersResources from "../../../resources/our-prayers";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import BottomNavigationComponent from "../../shared/components/bottom-navigation";
import { Paper } from "@mui/material";

class GroupInvitationsComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      id,
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      group: {},
      invitationsGroup: [],
      anchorEl: null,
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      controlShowOptionsInvitation: {},
    };
    this.deleteInvitation = this.deleteInvitation.bind(this);
    this.resendInvitation = this.resendInvitation.bind(this);
    this.reinviteInvitation = this.reinviteInvitation.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  loadGroup(loading = true) {
    const { id, message, controlShowOptionsInvitation } = this.state;
    this.setState({
      isLoading: loading,
    });
    OurPrayersResources.getGroup(id).then((data) => {
      if (data.owner) {
        this.setState({
          group: data,
        });
        OurPrayersResources.getInvitationsGroup(id).then((data) => {
          data.map((d) => {
            controlShowOptionsInvitation[`showOptionInvitation${d.id}`] = false;
          });
          this.setState({
            invitationsGroup: data.sort(
              (a, b) => Number(b.owner) - Number(a.owner)
            ),
            isLoading: false,
          });
          if (message) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: message,
              message: null,
            });
          }
        });
      } else {
        this.props.history.push(`/`);
      }
    });
  }

  renderInvitationOptions(invitationId) {
    const { controlShowOptionsInvitation } = this.state;
    this.setState((prevState) => ({
      controlShowOptionsInvitation: {
        ...prevState.controlShowOptionsInvitation,
        [`showOptionInvitation${invitationId}`]: controlShowOptionsInvitation[
          `showOptionInvitation${invitationId}`
        ]
          ? false
          : true,
      },
    }));
  }

  renderBottomNavigation() {
    const { group } = this.state;
    return (
      <BottomNavigationComponent
        iconOne={`url("/footer-bar/Icon_AddOrange.png")`}
        actionOne={() =>
          this.props.history.push(
            `/our-prayers/invitations-group/add-invitation`,
            {
              group_id: group.id,
            }
          )
        }
        widthOne={"40px"}
        heightOne={"38px"}
      />
    );
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  deleteInvitation(invitation) {
    this.setState({ isLoading: true });
    if (!invitation.owner) {
      OurPrayersResources.deleteInvitationGroup(invitation)
        .then(() => {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: "Member has been deleted successfully",
          });
          this.loadGroup();
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  resendInvitation(item) {
    this.setState({ isLoading: true });
    OurPrayersResources.resendInvitationGroup(item)
      .then(() => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "The invitation was resent",
        });
        this.loadGroup(false);
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  reinviteInvitation(item) {
    this.setState({ isLoading: true });
    OurPrayersResources.reinviteInvitationGroup(item)
      .then(() => {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "The invitation was sent again",
        });
        this.loadGroup(false);
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      snackbarMessage,
      snackbarOpen,
      isLoading,
      group,
      invitationsGroup,
      controlShowOptionsInvitation,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() =>
                this.props.history.push(`/our-prayers/${group.id}/view-group`)
              }
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <Grid container alignItems="center" direction="column">
              <Grid style={{ marginTop: "1.5vh", marginBottom: "2vh" }}>
                <Typography
                  variant="h5"
                  className="fontSourceSansPro"
                  style={{
                    color: "#000000",
                    textAlign: "center",
                    height: "auto",
                  }}
                >
                  {group.name} Members
                </Typography>
              </Grid>
            </Grid>

            {invitationsGroup.length > 0 && (
              <div style={{ paddingBottom: "20vh" }}>
                {invitationsGroup.map((invitationGroup) => (
                  <Grid
                    key={invitationGroup.id}
                    style={{
                      position: "relative",
                      width: "90%",
                      margin: "0 25px 2vh 10px",
                      display: "flex",
                      marginLeft: "25px",
                    }}
                    container
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={4}
                      onClick={() => {
                        if (!invitationGroup.owner) {
                          this.renderInvitationOptions(invitationGroup.id);
                        }
                      }}
                    >
                      <div
                        className={"invitation-div" + invitationGroup.id}
                        style={{
                          position: "relative",
                          paddingRight: "2vh",
                        }}
                      >
                        {controlShowOptionsInvitation[
                          `showOptionInvitation${invitationGroup.id}`
                        ] && (
                          <Paper
                            sx={{
                              ".invitation-options:hover": {
                                display: "none",
                                color: "#ffffff !important",
                              },
                            }}
                            style={{
                              width: "100%",
                              height: "12vh",
                              borderRadius: "2vh",
                              backgroundColor: "#F07522",
                              position: "absolute",
                            }}
                          >
                            <Paper
                              sx={{
                                boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                              }}
                              style={{
                                position: "absolute",
                                backgroundColor: "#fff0",
                                backgroundImage: `url("/Delete_Icon_White.png")`,
                                backgroundSize: "cover",
                                right: "2vh",
                                zIndex: "2",
                                width: "22px",
                                height: "26px",
                                top: "4.5vh",
                                left: "1.5vh",
                              }}
                              onClick={() =>
                                this.deleteInvitation(invitationGroup)
                              }
                            />
                          </Paper>
                        )}
                        <Paper
                          sx={{
                            ".invitation-options:hover": {
                              display: "none",
                              color: "#ffffff !important",
                            },
                          }}
                          style={{
                            width: "100%",
                            height: "12vh",
                            marginLeft:
                              controlShowOptionsInvitation[
                                `showOptionInvitation${invitationGroup.id}`
                              ] && "6vh",
                            borderRadius: "2vh",
                            backgroundImage: invitationGroup.user_avatar_url
                              ? `url("` + invitationGroup.user_avatar_url + `")`
                              : `url("/gradient-images/${invitationGroup.background_color}")`,
                            position: "relative",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        ></Paper>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={
                        !controlShowOptionsInvitation[
                          `showOptionInvitation${invitationGroup.id}`
                        ]
                          ? 8
                          : 4
                      }
                      style={{
                        position: "relative",
                        marginLeft:
                          controlShowOptionsInvitation[
                            `showOptionInvitation${invitationGroup.id}`
                          ] && "6vh",
                      }}
                      onClick={() => {
                        if (!invitationGroup.owner) {
                          this.renderInvitationOptions(invitationGroup.id);
                        }
                      }}
                    >
                      {(invitationGroup.user_first_name ||
                        invitationGroup.user_last_name) && (
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "18px",
                            width: "28vh",
                            boxOrient: "vertical",
                            overflow: "hidden",
                            color: "#808080",
                          }}
                        >
                          {invitationGroup.user_first_name}{" "}
                          {invitationGroup.user_last_name}
                        </Typography>
                      )}
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: "14px",
                          width: "28vh",
                          boxOrient: "vertical",
                          overflow: "hidden",
                          color: "#808080",
                        }}
                      >
                        {invitationGroup.email}
                      </Typography>
                      {invitationGroup.owner && (
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "14px",
                            width: "21vh",
                            boxOrient: "vertical",
                            overflow: "hidden",
                            color: "#F07522",
                          }}
                        >
                          GROUP OWNER
                        </Typography>
                      )}
                      {!invitationGroup.owner &&
                        invitationGroup.status != "accepted" && (
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: "14px",
                              width: "21vh",
                              boxOrient: "vertical",
                              overflow: "hidden",
                              color: "#808080",
                            }}
                          >
                            {invitationGroup.status.toUpperCase()}
                          </Typography>
                        )}
                    </Grid>

                    {invitationGroup.status == "pending" && (
                      <Typography
                        key={invitationGroup.id}
                        variant="h6"
                        style={{
                          fontSize: "14px",
                          maxWidth: "100%",
                          boxOrient: "vertical",
                          overflow: "hidden",
                          color: "#F07522",
                          position: "absolute",
                          bottom:
                            !invitationGroup.user_first_name &&
                            !invitationGroup.user_last_name
                              ? "3.2vh"
                              : "1.4vh",
                          right: 0,
                          zIndex: 2,
                        }}
                        onClick={() => this.resendInvitation(invitationGroup)}
                      >
                        RESEND INVITE
                      </Typography>
                    )}
                    {invitationGroup.status == "declined" && (
                      <Typography
                        key={invitationGroup.id}
                        variant="h6"
                        style={{
                          fontSize: "14px",
                          maxWidth: "100%",
                          boxOrient: "vertical",
                          overflow: "hidden",
                          color: "#F07522",
                          position: "absolute",
                          bottom:
                            !invitationGroup.user_first_name &&
                            !invitationGroup.user_last_name
                              ? "3.2vh"
                              : "1.4vh",
                          right: 0,
                          zIndex: 2,
                        }}
                        onClick={() => this.reinviteInvitation(invitationGroup)}
                      >
                        REINVITE
                      </Typography>
                    )}
                  </Grid>
                ))}
              </div>
            )}
            {this.renderBottomNavigation()}
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default GroupInvitationsComponent;
