import Grid from "@material-ui/core/Grid";
import { Paper } from "@mui/material";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import Typography from "@mui/material/Typography";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import MyPrayersResources from "../../resources/my-prayers";
import FilledInput from "@mui/material/FilledInput";
import "./my-prayers.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import LocalStorageResources from "../../resources/local-storage";
import TitleDescriptionComponent from "../shared/components/title-description";
import moment from "moment";
import MyProfileResources from "../../resources/my-profile";
import Snackbar from "@material-ui/core/Snackbar";
import BottomNavigationComponent from "../shared/components/bottom-navigation";
import Button from "@mui/material/Button";

class MyPrayersComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: null,
      loadPrayers: [],
      filterLoadPrayers: [],
      listView: false,
      open: false,
      isLoading: false,
      anchorEl: null,
      enableSearch: false,
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      userData: [],
      userEmailSettings: [],
      onSwipeProgress: false,
      controlShowPrayNowModals: {},
    };

    this.initPage = React.createRef();
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onClickTypeView = this.onClickTypeView.bind(this);
  }

  componentDidMount() {
    this.loadPrayers();
    //this.introFlow();
  }

  accentFold(s) {
    var accent_map = {
      á: "a",
      é: "e",
      è: "e",
      í: "i",
      ó: "o",
      ú: "u",
      Á: "a",
      É: "e",
      Í: "i",
      Ó: "o",
      Ú: "u",
    };
    if (!s) {
      return "";
    }
    var ret = "";
    for (var i = 0; i < s.length; i++) {
      ret += accent_map[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  }

  filter(searchFilter = null) {
    const { loadPrayers } = this.state;
    var filterLoadPrayers = loadPrayers.filter((f) => {
      if (searchFilter) {
        var filter = [];
        const searchByUpper = this.accentFold(searchFilter).toUpperCase();
        filter =
          this.accentFold(f.name).toUpperCase().indexOf(searchByUpper) >= 0;
        return filter;
      } else {
        return loadPrayers;
      }
    });
    filterLoadPrayers = filterLoadPrayers.filter(
      (f) => f.id.toString().indexOf("p-") === -1
    );
    filterLoadPrayers = filterLoadPrayers.sort((a, b) =>
      a.id < b.id ? 1 : -1
    );
    this.setState({ filterLoadPrayers });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  scrollToTop = () => {
    if (this.initPage) {
      this.initPage.scrollIntoView({ behavior: "auto" });
    }
  };

  trailingActions = (data) => (
    <TrailingActions>
      <SwipeAction onClick={() => {}}>
        <div
          style={{
            backgroundColor: "#F07522",
            position: "relative",
            width: "10vh",
            height: "30vh",
            borderRadius: "1vh",
          }}
        >
          <Paper
            onClick={() =>
              this.props.history.push(`/my-prayers/${data.id}/view-person`)
            }
            sx={{
              boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
            }}
            style={{
              backgroundColor: "#fff0",
              top: "9vh",
              left: "60%",
              position: "absolute",
              textAlign: "center",
              transform: "translate(-50%, -50%)",
              width: "5.5vh",
              height: "5.2vh",
              backgroundImage: `url("/prayer-encourage.png")`,
              backgroundSize: "cover",
            }}
          />
          <Paper
            onClick={() =>
              this.props.history.push(`/my-prayers/${data.id}/edit-person`)
            }
            sx={{
              boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
            }}
            style={{
              backgroundColor: "#fff0",
              bottom: "4vh",
              left: "60%",
              position: "absolute",
              textAlign: "center",
              transform: "translate(-50%, -50%)",
              width: "5.5vh",
              height: "5.4vh",
              backgroundImage: `url("/prayer-edit.png")`,
              backgroundSize: "cover",
            }}
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  trailingActionsList = (id) => (
    <TrailingActions>
      <SwipeAction onClick={() => {}}>
        <div
          style={{
            marginLeft: "-1vh",
            backgroundColor: "#F07522",
            position: "relative",
            width: "10vh",
            height: "15vh",
            borderRadius: "0vh 1vh 1vh 0vh",
          }}
        >
          <Paper
            onClick={() =>
              this.props.history.push(`/my-prayers/${id}/view-person`)
            }
            sx={{
              boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
            }}
            style={{
              backgroundColor: "#fff0",
              top: "5vh",
              left: "65%",
              position: "absolute",
              textAlign: "center",
              transform: "translate(-50%, -50%)",
              width: "3.5vh",
              height: "3.2vh",
              backgroundImage: `url("/prayer-encourage.png")`,
              backgroundSize: "cover",
            }}
          />
          <Paper
            onClick={() =>
              this.props.history.push(`/my-prayers/${id}/edit-person`)
            }
            sx={{
              boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
            }}
            style={{
              backgroundColor: "#fff0",
              bottom: "2vh",
              left: "65%",
              position: "absolute",
              textAlign: "center",
              transform: "translate(-50%, -50%)",
              width: "3.5vh",
              height: "3.4vh",
              backgroundImage: `url("/prayer-edit.png")`,
              backgroundSize: "cover",
            }}
          />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  loadPrayers(loading = true) {
    const { message, controlShowPrayNowModals } = this.state;
    this.setState({
      isLoading: loading,
    });
    MyPrayersResources.getAll().then((data) => {
      const userData = LocalStorageResources.getObject("authData").data;
      const currentDate = moment().format("DD/MM/yyyy");
      data.map((d) => {
        controlShowPrayNowModals[`showPrayNowPrayer${data.id}`] = false;
        const lastPrayDate = moment
          .utc(d.last_prayer_date)
          .local()
          .format("DD/MM/yyyy");
        d.prayToday =
          d.last_prayer_date == null || currentDate != lastPrayDate
            ? true
            : false;
      });
      const filterPrayToday = data
        .filter((f) => f.prayToday)
        .sort((a, b) => (a.id < b.id ? 1 : -1));
      const filterDontPrayToday = data
        .filter((f) => !f.prayToday)
        .sort(
          (a, b) =>
            moment.utc(a.last_prayer_date).local() -
            moment.utc(b.last_prayer_date).local()
        );
      const dataFilter = filterPrayToday.concat(filterDontPrayToday);
      this.setState({
        loadPrayers: dataFilter,
        isLoading: false,
        filterLoadPrayers: dataFilter,
        userData,
      });
      MyProfileResources.get().then((userEmailSettings) => {
        this.setState({
          userEmailSettings,
        });
      });
      // this.introFlow();
      if (message) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: message,
          message: null,
        });
      }
    });
  }

  introFlow() {
    MyProfileResources.getUserInfo().then((user) => {
      if (!user.view_intro_flow) {
        this.setState({
          introFlow: true,
        });
        MyPrayersResources.introFlow();
      }
    });
  }

  onClickSearch() {
    this.handleClose;
    this.handleSearch();
    this.scrollToTop();
  }

  onClickTypeView() {
    const { listView } = this.state;
    if (!listView) {
      this.handleClose;
      this.handleListView(true);
    } else {
      this.handleClose;
      this.handleListView(false);
    }
    this.scrollToTop();
  }

  renderBottomNavigation() {
    const { listView } = this.state;
    return (
      <BottomNavigationComponent
        iconOne={
          !listView
            ? `url("/footer-bar/Icon_ListViewOrange.png")`
            : `url("/footer-bar/Icon_CardViewOrange.png")`
        }
        actionOne={() => this.onClickTypeView()}
        iconTwo={`url("/footer-bar/Icon_SearchOrange.png")`}
        actionTwo={() => this.onClickSearch()}
        widthTwo={"29px"}
        iconThree={`url("/footer-bar/Icon_AddOrange.png")`}
        actionThree={() => this.props.history.push(`/my-prayers/add-person`)}
      />
    );
  }

  handleSearch = () => {
    const { enableSearch, loadPrayers } = this.state;
    this.setState({
      enableSearch: !enableSearch ? true : false,
      filterLoadPrayers: loadPrayers,
    });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleListView = (param) => {
    this.setState({ listView: param });
  };

  renderModalPrayNow(prayer, listView = false) {
    const { userEmailSettings, loadPrayers } = this.state;
    return (
      <Paper
        style={{
          position: "absolute",
          width: !listView ? "97%" : "92%",
          margin: "auto",
          bottom: !listView && "6px",
          left: 0,
          right: 0,
          height: !listView ? "auto" : "12.5vh",
          borderRadius: "2vh",
          zIndex: "2",
          background:
            "linear-gradient(rgb(255, 178, 122) 0%, rgb(245, 138, 66) 46%, rgb(241, 124, 44) 92%)",
        }}
        onClick={() => {
          loadPrayers.map((d) => {
            this.setState((prevState) => ({
              controlShowPrayNowModals: {
                ...prevState.controlShowPrayNowModals,
                [`showPrayNowPrayer${d.id}`]: false,
              },
            }));
          });
          this.loadPrayers(false);
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
          }}
          style={{
            margin: "1vh auto 0.5vh",
            backgroundColor: "#fff0",
            backgroundImage: `url("/CheckMark_Icon.png")`,
            backgroundSize: "cover",
            zIndex: "2",
            width: "33px",
            height: "33px",
            position: listView && "absolute",
            right: listView && "1.5vh",
            top: listView && "3vh",
          }}
        />
        <Typography
          style={{
            textAlign: !listView && "center",
            marginTop: listView && "2vh",
            marginLeft: listView && "2vh",
            fontSize: "20px",
            color: "#ffffff",
            fontWeight: "bold",
          }}
        >
          Prayer Marked Complete!
        </Typography>
        {((prayer.message_type == "email" && !prayer.email) ||
          (prayer.message_type == "sms" && !prayer.phone)) && (
          <>
            {!listView && (
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "#ffffff",
                  paddingLeft: "5vh",
                  paddingRight: "5vh",
                  paddingBottom: "2vh",
                }}
              >
                You can automatically message{" "}
                {prayer.name?.length > 20
                  ? `${prayer.name.slice(0, 20)}...`
                  : prayer.name}{" "}
                each time you pray by adding an email or cell.
              </Typography>
            )}
            <Button
              style={{
                color: "#888a88",
                borderRadius: "3vh",
                width: "218px",
                minWidth: "218px",
                height: "35px",
                minHeight: "35px",
                fontSize: "0.9rem",
                paddingTop: "0.7vh",
                textTransform: "capitalize",
                backgroundColor: "#ffffff",
                marginRight: !listView && "auto",
                marginLeft: !listView ? "auto" : "2vh",
                marginBottom: !listView && "1vh",
                display: "block",
              }}
              size="large"
              variant="contained"
              onClick={() =>
                this.props.history.push(`/my-prayers/${prayer.id}/edit-person`)
              }
            >
              ADD CONTACT INFO
            </Button>
          </>
        )}
        {prayer.message_type == "email" &&
          prayer.email &&
          !userEmailSettings.automatic_message_sending && (
            <>
              {!listView && (
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    paddingLeft: "5vh",
                    paddingRight: "5vh",
                    paddingBottom: "2vh",
                  }}
                >
                  You can automatically send a pre-set message to people that
                  you pray for.
                </Typography>
              )}
              <Button
                style={{
                  color: "#888a88",
                  borderRadius: "3vh",
                  width: "218px",
                  minWidth: "218px",
                  height: "35px",
                  minHeight: "35px",
                  fontSize: "0.9rem",
                  paddingTop: "0.7vh",
                  textTransform: "capitalize",
                  backgroundColor: "#ffffff",
                  marginRight: !listView && "auto",
                  marginLeft: !listView ? "auto" : "2vh",
                  marginBottom: !listView && "1vh",
                  display: "block",
                }}
                size="large"
                variant="contained"
                onClick={() =>
                  this.props.history.push(`/my-profile/user-email-settings`)
                }
              >
                SET UP MESSAGING
              </Button>
            </>
          )}
        {prayer.message_type == "sms" &&
          prayer.phone &&
          !userEmailSettings.automatic_message_sending && (
            <>
              {!listView && (
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    paddingLeft: "5vh",
                    paddingRight: "5vh",
                    paddingBottom: "2vh",
                  }}
                >
                  You can automatically send a pre-set message to people that
                  you pray for.
                </Typography>
              )}
              <Button
                style={{
                  color: "#888a88",
                  borderRadius: "3vh",
                  width: "218px",
                  minWidth: "218px",
                  height: "35px",
                  minHeight: "35px",
                  fontSize: "0.9rem",
                  paddingTop: "0.7vh",
                  textTransform: "capitalize",
                  backgroundColor: "#ffffff",
                  marginRight: !listView && "auto",
                  marginLeft: !listView ? "auto" : "2vh",
                  marginBottom: !listView && "1vh",
                  display: "block",
                }}
                size="large"
                variant="contained"
                onClick={() =>
                  this.props.history.push(`/my-profile/user-email-settings`)
                }
              >
                SET UP MESSAGING
              </Button>
            </>
          )}
        {((prayer.message_type == "email" && prayer.email) ||
          (prayer.message_type == "sms" && prayer.phone)) &&
          userEmailSettings.automatic_message_sending && (
            <Button
              style={{
                color: "#888a88",
                borderRadius: "3vh",
                width: "218px",
                minWidth: "218px",
                height: "35px",
                minHeight: "35px",
                fontSize: "0.9rem",
                paddingTop: "0.7vh",
                textTransform: "capitalize",
                backgroundColor: "#ffffff",
                marginRight: !listView && "auto",
                marginLeft: !listView ? "auto" : "2vh",
                marginBottom: !listView && "1vh",
                display: "block",
                marginTop: "1vh",
              }}
              size="large"
              variant="contained"
              onClick={() => this.prayNow(prayer, true)}
            >
              SEND ENCOURAGEMENT
            </Button>
          )}
      </Paper>
    );
  }

  prayNowOnly(prayer) {
    MyPrayersResources.prayNow(prayer).then(() => {
      this.loadPrayers(false);
    });
  }

  prayNow(prayer, sendEncouragement = false) {
    const { userEmailSettings } = this.state;
    if (!sendEncouragement) {
      MyPrayersResources.prayNow(prayer).then(() => {
        this.setState((prevState) => ({
          controlShowPrayNowModals: {
            ...prevState.controlShowPrayNowModals,
            [`showPrayNowPrayer${prayer.id}`]: true,
          },
        }));
      });
    } else {
      if (prayer.message_type == "sms" && prayer.phone) {
        if (userEmailSettings.automatic_message_sending) {
          location.href = `sms:${prayer.phone};?&body=${userEmailSettings.default_message}`;
        }
      } else {
        MyPrayersResources.prayNow(prayer, true);
      }
    }
  }

  render() {
    const {
      loadPrayers,
      filterLoadPrayers,
      listView,
      isLoading,
      enableSearch,
      snackbarOpen,
      snackbarMessage,
      onSwipeProgress,
      controlShowPrayNowModals,
      introFlow,
    } = this.state;

    return (
      <div style={{ marginBottom: "15vh" }}>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.initPage = el;
              }}
            />
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />
            {introFlow && (
              <Paper
                style={{
                  height: "95vh",
                  backgroundImage: `url("/intro_flow_my_prayers.png")`,
                  backgroundSize: "100% 100%",
                  width: "100%",
                  position: "fixed",
                  maxWidth: "500px",
                }}
                onClick={() => {
                  this.setState({ introFlow: false });
                }}
              ></Paper>
            )}
            {!introFlow && (
              <>
                <Grid container alignItems="center" direction="column">
                  <TitleDescriptionComponent title={"My Prayers"} />
                </Grid>
                <Grid container direction="column">
                  {enableSearch && (
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ marginBottom: "2vh" }}
                    >
                      <FilledInput
                        id="standard-basic"
                        label="Search..."
                        sx={{
                          backgroundColor: "#ffffff00 !important",
                          "&.MuiFilledInput-root:after": {
                            borderBottom: "2px solid #F07522 !important",
                          },
                        }}
                        variant="standard"
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ marginTop: "2vh" }}
                          >
                            <IconButton
                              onClick={() => this.handleSearch()}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        style={{ width: "80%" }}
                        onChange={(event) => this.filter(event.target.value)}
                      />
                    </Grid>
                  )}
                </Grid>
                {filterLoadPrayers.length > 0 && !listView && (
                  <div style={{ marginBottom: "10vh" }}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      style={{ marginBottom: "3vh" }}
                    >
                      <Grid
                        item
                        xs={11}
                        style={{ marginLeft: "25px", marginRight: "25px" }}
                      >
                        <SwipeableList threshold={0.5} type={ListType.IOS}>
                          {filterLoadPrayers.map((item) => (
                            <SwipeableListItem
                              style={{ width: "40vh" }}
                              key={item.id}
                              trailingActions={this.trailingActions(item)}
                              onSwipeStart={() => {
                                this.setState({
                                  onSwipeProgress: true,
                                });
                              }}
                              onSwipeProgress={() => {
                                this.setState({
                                  onSwipeProgress: true,
                                });
                              }}
                            >
                              <div
                                key={"load" + item.id}
                                style={{
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    paddingLeft: "3px",
                                  }}
                                >
                                  <div
                                    className={"prayer-div" + item.id}
                                    style={{ position: "relative" }}
                                  >
                                    <Paper
                                      key={"div-prayer-" + item.id}
                                      sx={{
                                        ".prayer-options:hover": {
                                          display: "none",
                                          color: "#ffffff !important",
                                        },
                                      }}
                                      onClick={() => {
                                        if (!onSwipeProgress) {
                                          this.props.history.push(
                                            `/my-prayers/${item.id}/view-details/`
                                          );
                                        } else {
                                          this.setState({
                                            onSwipeProgress: false,
                                          });
                                        }
                                      }}
                                      onTouchEnd={() => {
                                        this.setState({
                                          onSwipeProgress: false,
                                        });
                                      }}
                                      style={{
                                        width: "84vw",
                                        maxWidth: "435px",
                                        height: "30vh",
                                        backgroundImage: item.avatar_url
                                          ? `url("` + item.avatar_url + `")`
                                          : `url("/gradient-images/${item.background_color}")`,
                                        borderRadius: "2vh",
                                        position: "relative",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                      }}
                                    />
                                    <Paper
                                      onClick={() => {
                                        this.props.history.push(
                                          `/my-prayers/${item.id}/view-person`
                                        );
                                      }}
                                      sx={{
                                        boxShadow:
                                          "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                                      }}
                                      style={{
                                        position: "absolute",
                                        bottom: "1vh",
                                        backgroundColor: "#fff0",
                                        backgroundImage: `url("/send-encouragement.png")`,
                                        backgroundSize: "cover",
                                        right: "8.5vh",
                                        zIndex: "2",
                                        width: "33px",
                                        height: "37px",
                                      }}
                                    />
                                    <Paper
                                      onClick={() => this.prayNowOnly(item)}
                                      sx={{
                                        boxShadow:
                                          "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                                      }}
                                      style={{
                                        position: "absolute",
                                        bottom: "1.4vh",
                                        backgroundColor: "#fff0",
                                        backgroundImage: item.prayToday
                                          ? `url("/pray-now-icon-new.png")`
                                          : `url("/pray-now-icon-checked-new.png")`,
                                        backgroundSize: "cover",
                                        right: "2vh",
                                        zIndex: 2,
                                        width: "33px",
                                        height: "33px",
                                      }}
                                    />
                                    {item.avatar_url && (
                                      <Paper
                                        style={{
                                          backgroundColor: "#063D59",
                                          width: "100%",
                                          height: "8vh",
                                          position: "absolute",
                                          borderRadius: "0 0vh 2vh 2vh",
                                          bottom: 0,
                                          opacity: 0.5,
                                        }}
                                        onClick={() => {
                                          if (!onSwipeProgress) {
                                            this.props.history.push(
                                              `/my-prayers/${item.id}/view-details/`
                                            );
                                          } else {
                                            this.setState({
                                              onSwipeProgress: false,
                                            });
                                          }
                                        }}
                                      ></Paper>
                                    )}
                                    <Typography
                                      onClick={() => {
                                        if (!onSwipeProgress) {
                                          this.props.history.push(
                                            `/my-prayers/${item.id}/view-details/`
                                          );
                                        } else {
                                          this.setState({
                                            onSwipeProgress: false,
                                          });
                                        }
                                      }}
                                      onTouchEnd={() => {
                                        this.setState({
                                          onSwipeProgress: false,
                                        });
                                      }}
                                      variant="h6"
                                      style={{
                                        position: "absolute",
                                        bottom: item.avatar_url
                                          ? "1.8vh"
                                          : null,
                                        transform: !item.avatar_url
                                          ? "translate(0%, -435%)"
                                          : null,
                                        left: !item.avatar_url ? 0 : "2vh",
                                        right: !item.avatar_url ? 0 : null,
                                        zIndex: "1",
                                        fontSize: "2.5vh",
                                        color: "#ffffff",
                                        fontWeight: "bold",
                                        textAlign: !item.avatar_url
                                          ? "center"
                                          : null,
                                        overflow: "hidden",
                                      }}
                                    >
                                      {item.name?.length > 20
                                        ? `${item.name.slice(0, 20)}...`
                                        : item.name}
                                    </Typography>
                                    {/* {item.description && (
                                      <Typography
                                        onClick={() => {
                                          if (!onSwipeProgress) {
                                            this.props.history.push(
                                              `/my-prayers/${item.id}/view-details/`
                                            );
                                          } else {
                                            this.setState({
                                              onSwipeProgress: false,
                                            });
                                          }
                                        }}
                                        onTouchEnd={() => {
                                          this.setState({
                                            onSwipeProgress: false,
                                          });
                                        }}
                                        variant="h6"
                                        style={{
                                          position: "absolute",
                                          bottom: item.avatar_url
                                            ? "1vh"
                                            : null,
                                          transform: !item.avatar_url
                                            ? "translate(0%, -468%)"
                                            : null,
                                          right: !item.avatar_url ? 0 : null,
                                          left: !item.avatar_url ? 0 : "2vh",
                                          zIndex: "1",
                                          textAlign: !item.avatar_url
                                            ? "center"
                                            : null,
                                          fontSize: "2vh",
                                          color: "#ffffff",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item.description?.length > 25
                                          ? `${item.description.slice(
                                              0,
                                              25
                                            )}...`
                                          : item.description}
                                      </Typography>
                                    )} */}
                                    {controlShowPrayNowModals[
                                      `showPrayNowPrayer${item.id}`
                                    ] && this.renderModalPrayNow(item)}
                                  </div>
                                </div>
                              </div>
                            </SwipeableListItem>
                          ))}
                        </SwipeableList>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {filterLoadPrayers.length > 0 && listView && (
                  <div style={{ marginBottom: "10vh" }}>
                    <Grid
                      item
                      xs={12}
                      style={{ padding: "2vh", paddingTop: "0" }}
                    >
                      {filterLoadPrayers.map((item) => (
                        <Grid style={{ width: "100%" }} key={item.id}>
                          <div
                            key={item.id}
                            style={{
                              width: "100%",
                              marginBottom: "2vh",
                              display: "flex",
                            }}
                          >
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <div
                                  className={"prayer-div" + item.id}
                                  style={{
                                    position: "relative",
                                    paddingRight: "2vh",
                                  }}
                                >
                                  <Paper
                                    sx={{
                                      ".prayer-options:hover": {
                                        display: "none",
                                        color: "#ffffff !important",
                                      },
                                    }}
                                    onClick={() =>
                                      this.props.history.push(
                                        `/my-prayers/${item.id}/view-details/`
                                      )
                                    }
                                    style={{
                                      width: "100%",
                                      height: "12vh",
                                      borderRadius: "2vh",
                                      backgroundImage: item.avatar_url
                                        ? `url("` + item.avatar_url + `")`
                                        : `url("/gradient-images/${item.background_color}")`,
                                      position: "relative",
                                      backgroundSize: "cover",
                                      backgroundPosition: "center center",
                                    }}
                                  >
                                    {!item.avatar_url && (
                                      <Typography
                                        onClick={() =>
                                          this.props.history.push(
                                            `/my-prayers/${item.id}/view-details/`
                                          )
                                        }
                                        variant="h6"
                                        style={{
                                          position: "absolute",
                                          bottom: item.avatar_url
                                            ? "2vh"
                                            : null,
                                          left: !item.avatar_url ? 0 : "2vh",
                                          right: !item.avatar_url ? 0 : null,
                                          zIndex: "1",
                                          top: !item.avatar_url ? "4vh" : null,
                                          fontSize: !item.avatar_url
                                            ? "2.5vh"
                                            : "3vh",
                                          textAlign: !item.avatar_url
                                            ? "center"
                                            : null,
                                          color: "#ffffff",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.name.split(" ").shift().charAt(0)}
                                        {item.name.indexOf(" ") >= 0
                                          ? item.name.split(" ").pop().charAt(0)
                                          : null}
                                      </Typography>
                                    )}
                                  </Paper>
                                  <Paper
                                    onClick={() => this.prayNowOnly(item)}
                                    sx={{
                                      boxShadow:
                                        "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                                    }}
                                    style={{
                                      backgroundColor: "#fff0",
                                      backgroundImage: item.prayToday
                                        ? `url("pray-now-icon-new.png")`
                                        : `url("pray-now-icon-checked-new.png")`,
                                      backgroundSize: "cover",
                                      zIndex: "2",
                                      width: "26px",
                                      height: "26px",
                                      position: "absolute",
                                      bottom: "1vh",
                                      right: "3vh",
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                onClick={() =>
                                  this.props.history.push(
                                    `/my-prayers/${item.id}/view-details/`
                                  )
                                }
                                style={{
                                  position: "relative",
                                  paddingRight: "0.5vh",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "2.5vh",
                                    color: "#4D4D4D",
                                  }}
                                >
                                  {item.name?.length > 20
                                    ? `${item.name.slice(0, 20)}...`
                                    : item.name}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontSize: "1.6vh",
                                    maxWidth: "100%",
                                    boxOrient: "vertical",
                                    overflow: "hidden",
                                    color: "#808080",
                                  }}
                                >
                                  {item.description.length > 105
                                    ? `${item.description?.slice(0, 105)}...`
                                    : item.description}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={1}
                                style={{
                                  position: "relative",
                                }}
                              >
                                {/* <Paper
                                  onClick={() => this.prayNowOnly(item)}
                                  sx={{
                                    boxShadow:
                                      "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                                  }}
                                  style={{
                                    backgroundColor: "#fff0",
                                    backgroundImage: item.prayToday
                                      ? `url("pray-now-icon-new.png")`
                                      : `url("pray-now-icon-checked-new.png")`,
                                    backgroundSize: "cover",
                                    zIndex: "2",
                                    width: "26px",
                                    height: "26px",
                                  }}
                                /> */}
                              </Grid>
                            </Grid>
                            {controlShowPrayNowModals[
                              `showPrayNowPrayer${item.id}`
                            ] && this.renderModalPrayNow(item, true)}
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
                {filterLoadPrayers.length == 0 && loadPrayers.length == 0 && (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    style={{ marginBottom: "3vh" }}
                  >
                    <Grid
                      item
                      xs={11}
                      style={{ marginLeft: "25px", marginRight: "25px" }}
                    >
                      <div
                        key={"loadNoPrayer"}
                        style={{
                          marginTop: "25px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            paddingLeft: "3px",
                          }}
                        >
                          <div style={{ position: "relative" }}>
                            <Paper
                              sx={{
                                mt: "",
                                ".prayer-options:hover": {
                                  display: "none",
                                  color: "#ffffff !important",
                                },
                              }}
                              onClick={() =>
                                this.props.history.push(
                                  `/my-prayers/add-person`
                                )
                              }
                              style={{
                                width: "84vw",
                                height: "30vh",
                                maxWidth: "435px",
                                position: "relative",
                                backgroundSize: "cover",
                                borderRadius: "2vh",
                                border: "1px #c3c3c3 solid",
                                boxShadow:
                                  "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                              }}
                            ></Paper>
                            <Typography
                              onClick={() =>
                                this.props.history.push(
                                  `/my-prayers/add-person`
                                )
                              }
                              variant="h6"
                              style={{
                                position: "absolute",
                                top: "9vh",
                                left: "0",
                                right: "0",
                                zIndex: "1",
                                fontSize: "16px",
                                color: "#c3c3c3",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              Click here to add
                            </Typography>
                            <Typography
                              onClick={() =>
                                this.props.history.push(
                                  `/my-prayers/add-person`
                                )
                              }
                              variant="h6"
                              style={{
                                position: "absolute",
                                top: "11.5vh",
                                left: "0",
                                right: "0",
                                zIndex: "1",
                                fontSize: "16px",
                                color: "#c3c3c3",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              a new prayer recipient
                            </Typography>
                            <Paper
                              onClick={() =>
                                this.props.history.push(
                                  `/my-prayers/add-person`
                                )
                              }
                              style={{
                                position: "absolute",
                                top: "16vh",
                                zIndex: "1",
                                width: "40px",
                                height: "40px",
                                margin: "auto",
                                left: 0,
                                right: 0,
                                backgroundImage: `url("/Icon_Add_gray.png")`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                boxShadow:
                                  "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </div>
        )}
        {!introFlow && this.renderBottomNavigation()}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default MyPrayersComponent;
