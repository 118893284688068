import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import UploadImageComponent from "../shared/components/upload-image";
import ButtonComponent from "../shared/components/button";
import VerifyAccountComponent from "../verify-account/verify-account";
import Snackbar from "@material-ui/core/Snackbar";
import momentTimeZone from "moment-timezone";
import AuthResources from "../../resources/auth";
import { app } from "../../environments/environment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const checkAuth = function checkAuth() {
  return AuthResources.checkAuthSession();
};

class RegisterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      verifyAccount: false,
      user: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        invitation_code: null,
        avatar: null,
        timezone: null,
        redirect_url: `${app}/confirmation-token`,
        showPassword: false,
      },
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendData = this.sendData.bind(this);
    this.sendEmailVerification = this.sendEmailVerification.bind(this);
    this.deleteAvatar = this.deleteAvatar.bind(this);
  }

  componentDidMount() {
    if (checkAuth().isLoggedIn) {
      location.href = "/";
    }
  }

  deleteAvatar() {
    this.setState((prevState) => ({
      user: { ...prevState.user, avatar: null },
    }));
  }

  showPassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: showPassword ? false : true });
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = target != "avatar" ? evt.target.value : evt.target.files[0];
    this.setState((prevState) => ({
      user: { ...prevState.user, [target]: value },
    }));
    if (target == "avatar") {
      var file = evt.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = async () => {
          this.setState((prevState) => ({
            user: { ...prevState.user, avatar: reader.result },
          }));
        };
      }
    }
  }

  sendEmailVerification = () => {
    const { user } = this.state;

    if (!user.email) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your email",
      });
    } else {
      let userData = {};
      userData.user = { email: user.email };
      AuthResources.resendEmailVerify(userData)
        .then((response) => {
          response.json().then((parsedJson) => {
            if (parsedJson.errors && parsedJson.errors.length > 0) {
              this.setState({
                snackbarOpen: true,
                snackbarMessage: parsedJson.errors[0].toUpperCase(),
              });
            } else {
              this.setState({
                snackbarOpen: true,
                snackbarMessage: "Email sent successfully",
              });
            }
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  sendData() {
    const { user } = this.state;
    if (!user.first_name) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your first name",
      });
    } else {
      if (!user.last_name) {
        const snackbarMessage = "Enter your last name";
        this.setState({
          snackbarOpen: true,
          snackbarMessage,
        });
      } else {
        if (!user.email) {
          const snackbarMessage = "Enter your email";
          this.setState({
            snackbarOpen: true,
            snackbarMessage,
          });
        } else {
          if (!user.password) {
            const snackbarMessage = "Enter your password";
            this.setState({
              snackbarOpen: true,
              snackbarMessage,
            });
          } else {
            if (!user.invitation_code) {
              const snackbarMessage = "Enter a invitation code";
              this.setState({
                snackbarOpen: true,
                snackbarMessage,
              });
            } else {
              this.setState({ isLoading: true });
              let userData = {};
              let timeZone = momentTimeZone.tz.guess();
              user.timezone = timeZone;
              userData.user = user;
              AuthResources.register(userData).then((response) => {
                response.json().then((parsedJson) => {
                  if (parsedJson.errors) {
                    const firstError = `${Object.entries(
                      parsedJson.errors
                    )[0][0].replace("_", " ")} ${
                      Object.entries(parsedJson.errors)[0][1]
                    }`;
                    this.setState({
                      snackbarOpen: true,
                      snackbarMessage: firstError.toUpperCase(),
                      isLoading: false,
                    });
                  } else {
                    // this.setState({ verifyAccount: true });
                    this.login(user.email, user.password);
                  }
                });
              });
            }
          }
        }
      }
    }
  }

  login(email, password) {
    AuthResources.login(email, password)
      .then(({ data, headers }) => {
        const { "access-token": accessToken, uid, client } = headers;
        AuthResources.saveAuthData(accessToken, data, uid, client);
        this.props.history.push("/");
      })
      .catch(() => {
        this.props.history.push("/");
      });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const {
      snackbarOpen,
      snackbarMessage,
      isLoading,
      user,
      verifyAccount,
      showPassword,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        <div
          style={{
            backgroundImage: `url("loginbg-img.jpg")`,
            height: "900px",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <HeaderBarComponent
            showLogo
            showBack
            showBackAction={() => this.props.history.push(`/`)}
            title={"Prayr App"}
          />

          {!verifyAccount && (
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                marginTop: "8vh",
                position: "absolute",
              }}
            >
              <Grid item xs={10}>
                <Typography
                  variant="h5"
                  className="fontLatoRegular"
                  style={{ color: "#ffffff", textAlign: "center" }}
                >
                  CREATE AN ACCOUNT
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#ffffff",
                    textAlign: "center",
                    marginTop: "2vh",
                    fontSize: "0.9rem",
                    paddingRight: "2vh",
                    paddingLeft: "2vh",
                  }}
                >
                  We believe in the power of an image to create a connection
                  within prayer. Please select the image you would like used
                  throughtout the app
                </Typography>
              </Grid>

              <Grid>
                <UploadImageComponent
                  avatarChange={this.handleChange}
                  avatar={user.avatar}
                  paddingTopLabel={"1vh"}
                  borderColor={"#828282bd"}
                  deleteAvatar={this.deleteAvatar}
                  backgroundColor={"#ffffff00"}
                  width={"65vw"}
                  borderSize={"0.25vh"}
                  color={"#ffffff"}
                  label={"SELECT A PHOTO FROM CAMERA ROLL"}
                />
              </Grid>

              <Grid
                item
                xs={10}
                style={{
                  marginTop: "2vh",
                }}
              >
                <TextField
                  label="FIRST NAME"
                  style={{ width: "100%" }}
                  name="first_name"
                  id="outlined-start-adornment-1"
                  onChange={this.handleChange}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#ffffff !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#ffffff",
                      marginLeft: "1vh",
                      top: "7%",
                      fontSize: "1.5vh",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "1vh",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff00 !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#ffffff",
                      backgroundColor: "#afafaf57",
                      borderRadius: "4vh",
                    },
                  }}
                />
                <TextField
                  label="LAST NAME"
                  style={{ width: "100%", marginTop: "2vh" }}
                  name="last_name"
                  id="outlined-start-adornment-2"
                  onChange={this.handleChange}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#ffffff !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#ffffff",
                      marginLeft: "1vh",
                      top: "7%",
                      fontSize: "1.5vh",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "1vh",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff00 !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#ffffff",
                      backgroundColor: "#afafaf57",
                      borderRadius: "4vh",
                    },
                  }}
                />
                <TextField
                  label="YOUR EMAIL"
                  style={{ width: "100%", marginTop: "2vh" }}
                  name="email"
                  id="outlined-start-adornment-3"
                  onChange={this.handleChange}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#ffffff !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#ffffff",
                      marginLeft: "1vh",
                      top: "7%",
                      fontSize: "1.5vh",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "1vh",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff00 !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#ffffff",
                      backgroundColor: "#afafaf57",
                      borderRadius: "4vh",
                    },
                  }}
                />
                <TextField
                  label="PASSWORD"
                  style={{ width: "100%", marginTop: "2vh" }}
                  id="outlined-start-adornment-4"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={this.handleChange}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#ffffff !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#ffffff",
                      marginLeft: "1vh",
                      top: "7%",
                      fontSize: "1.5vh",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "1vh",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff00 !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#ffffff",
                      backgroundColor: "#afafaf57",
                      borderRadius: "4vh",
                    },
                    endAdornment: showPassword ? (
                      <VisibilityIcon
                        onClick={() => this.showPassword()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityIcon>
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => this.showPassword()}
                        position="end"
                        style={{ marginRight: "1vh" }}
                      ></VisibilityOffIcon>
                    ),
                  }}
                />
                <TextField
                  label="INVITATION CODE"
                  style={{ width: "100%", marginTop: "2vh" }}
                  id="outlined-start-adornment-5"
                  name="invitation_code"
                  onChange={this.handleChange}
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                      {
                        display: "none",
                        color: "#ffffff !important",
                      },
                    "& .MuiInputLabel-root": {
                      color: "#ffffff",
                      marginLeft: "1vh",
                      top: "7%",
                      fontSize: "1.5vh",
                    },
                    "& .MuiOutlinedInput-root": {
                      paddingLeft: "1vh",
                    },
                    "& .MuiOutlinedInput-root fieldset": {
                      borderColor: "#ffffff00 !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#ffffff",
                      backgroundColor: "#afafaf57",
                      borderRadius: "4vh",
                    },
                  }}
                />
              </Grid>

              <ButtonComponent
                name={"CREATE YOUR ACCOUNT"}
                action={this.sendData}
                marginTop={"2vh"}
              />
            </Grid>
          )}

          {verifyAccount && (
            <VerifyAccountComponent
              user={user}
              onAction={this.sendEmailVerification}
            />
          )}
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default RegisterComponent;
