import { api } from "../environments/environment";
import HttpService from "./http";
import ResourcesUtils from "./utils";

export default {
  async getAllLockScreens() {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/lock_screens`, {
        headers,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  },
  async getPhoto(id) {
    try {
      const headers = await ResourcesUtils.buildHeaders();
      const response = await HttpService.get(`${api}/photos/${id}`, {
        headers,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  },
};
