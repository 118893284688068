import Dexie from "dexie";

export default class dbResources {
  static db = null;

  static getInstance() {
    dbResources.refreshDb();

    if (!this.db) {
      this.db = new Dexie("db");
      this.db.version(1).stores({
        settings: "id++, settingKey, settingValue",
      });
    }

    return this.db;
  }

  static async refreshDb() {
    const urlHash = window.location.hash;
    if (this.db && urlHash == "#refreshdb") {
      window.location = window.location.href.replace("#refreshdb", "#");
      //let settings = await this.db.settings.toArray();
    }
  }
}
