import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import OurPrayersResources from "../../../resources/our-prayers";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FormComponent from "../shared/form";
import ConfirmationComponent from "../../shared/confirmation/confirmation";

class EditGroupComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      id,
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      openCloseGroupModalConfirmation: false,
      group: {
        name: null,
        description: null,
        logo: null,
        photo: null,
        background_color: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeGradient = this.handleChangeGradient.bind(this);
    this.update = this.update.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.deleteLogo = this.deleteLogo.bind(this);
    this.openCloseGroupModal = this.openCloseGroupModal.bind(this);
    this.closeGroup = this.closeGroup.bind(this);
  }

  update() {
    const { group } = this.state;
    if (!group.name) {
      const snackbarMessage = "Enter a name group";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    } else {
      var ourGroupData = {};
      ourGroupData.group = group;
      this.setState({ isLoading: true });
      OurPrayersResources.updateGroup(ourGroupData)
        .then(() => {
          this.props.history.push(`/our-prayers/${group.id}/view-group`, {
            message: "Group has been updated successfully",
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  componentDidMount() {
    this.loadGroup();
  }

  loadGroup() {
    const { id } = this.state;
    this.setState({
      isLoading: true,
    });
    OurPrayersResources.getGroup(id).then((data) => {
      if (data.owner) {
        this.setState({
          group: data,
          isLoading: false,
        });
      } else {
        this.props.history.push(`/our-prayers`);
      }
    });
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = target != "photo" ? evt.target.value : evt.target.files[0];
    this.setState((prevState) => ({
      group: { ...prevState.group, [target]: value },
    }));
    if (target == "photo" || target == "logo") {
      var file = evt.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          if (target == "photo") {
            this.setState((prevState) => ({
              group: { ...prevState.group, photo: reader.result },
            }));
          }
          if (target == "logo") {
            this.setState((prevState) => ({
              group: { ...prevState.group, logo: reader.result },
            }));
          }
        };
      }
    }
  }

  handleChangeGradient(gradientName) {
    this.setState((prevState) => ({
      group: {
        ...prevState.group,
        background_color: gradientName,
      },
    }));
    this.deletePhoto();
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  deletePhoto() {
    this.setState((prevState) => ({
      group: { ...prevState.group, photo: "null", photo_url: null },
    }));
  }

  deleteLogo() {
    this.setState((prevState) => ({
      group: { ...prevState.group, logo: "null", logo_url: null },
    }));
  }

  delete() {
    const { group } = this.state;
    this.setState({
      isLoading: true,
      isUpdating: true,
    });
    OurPrayersResources.deleteGroup(group.id)
      .then(() => {
        this.props.history.push(`/our-prayers`);
      })
      .catch(() => {
        this.setState({ isLoading: false, isUpdating: false });
      });
  }

  openCloseGroupModal() {
    this.setState({ openCloseGroupModalConfirmation: true });
  }

  closeGroup() {
    const { group } = this.state;
    OurPrayersResources.closeGroup(group.id).then((data) => {
      data.json().then((parsedJson) => {
        if (parsedJson.errors) {
          let firstError = "";
          if (typeof parsedJson.errors == "object") {
            firstError = `${Object.entries(parsedJson.errors)[0][0].replace(
              "_",
              " "
            )} ${Object.entries(parsedJson.errors)[0][1]}`;
          } else if (typeof parsedJson.errors == "string") {
            firstError = parsedJson.errors;
          }
          this.setState({
            snackbarOpen: true,
            snackbarMessage: firstError,
          });
        } else {
          this.props.history.push(`/our-prayers`, {
            message: parsedJson.message,
          });
        }
      });
    });
  }

  renderCloseGroupModalConfirmation() {
    const { openCloseGroupModalConfirmation } = this.state;
    return (
      <ConfirmationComponent
        open={openCloseGroupModalConfirmation}
        handleAccept={() => {
          this.setState({ openCloseGroupModalConfirmation: false });
          this.closeGroup();
        }}
        handleCancel={() => {
          this.setState({ openCloseGroupModalConfirmation: false });
        }}
        title="Confirm"
        message="Are you sure you want to close this group?"
        AcceptButtonText="Yes"
        CancelButtonText="No"
      ></ConfirmationComponent>
    );
  }

  render() {
    const { group, snackbarMessage, snackbarOpen, isLoading } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() =>
                this.props.history.push(`/our-prayers/${group.id}/view-group`)
              }
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <Grid container direction="column" alignItems="center">
              <FormComponent
                title={"Edit Group"}
                description={`Bring together friends, family, colleagues and community members within a group.`}
                descriptionTwo={`Groups can chat together, plan events, stay up to date and have a central place for organized prayer.`}
                group={group}
                createOrUpdate={this.update}
                deletePhoto={this.deletePhoto}
                deleteLogo={this.deleteLogo}
                deleteGroup={this.openCloseGroupModal}
                deleteText={"CLOSE GROUP"}
                buttonSubmitText={"SAVE"}
                onChange={this.handleChange}
                onChangeGradient={this.handleChangeGradient}
                closeForm={() => {
                  this.props.history.push(`/our-prayers`);
                }}
              />
            </Grid>
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
        {this.renderCloseGroupModalConfirmation()}
      </div>
    );
  }
}

export default EditGroupComponent;
