import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import OurPrayersResources from "../../../resources/our-prayers";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FormComponent from "../shared/form";

class AddGroupComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      group: {
        name: null,
        description: null,
        logo: null,
        photo: null,
        background_color: "Grad_1.png",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeGradient = this.handleChangeGradient.bind(this);
    this.create = this.create.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.deleteLogo = this.deleteLogo.bind(this);
  }

  create() {
    const { group } = this.state;

    if (!group.name) {
      const snackbarMessage = "Enter a name group";
      this.setState({
        snackbarOpen: true,
        snackbarMessage,
      });
    } else {
      var ourGroupData = {};
      ourGroupData.group = group;
      this.setState({ isLoading: true });
      OurPrayersResources.createGroup(ourGroupData)
        .then(() => {
          this.props.history.push(`/our-prayers`);
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  handleChange(evt) {
    const target = evt.target.name;
    const value = target != "photo" ? evt.target.value : evt.target.files[0];
    this.setState((prevState) => ({
      group: { ...prevState.group, [target]: value },
    }));
    if (target == "photo" || target == "logo") {
      var file = evt.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          if (target == "photo") {
            this.setState((prevState) => ({
              group: { ...prevState.group, photo: reader.result },
            }));
          }
          if (target == "logo") {
            this.setState((prevState) => ({
              group: { ...prevState.group, logo: reader.result },
            }));
          }
        };
      }
    }
  }

  handleChangeGradient(gradientName) {
    this.setState((prevState) => ({
      group: {
        ...prevState.group,
        background_color: gradientName,
      },
    }));
    this.deletePhoto();
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  deletePhoto() {
    this.setState((prevState) => ({
      group: { ...prevState.group, photo: null },
    }));
  }

  deleteLogo() {
    this.setState((prevState) => ({
      group: { ...prevState.group, logo: null },
    }));
  }

  render() {
    const { group, snackbarMessage, snackbarOpen, isLoading } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/our-prayers`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <Grid container direction="column" alignItems="center">
              <FormComponent
                title={"Create New Group"}
                description={`Bring together friends, family, colleagues and community members within a group.`}
                descriptionTwo={`Groups can chat together, plan events, stay up to date and have a central place for organized prayer.`}
                group={group}
                createOrUpdate={this.create}
                deletePhoto={this.deletePhoto}
                deleteLogo={this.deleteLogo}
                onChange={this.handleChange}
                onChangeGradient={this.handleChangeGradient}
                closeForm={() => {
                  this.props.history.push(`/our-prayers`);
                }}
              />
            </Grid>
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
      </div>
    );
  }
}

export default AddGroupComponent;
