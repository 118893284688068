import Typography from "@mui/material/Typography";
import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Paper } from "@mui/material";
import LocalStorageResources from "../../../resources/local-storage";
import OurPrayersResources from "../../../resources/our-prayers";
import Snackbar from "@material-ui/core/Snackbar";
import ConfirmationComponent from "../../shared/confirmation/confirmation";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import PusherChannelSubscriber from "../../../resources/pusher-channel-subscriber";
import BottomNavigationComponent from "../../shared/components/bottom-navigation";
import moment from "moment";
import MyPrayersResources from "../../../resources/my-prayers";
import MyProfileResources from "../../../resources/my-profile";
import TitleBackgroundComponent from "../../shared/components/title-background";
import Button from "@mui/material/Button";
import ButtonComponent from "../../shared/components/button";

class ViewGroupComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      snackbarOpen: false,
      snackbarMessage: null,
      isLoading: false,
      openDeclineInvitationGroupModalConfirmation: false,
      openLeaveGroupModalConfirmation: false,
      id,
      group: {},
      groupPrayers: [],
      invitationsGroup: [],
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      unreadMessages: 0,
      onSwipeProgress: false,
      userEmailSettings: [],
      controlShowPrayNowModals: {},
    };
    this.openLeaveGroupModal = this.openLeaveGroupModal.bind(this);
    this.handleNewMessage = this.handleNewMessage.bind(this);
    this.leaveGroup = this.leaveGroup.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  handleNewMessage() {
    this.setState((prevState) => {
      return { unreadMessages: prevState.unreadMessages + 1 };
    });
  }

  loadPusherNewMessages(pusher_channel) {
    PusherChannelSubscriber.subscribeChannelMessages(
      this.handleNewMessage,
      null,
      null,
      pusher_channel
    );
  }

  leaveGroup = () => {
    const { group } = this.state;
    OurPrayersResources.leaveGroup(group.invitation_id).then((data) => {
      data.json().then((parsedJson) => {
        if (parsedJson.errors) {
          let firstError = "";
          if (typeof parsedJson.errors == "object") {
            firstError = `${Object.entries(parsedJson.errors)[0][0].replace(
              "_",
              " "
            )} ${Object.entries(parsedJson.errors)[0][1]}`;
          } else if (typeof parsedJson.errors == "string") {
            firstError = parsedJson.errors;
          }
          this.setState({
            snackbarOpen: true,
            snackbarMessage: firstError,
          });
        } else {
          this.props.history.push(`/our-prayers`, {
            message: parsedJson.message,
          });
        }
      });
    });
  };

  trailingActions = (data, userData) => (
    <TrailingActions>
      <SwipeAction onClick={() => {}}>
        <div
          style={{
            backgroundColor: "#F07522",
            position: "relative",
            width: "10vh",
            height: "30vh",
            borderRadius: "1vh",
          }}
        >
          {data.group_id && userData.id != data.user_id && (
            <Paper
              onClick={() =>
                this.props.history.push(`/my-prayers/${data.id}/view-person`, {
                  group: data,
                  backUrl: `/our-prayers/${data.group_id}/view-group`,
                })
              }
              sx={{
                boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
              }}
              style={{
                backgroundColor: "#fff0",
                top: "9vh",
                left: "60%",
                position: "absolute",
                textAlign: "center",
                transform: "translate(-50%, -50%)",
                width: "5.5vh",
                height: "5.2vh",
                backgroundImage: `url("/prayer-encourage.png")`,
                backgroundSize: "cover",
              }}
            />
          )}

          {data.group_id && userData.id == data.user_id && (
            <Paper
              onClick={() =>
                this.props.history.push(`/my-prayers/${data.id}/edit-person`, {
                  group: data,
                  backUrl: `/our-prayers/${data.group_id}/view-group`,
                })
              }
              sx={{
                boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
              }}
              style={{
                backgroundColor: "#fff0",
                bottom: "4vh",
                left: "60%",
                position: "absolute",
                textAlign: "center",
                transform: "translate(-50%, -50%)",
                width: "5.5vh",
                height: "5.4vh",
                backgroundImage: `url("/prayer-edit.png")`,
                backgroundSize: "cover",
              }}
            />
          )}
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  loadGroupPrayers() {
    const { id, message, controlShowPrayNowModals } = this.state;
    OurPrayersResources.getAllGroupPrayers(id).then((data) => {
      const currentDate = moment().format("DD/MM/yyyy");
      data.map((d) => {
        controlShowPrayNowModals[`showPrayNowPrayer${data.id}`] = false;
        const lastPrayDate = moment
          .utc(d.last_prayer_date)
          .local()
          .format("DD/MM/yyyy");
        d.prayToday =
          d.last_prayer_date == null || currentDate != lastPrayDate
            ? true
            : false;
      });
      const filterPrayToday = data
        .filter((f) => f.prayToday)
        .sort((a, b) => (a.id < b.id ? 1 : -1));
      const filterDontPrayToday = data
        .filter((f) => !f.prayToday)
        .sort(
          (a, b) =>
            moment.utc(a.last_prayer_date).local() -
            moment.utc(b.last_prayer_date).local()
        );
      const dataFilter = filterPrayToday.concat(filterDontPrayToday);
      this.setState({
        groupPrayers: dataFilter,
      });
      MyProfileResources.get().then((userEmailSettings) => {
        this.setState({
          userEmailSettings,
        });
      });
      OurPrayersResources.getInvitationsGroup(id).then((data) => {
        this.setState({
          invitationsGroup: data,
          isLoading: false,
        });
        if (message) {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: message,
            message: null,
          });
        }
      });
    });
  }

  loadGroup() {
    const { id } = this.state;
    this.setState({
      isLoading: true,
    });
    OurPrayersResources.getGroup(id).then((data) => {
      this.setState({
        group: data,
        unreadMessages: data.unread_messages,
      });
      if (!(data.invitation_status && data.invitation_status == "pending")) {
        this.loadGroupPrayers();
        this.loadPusherNewMessages(data.pusher_channel);
      } else {
        location.href = "/our-prayers";
      }
    });
  }

  renderModalPrayNow(prayer, listView = false) {
    const { groupPrayers, userEmailSettings } = this.state;
    return (
      <Paper
        style={{
          position: "absolute",
          width: !listView ? "97%" : "92%",
          margin: "auto",
          bottom: !listView && "6px",
          left: 0,
          right: 0,
          height: !listView ? "auto" : "12.5vh",
          borderRadius: "2vh",
          zIndex: "2",
          background:
            "linear-gradient(rgb(255, 178, 122) 0%, rgb(245, 138, 66) 46%, rgb(241, 124, 44) 92%)",
        }}
        onClick={() => {
          groupPrayers.map((d) => {
            this.setState((prevState) => ({
              controlShowPrayNowModals: {
                ...prevState.controlShowPrayNowModals,
                [`showPrayNowPrayer${d.id}`]: false,
              },
            }));
          });
          this.loadGroupPrayers();
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
          }}
          style={{
            margin: "1vh auto 0.5vh",
            backgroundColor: "#fff0",
            backgroundImage: `url("/CheckMark_Icon.png")`,
            backgroundSize: "cover",
            zIndex: "2",
            width: "33px",
            height: "33px",
            position: listView && "absolute",
            right: listView && "1.5vh",
            top: listView && "3vh",
          }}
        />
        <Typography
          style={{
            textAlign: !listView && "center",
            marginTop: listView && "2vh",
            marginLeft: listView && "2vh",
            fontSize: "20px",
            color: "#ffffff",
            fontWeight: "bold",
          }}
        >
          Prayer Marked Complete!
        </Typography>
        {userEmailSettings.automatic_message_sending && (
          <Button
            style={{
              color: "#888a88",
              borderRadius: "3vh",
              width: "218px",
              minWidth: "218px",
              height: "35px",
              minHeight: "35px",
              fontSize: "0.9rem",
              paddingTop: "0.7vh",
              textTransform: "capitalize",
              backgroundColor: "#ffffff",
              marginRight: !listView && "auto",
              marginLeft: !listView ? "auto" : "2vh",
              marginBottom: !listView && "1vh",
              display: "block",
              marginTop: "1vh",
            }}
            size="large"
            variant="contained"
            onClick={() => this.prayNow(prayer, true)}
          >
            SEND ENCOURAGEMENT
          </Button>
        )}
        {!userEmailSettings.automatic_message_sending && (
          <>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#ffffff",
                paddingLeft: "5vh",
                paddingRight: "5vh",
                paddingBottom: "2vh",
              }}
            >
              You can automatically send a pre-set message to people that you
              pray for.
            </Typography>
            <Button
              style={{
                color: "#888a88",
                borderRadius: "3vh",
                width: "218px",
                minWidth: "218px",
                height: "35px",
                minHeight: "35px",
                fontSize: "0.9rem",
                paddingTop: "0.7vh",
                textTransform: "capitalize",
                backgroundColor: "#ffffff",
                marginRight: !listView && "auto",
                marginLeft: !listView ? "auto" : "2vh",
                marginBottom: !listView && "1vh",
                display: "block",
              }}
              size="large"
              variant="contained"
              onClick={() =>
                this.props.history.push(`/my-profile/user-email-settings`)
              }
            >
              SET UP MESSAGING
            </Button>
          </>
        )}
      </Paper>
    );
  }

  prayNowOnly(prayer) {
    MyPrayersResources.prayNow(prayer).then(() => {
      this.loadGroupPrayers();
    });
  }

  prayNow(prayer, sendEncouragement = false) {
    const { userEmailSettings } = this.state;
    if (!sendEncouragement) {
      MyPrayersResources.prayNow(prayer).then(() => {
        this.setState((prevState) => ({
          controlShowPrayNowModals: {
            ...prevState.controlShowPrayNowModals,
            [`showPrayNowPrayer${prayer.id}`]: true,
          },
        }));
      });
    } else {
      if (userEmailSettings.automatic_message_sending) {
        let sendData = {};
        let payload = {
          subject: userEmailSettings.default_subject,
          content: userEmailSettings.default_message,
          prayer_id: prayer.id,
          message_type: "pray_now",
        };
        (payload.to_user_id = prayer.user_id), (sendData.in_app = payload);
        MyPrayersResources.sendMessageInApp(sendData);
      } else {
        MyPrayersResources.prayNow(prayer);
      }
    }
  }

  openLeaveGroupModal() {
    this.setState({ openLeaveGroupModalConfirmation: true });
  }

  renderLeaveGroupModalConfirmation() {
    const { openLeaveGroupModalConfirmation } = this.state;
    return (
      <ConfirmationComponent
        open={openLeaveGroupModalConfirmation}
        handleAccept={() => {
          this.setState({ openLeaveGroupModalConfirmation: false });
          this.leaveGroup();
        }}
        handleCancel={() => {
          this.setState({ openLeaveGroupModalConfirmation: false });
        }}
        title="Confirm"
        message="Are you sure you want to leave this group?"
        AcceptButtonText="Yes"
        CancelButtonText="No"
      ></ConfirmationComponent>
    );
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  renderBottomNavigation(group) {
    if (!group.owner) {
      return (
        <BottomNavigationComponent
          iconOne={`url("/footer-bar/Icon_LeaveGroup.png")`}
          actionOne={() => this.openLeaveGroupModal()}
        />
      );
    } else {
      return (
        <BottomNavigationComponent
          iconOne={`url("/footer-bar/Icon_ManageGroup.png")`}
          actionOne={() =>
            this.props.history.push(
              `/our-prayers/${group.id}/invitations-group`
            )
          }
          iconTwo={`url("/footer-bar/Icon_EditGroup.png")`}
          actionTwo={() =>
            this.props.history.push(`/our-prayers/${group.id}/edit-group`)
          }
        />
      );
    }
  }

  editPrayer(id) {
    const { group } = this.state;
    this.props.history.push(`/my-prayers/${id}/edit-person`, {
      backUrl: `/my-prayers/${id}/view-details`,
      fromGroup: group,
    });
  }

  renderEditPrayerRequest() {
    const { groupPrayers } = this.state;
    let prayerEdit = null;
    if (groupPrayers.length > 0) {
      const userData = LocalStorageResources.getObject("authData").data;
      prayerEdit = groupPrayers.filter((f) => f.user_id == userData.id)[0];
    }
    if (prayerEdit) {
      return (
        <ButtonComponent
          name={
            !prayerEdit.description
              ? "ADD MY PRAYER REQUEST"
              : "EDIT MY PRAYER REQUEST"
          }
          marginBottom={"20px"}
          action={() => this.editPrayer(prayerEdit.id)}
        />
      );
    }
  }

  render() {
    const {
      group,
      isLoading,
      groupPrayers,
      snackbarOpen,
      snackbarMessage,
      unreadMessages,
      onSwipeProgress,
      controlShowPrayNowModals,
    } = this.state;

    const userData = LocalStorageResources.getObject("authData").data;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              backgroundColor: "#f5f5f5",
              height: "100%",
            }}
          >
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/our-prayers`)}
              showMenu
              showNotifications
              title={"Prayr App"}
            />

            <TitleBackgroundComponent title={"Our Prayers"} />

            <div
              style={{
                backgroundImage: group.photo_url
                  ? `url("` + group.photo_url + `")`
                  : `url("/gradient-images/${group.background_color}")`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "30vh",
                position: "relative",
              }}
            >
              {!(
                group.invitation_status && group.invitation_status == "pending"
              ) && (
                <div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-2.5vh",
                      right: "1vh",
                      zIndex: "3",
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#ffffff",
                      borderRadius: "30px",
                    }}
                  >
                    {unreadMessages > 0 && (
                      <Paper
                        onClick={() =>
                          this.props.history.push(
                            `/our-prayers/${group.id}/messages-group`
                          )
                        }
                        style={{
                          position: "absolute",
                          top: "-6px",
                          color: "#fff",
                          right: "-6px",
                          zIndex: "3",
                          width: "auto",
                          minWidth: "16px",
                          paddingBottom: "5px",
                          paddingRight: "4px",
                          paddingLeft: "4px",
                          textAlign: "center",
                          height: "16px",
                          backgroundColor: "#ff0000",
                          borderRadius: "30px",
                        }}
                      >
                        {unreadMessages}
                      </Paper>
                    )}
                    <Paper
                      style={{
                        backgroundImage: `url("/Chat_Icon_Orange.png")`,
                        width: "27px",
                        backgroundSize: "cover",
                        marginLeft: "12px",
                        marginTop: "10px",
                        height: "31px",
                        backgroundColor: "#ffffff00",
                        boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
                      }}
                      onClick={() =>
                        this.props.history.push(
                          `/our-prayers/${group.id}/messages-group`
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                backgroundColor: "#023046",
                position: "relative",
                height: "auto",
                marginBottom: "2vh",
              }}
            >
              <Grid item xs={10} style={{ width: "90%" }}>
                <Typography
                  variant="h5"
                  style={{
                    color: "#ffffff",
                    padding: "2vh 0 2vh",
                  }}
                >
                  {group.name}
                </Typography>
                {/* <Typography
                  style={{
                    fontSize: "18px",
                    color: "#ffffff",
                    paddingTop: "1vh",
                    paddingBottom: "3vh ",
                  }}
                >
                  {group.description}
                </Typography> */}
              </Grid>
            </Grid>

            {groupPrayers.length > 0 && (
              <div style={{ marginBottom: "10vh" }}>
                {this.renderEditPrayerRequest()}
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{
                    marginBottom: "10vh",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <Grid item xs={11} style={{ marginBottom: "15vh" }}>
                    <SwipeableList threshold={0.5} type={ListType.IOS}>
                      {groupPrayers.map((item) => (
                        <SwipeableListItem
                          style={{ width: "40vh" }}
                          key={item.id}
                          trailingActions={this.trailingActions(item, userData)}
                          onSwipeStart={() => {
                            this.setState({
                              onSwipeProgress: true,
                            });
                          }}
                          onSwipeProgress={() => {
                            this.setState({
                              onSwipeProgress: true,
                            });
                          }}
                        >
                          <div
                            key={"load" + item.id}
                            style={{
                              marginBottom: "25px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                paddingLeft: "3px",
                              }}
                            >
                              <div
                                className={"prayer-div" + item.id}
                                style={{ position: "relative" }}
                              >
                                {group.user_id == item.user_id && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      color: "#fff",
                                      fontWeight: "bolder",
                                      zIndex: "3",
                                      width: "30px",
                                      minWidth: "30px",
                                      minHeight: "18px",
                                      height: "18px",
                                      paddingBottom: "6px",
                                      paddingTop: "6px",
                                      textAlign: "center",
                                      backgroundColor: "#f07522",
                                      borderRadius: "14px 0 14px 0",
                                    }}
                                  >
                                    <Paper
                                      style={{
                                        backgroundImage: `url("/Owner_Icon_White.png")`,
                                        width: "13px",
                                        backgroundSize: "cover",
                                        marginLeft: "8px",
                                        marginTop: "1px",
                                        height: "15px",
                                        backgroundColor: "#ffffff00",
                                        boxShadow:
                                          "0px 0px 0px 0px rgb(0 0 0 / 0%)",
                                      }}
                                    />
                                  </div>
                                )}

                                <Paper
                                  key={"div-prayer-" + item.id}
                                  sx={{
                                    ".prayer-options:hover": {
                                      display: "none",
                                      color: "#ffffff !important",
                                    },
                                  }}
                                  onClick={() => {
                                    if (!onSwipeProgress) {
                                      this.props.history.push(
                                        `/my-prayers/${item.id}/view-details`,
                                        {
                                          backUrl: `/our-prayers/${group.id}/view-group`,
                                          fromGroup: group,
                                        }
                                      );
                                    } else {
                                      this.setState({
                                        onSwipeProgress: false,
                                      });
                                    }
                                  }}
                                  onTouchEnd={() => {
                                    this.setState({
                                      onSwipeProgress: false,
                                    });
                                  }}
                                  style={{
                                    width: "84vw",
                                    maxWidth: "435px",
                                    height: "30vh",
                                    backgroundImage: item.avatar_url
                                      ? `url("` + item.avatar_url + `")`
                                      : `url("/gradient-images/${item.background_color}")`,
                                    borderRadius: "2vh",
                                    position: "relative",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                  }}
                                />
                                {userData.id != item.user_id && (
                                  <>
                                    <Paper
                                      onClick={() => {
                                        this.props.history.push(
                                          `/my-prayers/${item.id}/view-person`
                                        );
                                      }}
                                      sx={{
                                        boxShadow:
                                          "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                                      }}
                                      style={{
                                        position: "absolute",
                                        bottom: "1vh",
                                        backgroundColor: "#fff0",
                                        backgroundImage: `url("/send-encouragement.png")`,
                                        backgroundSize: "cover",
                                        right: "8.5vh",
                                        zIndex: "2",
                                        width: "33px",
                                        height: "37px",
                                      }}
                                    />
                                    <Paper
                                      onClick={() => this.prayNowOnly(item)}
                                      sx={{
                                        boxShadow:
                                          "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                                      }}
                                      style={{
                                        position: "absolute",
                                        bottom: "1.4vh",
                                        backgroundColor: "#fff0",
                                        backgroundImage: item.prayToday
                                          ? `url("/pray-now-icon-new.png")`
                                          : `url("/pray-now-icon-checked-new.png")`,
                                        backgroundSize: "cover",
                                        right: "2vh",
                                        zIndex: 2,
                                        width: "33px",
                                        height: "33px",
                                      }}
                                    />
                                  </>
                                )}
                                {item.avatar_url && (
                                  <Paper
                                    style={{
                                      backgroundColor: "#063D59",
                                      width: "100%",
                                      height: "8vh",
                                      position: "absolute",
                                      borderRadius: "0 0vh 2vh 2vh",
                                      bottom: 0,
                                      opacity: 0.5,
                                    }}
                                    onClick={() => {
                                      if (!onSwipeProgress) {
                                        this.props.history.push(
                                          `/my-prayers/${item.id}/view-details/`
                                        );
                                      } else {
                                        this.setState({
                                          onSwipeProgress: false,
                                        });
                                      }
                                    }}
                                  ></Paper>
                                )}
                                <Typography
                                  onClick={() => {
                                    if (!onSwipeProgress) {
                                      this.props.history.push(
                                        `/my-prayers/${item.id}/view-details`,
                                        {
                                          backUrl: `/our-prayers/${group.id}/view-group`,
                                          fromGroup: group,
                                        }
                                      );
                                    } else {
                                      this.setState({
                                        onSwipeProgress: false,
                                      });
                                    }
                                  }}
                                  onTouchEnd={() => {
                                    this.setState({
                                      onSwipeProgress: false,
                                    });
                                  }}
                                  variant="h6"
                                  style={{
                                    position: "absolute",
                                    bottom: item.avatar_url
                                      ? item.description
                                        ? "3.8vh"
                                        : "1.8vh"
                                      : null,
                                    transform: !item.avatar_url
                                      ? item.description
                                        ? "translate(0%, -468%)"
                                        : "translate(0%, -435%)"
                                      : null,
                                    left: !item.avatar_url ? 0 : "2vh",
                                    right: !item.avatar_url ? 0 : null,
                                    zIndex: "1",
                                    fontSize: "2.5vh",
                                    color: "#ffffff",
                                    fontWeight: "bold",
                                    textAlign: !item.avatar_url
                                      ? "center"
                                      : null,
                                    overflow: "hidden",
                                  }}
                                >
                                  {item.name?.length > 20
                                    ? `${item.name.slice(0, 20)}...`
                                    : item.name}
                                </Typography>
                                {item.description && (
                                  <Typography
                                    onClick={() => {
                                      if (!onSwipeProgress) {
                                        this.props.history.push(
                                          `/my-prayers/${item.id}/view-details/`
                                        );
                                      } else {
                                        this.setState({
                                          onSwipeProgress: false,
                                        });
                                      }
                                    }}
                                    onTouchEnd={() => {
                                      this.setState({
                                        onSwipeProgress: false,
                                      });
                                    }}
                                    variant="h6"
                                    style={{
                                      position: "absolute",
                                      bottom: item.avatar_url ? "1vh" : null,
                                      transform: !item.avatar_url
                                        ? "translate(0%, -468%)"
                                        : null,
                                      right: !item.avatar_url ? 0 : null,
                                      left: !item.avatar_url ? 0 : "2vh",
                                      zIndex: "1",
                                      textAlign: !item.avatar_url
                                        ? "center"
                                        : null,
                                      fontSize: "2vh",
                                      color: "#ffffff",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.description?.length > 25
                                      ? `${item.description.slice(0, 25)}...`
                                      : item.description}
                                  </Typography>
                                )}
                                {controlShowPrayNowModals[
                                  `showPrayNowPrayer${item.id}`
                                ] && this.renderModalPrayNow(item)}
                              </div>
                            </div>
                          </div>
                        </SwipeableListItem>
                      ))}
                    </SwipeableList>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        )}
        {this.renderBottomNavigation(group)}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
        {this.renderLeaveGroupModalConfirmation()}
      </div>
    );
  }
}

export default ViewGroupComponent;
