/* eslint-disable jsx-a11y/alt-text */
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import AuthResources from "../../resources/auth";
import FormControl from "@material-ui/core/FormControl";
import Snackbar from "@material-ui/core/Snackbar";
import Validator from "validator";
import "./login.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import VerifyAccountComponent from "../verify-account/verify-account";
import HeaderBarComponent from "../shared/header-bar/header-bar";
import { Paper } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const styles = () => ({
  textField: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "red",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  input: {
    color: "white",
  },
});

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    const queryString = require("query-string");
    const headers = queryString.parse(props.location.search);
    this.state = {
      isLoading: false,
      snackbarOpen: false,
      snackbarMessage: "",
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      email: "",
      password: "",
      verifyAccount: false,
      installableApp: props.installableApp,
      isIosInstalled: props.isIosInstalled,
      handleInstallApp: props.handleInstallApp,
      deviceType: props.deviceType,
      headers,
      showPassword: false,
    };
    this.login = this.login.bind(this);
    this.sendEmailVerification = this.sendEmailVerification.bind(this);
  }

  componentDidMount() {
    const { message } = this.state;
    const searchPath = window.location.search;
    this.verifyConfirmationAccount();
    if (searchPath == "?register=true") {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Account was created successfully",
      });
    }

    if (AuthResources.checkAuthSession().isLoggedIn) {
      this.props.history.push("/");
    } else {
      AuthResources.logOut();
    }

    if (message) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: message,
        message: null,
      });
    }
  }

  verifyConfirmationAccount() {
    const { headers } = this.state;
    if (headers.confirmation_token) {
      AuthResources.validationToken(
        "confirmation_token=" + headers.confirmation_token
      ).then((response) => {
        response.json().then((parsedJson) => {
          if (parsedJson.errors && parsedJson.errors.length > 0) {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: parsedJson.errors[0],
            });
          } else {
            this.setState({
              snackbarOpen: true,
              snackbarMessage: parsedJson.message,
            });
          }
        });
      });
    }
  }

  showPassword() {
    const { showPassword } = this.state;
    this.setState({ showPassword: showPassword ? false : true });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  login(evt) {
    evt.preventDefault();
    const { email, password } = this.state;
    this.setState({ isLoading: true });
    AuthResources.login(email, password)
      .then(({ data, headers }) => {
        const { "access-token": accessToken, uid, client } = headers;
        AuthResources.saveAuthData(accessToken, data, uid, client);
        this.props.history.push("/home");
      })
      .catch((error) => {
        const response = error.response;
        const snackbarMessage =
          response.status === 401 && error.response.data
            ? error.response.data.errors.join(", ")
            : "Error de conexión: Intente nuevamente";
        if (
          snackbarMessage.includes(
            "A confirmation email was sent to your account"
          )
        ) {
          this.setState({
            isLoading: false,
            snackbarOpen: true,
            snackbarMessage:
              "Your email address needs to be verified before you can access PRAYR, please check your email.",
          });
        } else {
          this.setState({
            isLoading: false,
            snackbarOpen: true,
            snackbarMessage,
          });
        }
      });
  }

  sendEmailVerification = () => {
    const { email } = this.state;

    if (!email) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Enter your email",
      });
    } else {
      let userData = {};
      userData.user = { email };
      AuthResources.resendEmailVerify(userData)
        .then((response) => {
          response.json().then((parsedJson) => {
            if (parsedJson.errors && parsedJson.errors.length > 0) {
              this.setState({
                snackbarOpen: true,
                snackbarMessage: parsedJson.errors[0].toUpperCase(),
              });
            } else {
              this.setState({
                snackbarOpen: true,
                snackbarMessage: "Email sent successfully",
              });
            }
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    const {
      email,
      password,
      isLoading,
      snackbarOpen,
      snackbarMessage,
      verifyAccount,
      installableApp,
      isIosInstalled,
      handleInstallApp,
      deviceType,
      showPassword,
    } = this.state;
    const formValid = !Validator.isEmpty(email) && password.length > 0;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {installableApp && deviceType == "android" && (
          <div
            style={{
              height: "150px",
              display: "block",
              background:
                "linear-gradient(322deg, rgb(0, 0, 1) 60%, rgb(22, 22, 22) 60%)",
              position: "relative",
              borderBottom: "6px #5c5c5c solid",
            }}
          >
            <Typography
              style={{
                display: "block",
                textAlign: "center",
                padding: "18px 0 0",
                color: "#929292",
              }}
            >
              For the best experience
            </Typography>
            <Typography
              style={{
                display: "block",
                textAlign: "center",
                color: "#929292",
              }}
            >
              install this site as an app on your device.
            </Typography>
            <Button
              style={{
                textAlign: "center",
                display: "block",
                background:
                  "linear-gradient(180deg, rgba(247,141,70,1) 25%, rgba(240,117,34,1) 43%)",
                color: "#ffffff",
                borderRadius: "3vh",
                margin: "5px auto",
                padding: "2px 38px 0",
                boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
              }}
              size="large"
              variant="contained"
              onClick={handleInstallApp}
            >
              ADD TO HOME SCREEN
            </Button>
            <Paper
              sx={{
                boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
              }}
              style={{
                backgroundColor: "#fff0",
                backgroundImage: `url("favicon-96x96.png")`,
                backgroundSize: "cover",
                zIndex: 2,
                width: "50px",
                height: "50px",
                position: "absolute",
                top: "120px",
                right: 0,
                left: 0,
                margin: "auto",
              }}
            />
          </div>
        )}
        {!isIosInstalled && deviceType == "ios" && (
          <div
            style={{
              height: "150px",
              display: "block",
              background:
                "linear-gradient(322deg, rgb(0, 0, 1) 60%, rgb(22, 22, 22) 60%)",
              position: "relative",
              borderBottom: "6px #5c5c5c solid",
            }}
          >
            <Typography
              style={{
                display: "block",
                textAlign: "center",
                padding: "18px 0 0",
                color: "#929292",
              }}
            >
              For the best experience
            </Typography>
            <Typography
              style={{
                display: "block",
                textAlign: "center",
                color: "#929292",
              }}
            >
              install this site as an app on your device.
            </Typography>
            <Typography
              style={{
                display: "block",
                textAlign: "center",
                padding: "0 25px 0px 25px",
                color: "#929292",
              }}
            >
              Select{" "}
              <img
                alt=""
                src="/ios_install_icon_blue.png"
                style={{ width: "15px", height: "20px", padding: "0 3px" }}
              />{" "}
              then{" "}
              <img
                alt=""
                src="/ios_plus_icon_blue.png"
                style={{ width: "18px", height: "18px", padding: "0 3px" }}
              />{" "}
              <span style={{ color: "#129ef4" }}>Add to Home Screen</span>
            </Typography>
            <Paper
              sx={{
                boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
              }}
              style={{
                backgroundColor: "#fff0",
                backgroundImage: `url("favicon-96x96.png")`,
                backgroundSize: "cover",
                zIndex: 2,
                width: "50px",
                height: "50px",
                position: "absolute",
                top: "120px",
                right: 0,
                left: 0,
                margin: "auto",
              }}
            />
          </div>
        )}
        {!verifyAccount && (
          <div>
            <div
              style={{
                backgroundImage: `url("loginbg-img.jpg")`,
                height: "80vh",
                position: "relative",
                backgroundSize: "cover",
              }}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ position: "absolute", bottom: "50vh" }}
              >
                <img
                  src={`prayr_white.png`}
                  style={{
                    width: "22vh",
                    height: "5vh",
                  }}
                />
                <Grid item xs={10}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "#ffffff",
                      textAlign: "center",
                      marginTop: "2vh",
                      fontSize: "1.1vh",
                      width: "40vh",
                      letterSpacing: "0.4vh",
                    }}
                  >
                    BE UNCEASING AND PERSISTENT IN PRAYER
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                style={{ position: "absolute", bottom: "3vh" }}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10}>
                  <form onSubmit={this.login}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        label="USERNAME"
                        style={{ width: "100%" }}
                        id="input-username"
                        onChange={({ target: { value } }) =>
                          this.setState({ email: value })
                        }
                        sx={{
                          "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                            {
                              display: "none",
                              color: "#ffffff !important",
                            },
                          "& .MuiInputLabel-root": {
                            color: "#ffffff",
                            position: "absolute",
                            top: "50%",
                            marginLeft: "3.5vh",
                          },
                          "& .MuiOutlinedInput-root fieldset": {
                            borderColor: "#ffffff00 !important",
                          },
                        }}
                        className={styles.textField}
                        InputProps={{
                          style: {
                            color: "#ffffff",
                            backgroundColor: "#afafaf57",
                            borderRadius: "4vh",
                          },
                          startAdornment: (
                            <PersonIcon
                              position="start"
                              style={{ marginRight: "1vh" }}
                            ></PersonIcon>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        label="PASSWORD"
                        style={{ width: "100%", marginTop: "2vh" }}
                        id="input-password"
                        type={showPassword ? "text" : "password"}
                        onChange={({ target: { value } }) =>
                          this.setState({ password: value })
                        }
                        sx={{
                          "& .MuiInputLabel-root.Mui-focused, & .MuiFormLabel-filled":
                            {
                              display: "none",
                              color: "#ffffff !important",
                            },
                          "& .MuiInputLabel-root": {
                            color: "#ffffff",
                            position: "absolute",
                            top: "50%",
                            marginLeft: "3.5vh",
                          },
                          "& .MuiOutlinedInput-root fieldset": {
                            borderColor: "#ffffff00 !important",
                          },
                        }}
                        InputProps={{
                          style: {
                            color: "#ffffff",
                            backgroundColor: "#afafaf57",
                            borderRadius: "4vh",
                          },
                          startAdornment: (
                            <LockIcon
                              position="start"
                              style={{ marginRight: "1vh" }}
                            ></LockIcon>
                          ),
                          endAdornment: showPassword ? (
                            <VisibilityIcon
                              onClick={() => this.showPassword()}
                              position="end"
                              style={{ marginRight: "1vh" }}
                            ></VisibilityIcon>
                          ) : (
                            <VisibilityOffIcon
                              onClick={() => this.showPassword()}
                              position="end"
                              style={{ marginRight: "1vh" }}
                            ></VisibilityOffIcon>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl
                      margin="normal"
                      required
                      fullWidth
                      style={{ position: "relative" }}
                    >
                      <Button
                        type="submit"
                        style={{
                          marginTop: "4vh",
                          position: "absolute",
                          backgroundColor: "#F07522",
                          color: "#ffffff",
                          borderRadius: "3vh",
                          width: "284px",
                          minWidth: "284px",
                          height: "48px",
                          minHeight: "48px",
                          fontSize: "13px",
                          paddingTop: "10px",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        size="large"
                        disabled={isLoading || !formValid}
                        variant="contained"
                      >
                        LOGIN TO YOUR ACCOUNT
                      </Button>
                    </FormControl>
                  </form>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: "#ffffff" }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ marginTop: "5vh", marginBottom: "2vh" }}
              >
                <Grid item xs={4}>
                  <Button
                    size="large"
                    onClick={() => this.props.history.push(`/register`)}
                    style={{
                      color: "#F07522",
                      textTransform: "none",
                      marginRight: "1vh",
                    }}
                  >
                    Create New Account
                  </Button>
                </Grid>

                <Grid item xs={4}>
                  <Button
                    size="large"
                    onClick={() => this.props.history.push(`/forgot-password`)}
                    style={{
                      color: "#F07522",
                      textTransform: "none",
                      marginLeft: "1vh",
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              action={
                <div>
                  {snackbarMessage.includes("Your email address needs") && (
                    <div>
                      <Button
                        color="secondary"
                        size="small"
                        style={{
                          fontSize: "0.875rem",
                          color: "#fff",
                          textTransform: "capitalize",
                          textDecoration: "underline",
                          marginRight: "10vh",
                        }}
                        onClick={() => {
                          this.setState({ verifyAccount: true });
                          this.sendEmailVerification();
                        }}
                      >
                        Resend email verification link
                      </Button>
                    </div>
                  )}
                </div>
              }
              onClose={() => this.onClose()}
            />
          </div>
        )}

        {verifyAccount && (
          <div
            style={{
              backgroundImage: `url("loginbg-img.jpg")`,
              height: "900px",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/`)}
              title={"Prayr App"}
            />
            <VerifyAccountComponent
              user={{ email }}
              onAction={this.sendEmailVerification}
            />
          </div>
        )}
      </div>
    );
  }
}

export default LoginComponent;
