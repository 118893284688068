import React from "react";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import Grid from "@material-ui/core/Grid";
import ConfirmationComponent from "../../shared/confirmation/confirmation";
import Button from "@mui/material/Button";
import TitleDescriptionComponent from "../../shared/components/title-description";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { app } from "../../../environments/environment";
import ButtonComponent from "../../shared/components/button";

class LicenseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
    };
    this.openCancelModal = this.openCancelModal.bind(this);
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  openCancelModal() {
    this.setState({ openCancelModalConfirmation: true });
  }

  cancel() {
    location.href = `${app}/privacy-policy`;
  }

  renderCancelModalConfirmation() {
    const { openCancelModalConfirmation } = this.state;
    return (
      <ConfirmationComponent
        open={openCancelModalConfirmation}
        handleAccept={() => {
          this.setState({ openCancelModalConfirmation: false });
          this.cancel();
        }}
        handleCancel={() => {
          this.setState({ openCancelModalConfirmation: false });
        }}
        title="Confirm"
        message="Are you sure you want to cancel your account?"
        AcceptButtonText="Yes"
        CancelButtonText="No"
      ></ConfirmationComponent>
    );
  }

  render() {
    const { snackbarOpen, snackbarMessage, isLoading } = this.state;
    return (
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div>
            <HeaderBarComponent
              showLogo
              showBack
              showBackAction={() => this.props.history.push(`/my-profile`)}
              showMenu
              showNotifications
              title={"Prayr Apps"}
            />

            <Grid container alignItems="center" direction="column">
              <TitleDescriptionComponent
                title={"License"}
              />
            </Grid>

            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: "#ffffff" }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ marginTop: "5vh", marginBottom: "1vh" }}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Type
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "right",
                    }}
                  >
                    Premium
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ marginBottom: "1vh" }}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "right",
                    }}
                  >
                    Active
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ marginBottom: "1vh" }}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Next billing date
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "right",
                    }}
                  >
                    Jan 05, 2023
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                style={{ marginBottom: "2vh" }}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Annual fee
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    style={{
                      textAlign: "right",
                    }}
                  >
                    $20
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center">
              <ButtonComponent
                name={"CHANGE PLAN"}
                marginTop={"5vh"}
                action={() => (location.href = `${app}/plans`)}
              />
              <Button
                type="submit"
                variant="text"
                style={{
                  marginBottom: "2vh",
                  color: "#F07522",
                  height: "6vh",
                  fontSize: "0.9rem",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  this.setState({
                    openCancelModalConfirmation: true,
                  });
                }}
              >
                Cancel Account
              </Button>
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              message={snackbarMessage}
              onClose={() => this.onClose()}
            />
            {this.renderCancelModalConfirmation()}
          </div>
        )}
      </div>
    );
  }
}

export default LicenseComponent;
