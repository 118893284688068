import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import "react-swipeable-list/dist/styles.css";
import NotificationsResources from "../../../resources/notifications";
import Typography from "@mui/material/Typography";
import Paper from "@material-ui/core/Paper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  p: 4,
};

class NotificationsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalImage: false,
      imageUrl: null,
    };
  }

  delete(id) {
    const { refreshNotifications } = this.props;
    NotificationsResources.delete(id).then(() => {
      refreshNotifications();
    });
  }

  showModalImage(imageUrl) {
    this.setState({ openModalImage: true, imageUrl });
  }

  handleClose = () => {
    this.setState({ openModalImage: false, imageUrl: null });
  };

  render() {
    const { notifications, onClose } = this.props;
    const { openModalImage, imageUrl } = this.state;

    return (
      <div
        className="notificationsComponent"
        style={{
          width: "100%",
          zIndex: 100,
          maxWidth: "500px",
          maxHeight: "100%",
          backgroundColor: "#f7f4f2",
          height: "100%",
          position: "fixed",
          overflowY: "auto",
        }}
      >
        <Grid container direction="column">
          <Typography
            variant="h6"
            onClick={() => {
              location.href = "/my-profile/notifications-preferences";
            }}
            style={{
              textAlign: "center",
              paddingTop: "1vh",
              fontSize: "1.5rem",
            }}
          >
            Notifications
          </Typography>
        </Grid>

        <Grid container direction="column" style={{ paddingBottom: "20vh" }}>
          {notifications.length > 0 ? (
            <>
              {notifications.map((item, i) => (
                <div
                  key={i}
                  style={{
                    marginTop: "3vh",
                    marginLeft: "3vh",
                    position: "relative",
                    width: "78%",
                  }}
                >
                  <Grid
                    item
                    style={{
                      backgroundColor: "#ffffff",
                      height: "auto",
                      borderRadius: "1.2vh",
                      position: "relative",
                      boxShadow: "rgb(153 153 153 / 26%) 3px 3px 3px",
                    }}
                  >
                    <Typography
                      style={{
                        padding: "6px 14px",
                        overflow: "hidden",
                        color: "#919191",
                        fontSize: "14px",
                        fontStyle: "initial",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {item.content
                        ? item.content
                        : item.entity_type == "Prayer" &&
                          !item.content &&
                          item.prayer_name
                        ? "Reminder to Pray!"
                        : null}
                    </Typography>
                    {item.entity_type == "GroupPrayer" &&
                      item.content?.includes(
                        "You have been invited to join to the group "
                      ) && (
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          style={{ margin: "1vh 0 -1vh" }}
                        >
                          <Button
                            style={{
                              color: "#ffffff",
                              borderRadius: "3vh",
                              width: "165px",
                              height: "32px",
                              fontSize: "12px",
                              textTransform: "inherit",
                              backgroundColor: "#F07522",
                              zIndex: 2,
                              position: "relative",
                              bottom: "10px",
                              left: "1.5vh",
                              paddingTop: "10px",
                            }}
                            size="large"
                            variant="contained"
                            onClick={() => (location.href = `/our-prayers`)}
                          >
                            VIEW INVITE
                          </Button>
                        </Grid>
                      )}
                    {item.photo_url && (
                      <Button
                        style={{
                          color: "#ffffff",
                          borderRadius: "3vh",
                          width: "165px",
                          height: "32px",
                          fontSize: "12px",
                          textTransform: "inherit",
                          backgroundColor: "#F07522",
                          zIndex: 2,
                          position: "relative",
                          bottom: "10px",
                          left: "1.5vh",
                          paddingTop: "10px",
                          margin: "1vh 0 -1vh",
                        }}
                        size="large"
                        variant="contained"
                        onClick={() => this.showModalImage(item.photo_url)}
                      >
                        VIEW IMAGE
                      </Button>
                    )}
                    <Paper
                      style={{
                        position: "absolute",
                        backgroundColor: "#fff0",
                        backgroundImage: `url("/Delete_Icon_Gray.png")`,
                        backgroundSize: "cover",
                        top: "5px",
                        right: "-35px",
                        zIndex: "2",
                        width: "20px",
                        height: "24px",
                        boxShadow:
                          "rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 0px 0px 0px",
                      }}
                      onClick={() => this.delete(item.id)}
                    />
                    <Typography
                      style={{
                        fontSize: "10px",
                        top: "-16px",
                        left: "12px",
                        position: "absolute",
                        color: "#919191",
                      }}
                    >
                      {moment
                        .utc(item.scheduled_at)
                        .local()
                        .format("ll. hh:mm A")}
                    </Typography>
                    <Typography
                      style={{
                        padding: "6px 14px",
                        color: "#f07522",
                        fontSize: "12px",
                        position: "relative",
                        textAlign: "right",
                      }}
                    >
                      {item.entity_type == "Group" &&
                        item.content?.includes("was closed") &&
                        item.content.replace(" was closed", "")}
                      {item.entity_type == "GroupPrayer" &&
                        item.content?.includes(
                          "You have been invited to join to the group "
                        ) &&
                        item.send_by_name}
                      {item.entity_type == "Prayer" &&
                        item.prayer_name &&
                        item.prayer_name}
                      {item.sent_by_name && item.sent_by_name}
                    </Typography>
                  </Grid>
                </div>
              ))}
            </>
          ) : (
            <Typography
              variant="h6"
              onClick={() => {
                location.href = "/my-profile/notifications-preferences";
              }}
              style={{
                textAlign: "center",
                paddingTop: "40vh",
              }}
            >
              No Notifications
            </Typography>
          )}
        </Grid>
        <Button
          style={{
            color: "#F07522",
            width: "100%",
            maxWidth: "500px",
            position: "fixed",
            height: "6vh",
            bottom: 0,
            fontSize: "0.9rem",
            zIndex: 2,
            backgroundColor: "#ffffff",
          }}
          variant="text"
          onClick={onClose}
        >
          CLOSE
        </Button>
        <Modal
          open={openModalImage}
          onClose={() => this.handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              style={{ width: "100%", height: "auto" }}
              src={imageUrl}
              alt=""
            />
          </Box>
        </Modal>
      </div>
    );
  }
}

export default NotificationsComponent;
