import React from "react";
import MyPrayersResources from "../../../resources/my-prayers";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@mui/material";
import TitleDescriptionComponent from "../../shared/components/title-description";
import moment from "moment";
import Button from "@mui/material/Button";
import HeaderBarComponent from "../../shared/header-bar/header-bar";
import ButtonComponent from "../../shared/components/button";
import LocalStorageResources from "../../../resources/local-storage";
import Snackbar from "@material-ui/core/Snackbar";
import ConfirmationComponent from "../../shared/confirmation/confirmation";
import Typography from "@mui/material/Typography";
import MyProfileResources from "../../../resources/my-profile";
import TitleBackgroundComponent from "../../shared/components/title-background";

class ViewDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.state = {
      snackbarOpen: false,
      snackbarMessage: "",
      isLoading: false,
      isUpdating: false,
      id,
      prayAnswered: false,
      prayer: {},
      message: this.props.location?.state?.message
        ? this.props.location.state.message
        : null,
      backUrl: this.props.location?.state?.backUrl
        ? this.props.location.state.backUrl
        : null,
      fromGroup: this.props.location?.state?.fromGroup
        ? this.props.location.state.fromGroup
        : null,
      controlShowPrayNowModal: false,
      userEmailSettings: [],
    };
    this.sendEncouragement = this.sendEncouragement.bind(this);
    this.backAction = this.backAction.bind(this);
    this.editPrayer = this.editPrayer.bind(this);
    this.sendAnswered = this.sendAnswered.bind(this);
  }

  componentDidMount() {
    this.loadPrayer();
  }

  sendEncouragement(id) {
    const { fromGroup } = this.state;
    this.props.history.push(`/my-prayers/${id}/view-person`, {
      backUrl: `/my-prayers/${id}/view-details`,
      fromGroup,
    });
  }

  backAction() {
    const { backUrl, fromGroup } = this.state;
    this.props.history.push(
      backUrl
        ? backUrl
        : fromGroup
        ? `/our-prayers/${fromGroup.id}/view-group`
        : `/my-prayers`
    );
  }

  editPrayer(id) {
    const { fromGroup } = this.state;
    this.props.history.push(`/my-prayers/${id}/edit-person`, {
      backUrl: `/my-prayers/${id}/view-details`,
      fromGroup,
    });
  }

  sendAnswered(prayer, status) {
    var prayerData = { id: prayer.id };
    prayerData.prayer = { status: status };
    MyPrayersResources.prayAnswered(prayerData).then(() => {
      this.setState({
        prayAnswered: status == "answered" ? true : false,
        snackbarOpen: true,
        snackbarMessage: "This prayer was updated",
      });
    });
  }

  renderFooterOptions() {
    const { prayer, prayAnswered } = this.state;
    const userData = LocalStorageResources.getObject("authData").data;
    let canSendEncouragement = false;
    let canPrayerAnswered = false;
    let canEdit = false;
    let canDelete = false;

    canSendEncouragement =
      (!prayer.group_id && !prayAnswered) ||
      (prayer.group_id && userData.id != prayer.user_id && !prayAnswered);
    canPrayerAnswered = !prayer.group_id;
    canEdit =
      (!prayer.group_id && userData.id == prayer.user_id && !prayAnswered) ||
      (prayer.group_id && userData.id == prayer.user_id);
    canDelete = !prayAnswered && !prayer.group_id;

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          position: "relative",
          backgroundColor: "#ffffff",
          height: "260px",
          width: "100%",
        }}
      >
        {canSendEncouragement && (
          <ButtonComponent
            name={"SEND ENCOURAGEMENT"}
            marginTop={"35px"}
            action={() => this.sendEncouragement(prayer.id)}
          />
        )}
        {canPrayerAnswered && (
          <ButtonComponent
            name={
              !prayAnswered && !prayer.group_id
                ? "PRAYER ANSWERED"
                : "PRAY AGAIN"
            }
            marginTop={canSendEncouragement ? "20px" : "35px"}
            action={() =>
              !prayAnswered && !prayer.group_id
                ? this.sendAnswered(prayer, "answered")
                : this.sendAnswered(prayer, "active")
            }
          />
        )}

        {canEdit && (
          <ButtonComponent
            name={
              !prayer.group_id
                ? "EDIT"
                : !prayer.description
                ? "ADD PRAYER REQUEST"
                : "EDIT PRAYER REQUEST"
            }
            marginTop={
              canSendEncouragement || canPrayerAnswered ? "20px" : "35px"
            }
            action={() => this.editPrayer(prayer.id)}
          />
        )}

        {canDelete && (
          <Button
            type="submit"
            style={{
              marginTop:
                canSendEncouragement || canPrayerAnswered || canEdit
                  ? "20px"
                  : "35px",
              padding: 0,
              color: "#F07522",
              fontSize: "0.9rem",
            }}
            onClick={() => this.openDeleteModal()}
          >
            Delete
          </Button>
        )}

        {prayer.group_id && (
          <Typography
            style={{
              textAlign: "center",
              margin:
                canSendEncouragement ||
                canPrayerAnswered ||
                canEdit ||
                canDelete
                  ? "20px auto 1vh"
                  : "35px auto 1vh",
            }}
          >
            Reminder: This is a Group Prayer Request
          </Typography>
        )}
      </Grid>
    );
  }

  delete() {
    const { prayer } = this.state;
    if (!prayer.group_id) {
      this.setState({
        isLoading: true,
        isUpdating: true,
      });
      MyPrayersResources.delete(prayer.id)
        .then(() => {
          this.props.history.push(`/my-prayers`, {
            message: "Prayer has been deleted successfully",
          });
        })
        .catch(() => {
          this.setState({ isLoading: false, isUpdating: false });
        });
    }
  }

  renderDeleteModalConfirmation() {
    const { openDeleteModalConfirmation } = this.state;
    return (
      <ConfirmationComponent
        open={openDeleteModalConfirmation}
        handleAccept={() => {
          this.setState({ openDeleteModalConfirmation: false });
          this.delete();
        }}
        handleCancel={() => {
          this.setState({ openDeleteModalConfirmation: false });
        }}
        title="Confirm"
        message="Are you sure you want to delete this prayer request?"
        AcceptButtonText="Yes"
        CancelButtonText="No"
      ></ConfirmationComponent>
    );
  }

  loadPrayer(loading = true) {
    const { id, message } = this.state;
    const currentDate = moment().format("DD/MM/yyyy");

    this.setState({
      isLoading: loading,
    });
    MyPrayersResources.get(id).then((data) => {
      if (data.group_id) {
        this.setState({
          fromGroup: { id: data.group_id },
        });
      }
      MyProfileResources.get().then((userEmailSettings) => {
        this.setState({
          userEmailSettings,
        });
      });
      const lastPrayDate = moment
        .utc(data.last_prayer_date)
        .local()
        .format("DD/MM/yyyy");
      data.prayToday =
        data.last_prayer_date == null || currentDate != lastPrayDate
          ? true
          : false;

      this.setState({
        prayer: data,
        isLoading: false,
        prayAnswered: data.status == "answered" ? true : false,
      });
      if (message) {
        this.setState({
          snackbarOpen: true,
          snackbarMessage: message,
          message: null,
        });
      }
    });
  }

  renderModalPrayNow(prayer, listView = false) {
    const { userEmailSettings } = this.state;
    return (
      <Paper
        style={{
          position: "absolute",
          width: !listView ? "97%" : "92%",
          margin: "auto",
          bottom: !listView && "6px",
          left: 0,
          right: 0,
          height: !listView ? "auto" : "12.5vh",
          borderRadius: "2vh",
          zIndex: "2",
          background:
            "linear-gradient(rgb(255, 178, 122) 0%, rgb(245, 138, 66) 46%, rgb(241, 124, 44) 92%)",
        }}
        onClick={() => {
          this.setState({ controlShowPrayNowModal: false });
          this.loadPrayer(false);
        }}
      >
        <Paper
          sx={{
            boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
          }}
          style={{
            margin: "1vh auto 0.5vh",
            backgroundColor: "#fff0",
            backgroundImage: `url("/CheckMark_Icon.png")`,
            backgroundSize: "cover",
            zIndex: "2",
            width: "33px",
            height: "33px",
            position: listView && "absolute",
            right: listView && "1.5vh",
            top: listView && "3vh",
          }}
        />
        <Typography
          style={{
            textAlign: !listView && "center",
            marginTop: listView && "2vh",
            marginLeft: listView && "2vh",
            fontSize: "20px",
            color: "#ffffff",
            fontWeight: "bold",
          }}
        >
          Prayer Marked Complete!
        </Typography>
        {((prayer.message_type == "email" && !prayer.email) ||
          (prayer.message_type == "sms" && !prayer.phone)) &&
          !prayer.group_id && (
            <>
              {!listView && (
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    paddingLeft: "5vh",
                    paddingRight: "5vh",
                    paddingBottom: "2vh",
                  }}
                >
                  You can automatically message{" "}
                  {prayer.name?.length > 20
                    ? `${prayer.name.slice(0, 20)}...`
                    : prayer.name}{" "}
                  each time you pray by adding an email or cell.
                </Typography>
              )}
              <Button
                style={{
                  color: "#888a88",
                  borderRadius: "3vh",
                  width: "218px",
                  minWidth: "218px",
                  height: "35px",
                  minHeight: "35px",
                  fontSize: "0.9rem",
                  paddingTop: "0.7vh",
                  textTransform: "capitalize",
                  backgroundColor: "#ffffff",
                  marginRight: !listView && "auto",
                  marginLeft: !listView ? "auto" : "2vh",
                  marginBottom: !listView && "1vh",
                  display: "block",
                }}
                size="large"
                variant="contained"
                onClick={() =>
                  this.props.history.push(
                    `/my-prayers/${prayer.id}/edit-person`
                  )
                }
              >
                ADD CONTACT INFO
              </Button>
            </>
          )}
        {prayer.message_type == "email" &&
          prayer.email &&
          !userEmailSettings.automatic_message_sending &&
          !prayer.group_id && (
            <>
              {!listView && (
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    paddingLeft: "5vh",
                    paddingRight: "5vh",
                    paddingBottom: "2vh",
                  }}
                >
                  You can automatically send a pre-set message to people that
                  you pray for.
                </Typography>
              )}
              <Button
                style={{
                  color: "#888a88",
                  borderRadius: "3vh",
                  width: "218px",
                  minWidth: "218px",
                  height: "35px",
                  minHeight: "35px",
                  fontSize: "0.9rem",
                  paddingTop: "0.7vh",
                  textTransform: "capitalize",
                  backgroundColor: "#ffffff",
                  marginRight: !listView && "auto",
                  marginLeft: !listView ? "auto" : "2vh",
                  marginBottom: !listView && "1vh",
                  display: "block",
                }}
                size="large"
                variant="contained"
                onClick={() =>
                  this.props.history.push(`/my-profile/user-email-settings`)
                }
              >
                SET UP MESSAGING
              </Button>
            </>
          )}
        {prayer.message_type == "sms" &&
          prayer.phone &&
          !userEmailSettings.automatic_message_sending &&
          !prayer.group_id && (
            <>
              {!listView && (
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    paddingLeft: "5vh",
                    paddingRight: "5vh",
                    paddingBottom: "2vh",
                  }}
                >
                  You can automatically send a pre-set message to people that
                  you pray for.
                </Typography>
              )}
              <Button
                style={{
                  color: "#888a88",
                  borderRadius: "3vh",
                  width: "218px",
                  minWidth: "218px",
                  height: "35px",
                  minHeight: "35px",
                  fontSize: "0.9rem",
                  paddingTop: "0.7vh",
                  textTransform: "capitalize",
                  backgroundColor: "#ffffff",
                  marginRight: !listView && "auto",
                  marginLeft: !listView ? "auto" : "2vh",
                  marginBottom: !listView && "1vh",
                  display: "block",
                }}
                size="large"
                variant="contained"
                onClick={() =>
                  this.props.history.push(`/my-profile/user-email-settings`)
                }
              >
                SET UP MESSAGING
              </Button>
            </>
          )}
        {((prayer.message_type == "email" && prayer.email) ||
          (prayer.message_type == "sms" && prayer.phone)) &&
          !prayer.group_id &&
          userEmailSettings.automatic_message_sending && (
            <Button
              style={{
                color: "#888a88",
                borderRadius: "3vh",
                width: "218px",
                minWidth: "218px",
                height: "35px",
                minHeight: "35px",
                fontSize: "0.9rem",
                paddingTop: "0.7vh",
                textTransform: "capitalize",
                backgroundColor: "#ffffff",
                marginRight: !listView && "auto",
                marginLeft: !listView ? "auto" : "2vh",
                marginBottom: !listView && "1vh",
                display: "block",
                marginTop: "1vh",
              }}
              size="large"
              variant="contained"
              onClick={() => this.prayNow(prayer, true)}
            >
              SEND ENCOURAGEMENT
            </Button>
          )}
        {userEmailSettings.automatic_message_sending && prayer.group_id && (
          <Button
            style={{
              color: "#888a88",
              borderRadius: "3vh",
              width: "218px",
              minWidth: "218px",
              height: "35px",
              minHeight: "35px",
              fontSize: "0.9rem",
              paddingTop: "0.7vh",
              textTransform: "capitalize",
              backgroundColor: "#ffffff",
              marginRight: !listView && "auto",
              marginLeft: !listView ? "auto" : "2vh",
              marginBottom: !listView && "1vh",
              display: "block",
              marginTop: "1vh",
            }}
            size="large"
            variant="contained"
            onClick={() => this.prayNow(prayer, true)}
          >
            SEND ENCOURAGEMENT
          </Button>
        )}
        {!userEmailSettings.automatic_message_sending && prayer.group_id && (
          <>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#ffffff",
                paddingLeft: "5vh",
                paddingRight: "5vh",
                paddingBottom: "2vh",
              }}
            >
              You can automatically send a pre-set message to people that you
              pray for.
            </Typography>
            <Button
              style={{
                color: "#888a88",
                borderRadius: "3vh",
                width: "218px",
                minWidth: "218px",
                height: "35px",
                minHeight: "35px",
                fontSize: "0.9rem",
                paddingTop: "0.7vh",
                textTransform: "capitalize",
                backgroundColor: "#ffffff",
                marginRight: !listView && "auto",
                marginLeft: !listView ? "auto" : "2vh",
                marginBottom: !listView && "1vh",
                display: "block",
              }}
              size="large"
              variant="contained"
              onClick={() =>
                this.props.history.push(`/my-profile/user-email-settings`)
              }
            >
              SET UP MESSAGING
            </Button>
          </>
        )}
      </Paper>
    );
  }

  prayNowOnly(prayer) {
    MyPrayersResources.prayNow(prayer).then(() => {
      this.loadPrayer(false);
    });
  }

  prayNow(prayer, sendEncouragement = false) {
    const { userEmailSettings } = this.state;
    if (!sendEncouragement) {
      MyPrayersResources.prayNow(prayer).then(() => {
        this.setState({ controlShowPrayNowModal: true });
      });
    } else {
      if (!prayer.group_id) {
        if (prayer.message_type == "sms" && prayer.phone) {
          if (userEmailSettings.automatic_message_sending) {
            location.href = `sms:${prayer.phone};?&body=${userEmailSettings.default_message}`;
          }
        } else {
          MyPrayersResources.prayNow(prayer, true);
        }
      } else {
        if (userEmailSettings.automatic_message_sending) {
          let sendData = {};
          let payload = {
            subject: userEmailSettings.default_subject,
            content: userEmailSettings.default_message,
            prayer_id: prayer.id,
            message_type: "pray_now",
          };
          (payload.to_user_id = prayer.user_id), (sendData.in_app = payload);
          MyPrayersResources.sendMessageInApp(sendData);
        } else {
          MyPrayersResources.prayNow(prayer);
        }
      }
    }
  }

  openDeleteModal() {
    this.setState({ openDeleteModalConfirmation: true });
  }

  onClose() {
    this.setState({ snackbarOpen: false });
  }

  render() {
    const {
      prayer,
      isLoading,
      isUpdating,
      snackbarMessage,
      snackbarOpen,
      controlShowPrayNowModal,
      prayAnswered,
    } = this.state;
    const userData = LocalStorageResources.getObject("authData").data;

    return (
      <div>
        {(isLoading || isUpdating) && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && !isUpdating && (
          <div>
            <HeaderBarComponent
              showBack
              showBackAction={this.backAction}
              showMenu
              showLogo
              showNotifications
              title={"Prayr App"}
            />

            {prayer.group_id && (
              <TitleBackgroundComponent title={"Our Prayers"} />
            )}

            <div
              style={{
                backgroundImage: prayer.avatar_url
                  ? `url("` + prayer.avatar_url + `")`
                  : `url("/gradient-images/${prayer.background_color}")`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                height: "37vh",
                position: "relative",
              }}
            >
              {(!prayer.group_id ||
                (prayer.group_id && userData.id != prayer.user_id)) &&
                !prayAnswered && (
                  <>
                    <Paper
                      onClick={() => this.prayNowOnly(prayer)}
                      sx={{
                        boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%);",
                      }}
                      style={{
                        position: "absolute",
                        bottom: "18px",
                        backgroundColor: "#fff0",
                        backgroundImage: prayer.prayToday
                          ? `url("/pray-now-icon-new.png")`
                          : `url("/pray-now-icon-checked-new.png")`,
                        backgroundSize: "cover",
                        right: "25px",
                        zIndex: "2",
                        width: "46px",
                        height: "46px",
                      }}
                    />
                    {controlShowPrayNowModal && this.renderModalPrayNow(prayer)}
                  </>
                )}
            </div>
            <TitleDescriptionComponent
              title={prayer.name}
              typeTitle={"titleBody"}
              textAlign={"left"}
              textAlignSubtitle={"left"}
              description={prayer.description}
              height={"auto"}
            />
            {this.renderFooterOptions()}
          </div>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          message={snackbarMessage}
          onClose={() => this.onClose()}
        />
        {this.renderDeleteModalConfirmation()}
      </div>
    );
  }
}

export default ViewDetailsComponent;
