import React from "react";

class CheckDeviceComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h1>deviceType: {this.props.deviceType}</h1>
        <h1>installableApp: {this.props.installableApp}</h1>
        <h1>isIosInstalled: {this.props.isIosInstalled}</h1>
      </div>
    );
  }
}

export default CheckDeviceComponent;
