import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
// import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Theme from "../theme/theme";
//Profile
import MyProfileComponent from "../../my-profile/my-profile";
import UserEmailSettingsComponent from "../../my-profile/user-email-settings/user-email-settings";
import UserInfoComponent from "../../my-profile/user-info/user-info";
import UserResetPasswordComponent from "../../my-profile/reset-password/reset-password";
import NotificationsPreferencesComponent from "../../my-profile/notifications-preferences/notifications-preferences";
import UserLicenseComponent from "../../my-profile/license/license";
//notifications
import NotificationsDetailsComponent from "../../notifications/notifications-details/notifications-details";
//Login, Register, Forgot-Password
import AuthRoute from "../auth-route/auth-route";
import LoginComponent from "../../login/login";
import RegisterComponent from "../../register/register";
import ForgotPasswordComponent from "../../forgot-password/forgot-password";
import VerifyAccountComponent from "../../verify-account/verify-account";
import ResetPasswordComponent from "../../forgot-password/reset-password";
import ConfirmationEmailComponent from "../../verify-account/confirmation-email";
//Home, Quick Start
import HomeComponent from "../../home/home";
import QuickStartComponent from "../../quick-start/quick-start";
//My Prayers
import MyPrayersComponent from "../../my-prayers/my-prayers";
import AddPersonComponent from "../../my-prayers/add-person/add-person";
import EditPersonComponent from "../../my-prayers/edit-person/edit-person";
import ViewPersonComponent from "../../my-prayers/view-person/view-person";
import ViewDetailsComponent from "../../my-prayers/view-details/view-details";
import ShareVerseComponent from "../../my-prayers/view-person/share-verse/share-verse";
import SharePhotoComponent from "../../my-prayers/view-person/share-photo/share-photo";
import QuickResponseComponent from "../../my-prayers/view-person/quick-response/quick-response";
import PrayNowComponent from "../../my-prayers/view-person/pray-now/pray-now";
//Our Prayers
import OurPrayersComponent from "../../our-prayers/our-prayers";
import AddGroupComponent from "../../our-prayers/add-group/add-group";
import EditGroupComponent from "../../our-prayers/edit-group/edit-group";
import ViewGroupComponent from "../../our-prayers/view-group/view-group";
import InvitationsGroupComponent from "../../our-prayers/invitations-group/invitations-group";
import AddInvitationGroupComponent from "../../our-prayers/invitations-group/add-invitation/add-invitation";
import MessagesGroupComponent from "../../our-prayers/messages-group/messages-group";
//Answered Prayers
import AnsweredPrayersComponent from "../../answered-prayers/answered-prayers";
//Faith Builder
import FaithBuilderComponent from "../../faith-builder/faith-builder";
import AddFaithComponent from "../../faith-builder/add-faith/add-faith";
import EditFaithComponent from "../../faith-builder/edit-faith/edit-faith";
//Check Device
import CheckDeviceComponent from "../../check-device/check-device";
//Feedback
import FeedbackComponent from "../../feedback/feedback";

const Routes = function Routes({
  installableApp,
  isIosInstalled,
  handleInstallApp,
  deviceType,
}) {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={Theme}>
        <Switch>
          <Route
            exact
            path="/login"
            component={(props) => (
              <LoginComponent
                {...props}
                {...{
                  installableApp,
                  isIosInstalled,
                  handleInstallApp,
                  deviceType,
                }}
              />
            )}
          />
          <Route
            exact
            path="/register"
            component={(props) => <RegisterComponent {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            component={(props) => <ForgotPasswordComponent {...props} />}
          />
          <Route
            exact
            path="/verify-account"
            component={(props) => <VerifyAccountComponent {...props} />}
          />
          <Route
            exact
            path="/confirmation-token"
            component={(props) => <ConfirmationEmailComponent {...props} />}
          />
          <Route
            exact
            path="/reset-password"
            component={(props) => <ResetPasswordComponent {...props} />}
          />
          <Route
            exact
            path="/check-device"
            component={(props) => (
              <CheckDeviceComponent
                {...props}
                {...{
                  installableApp,
                  isIosInstalled,
                  handleInstallApp,
                  deviceType,
                }}
              />
            )}
          />
          <AuthRoute
            exact
            path="/my-profile"
            component={(props) => <MyProfileComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-profile/user-email-settings"
            component={(props) => <UserEmailSettingsComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-profile/user-info"
            component={(props) => <UserInfoComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-profile/notifications-preferences"
            component={(props) => (
              <NotificationsPreferencesComponent {...props} />
            )}
          />
          <AuthRoute
            exact
            path="/my-profile/license"
            component={(props) => <UserLicenseComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-profile/reset-password-email"
            component={(props) => <UserResetPasswordComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/notifications/:id/notifications-details"
            component={(props) => <NotificationsDetailsComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers"
            component={(props) => <MyPrayersComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/add-person"
            component={(props) => <AddPersonComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/:id/edit-person"
            component={(props) => <EditPersonComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/:id/view-person"
            component={(props) => <ViewPersonComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/:id/view-details"
            component={(props) => <ViewDetailsComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/:id/view-person/share-verse"
            component={(props) => <ShareVerseComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/:id/view-person/share-photo"
            component={(props) => <SharePhotoComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/:id/view-person/pray-now"
            component={(props) => <PrayNowComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/my-prayers/:id/view-person/quick-response"
            component={(props) => <QuickResponseComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/our-prayers"
            component={(props) => <OurPrayersComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/our-prayers/add-group"
            component={(props) => <AddGroupComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/our-prayers/:id/edit-group"
            component={(props) => <EditGroupComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/our-prayers/:id/view-group"
            component={(props) => <ViewGroupComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/our-prayers/:id/invitations-group"
            component={(props) => <InvitationsGroupComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/our-prayers/invitations-group/add-invitation"
            component={(props) => <AddInvitationGroupComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/our-prayers/:id/messages-group"
            component={(props) => <MessagesGroupComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/faith-builder"
            component={(props) => <FaithBuilderComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/faith-builder/add-faith"
            component={(props) => <AddFaithComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/faith-builder/:id/edit-faith"
            component={(props) => <EditFaithComponent {...props} />}
          />

          <AuthRoute
            exact
            path="/answered-prayers"
            component={(props) => <AnsweredPrayersComponent {...props} />}
          />
          <AuthRoute
            exact
            path="/quick-start"
            component={(props) => <QuickStartComponent {...props} />}
          />
          <AuthRoute exact path="/" component={HomeComponent} />
          <AuthRoute exact path="/home" component={HomeComponent} />
          <AuthRoute
            exact
            path="/feedback"
            component={(props) => <FeedbackComponent {...props} />}
          />
          {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
        </Switch>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default Routes;
